import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/container/insight-dialog.js';

class ConfirmationDialog extends InsightElement {
  static get properties() {
    return {
      danger: {type: Boolean, attribute: 'danger'},
      warning: {type: Boolean, attribute: 'warning'},
      success: {type: Boolean, attribute: 'success'},
      __confirmationPause: {type: Boolean},
      __confirmationTitle: {type: String},
      __confirmationBody: {type: Object},
      __confirmationSubmitFn: {type: Function},
      __confirmationCancelFn: {type: Function},
      __confirmationBtnText: {type: String}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  show(params) {
    this.__confirmationPause = false;
    this.__confirmationTitle = params.title || this._i18n('common:areYouSure');
    this.__confirmationBody = params.body;
    this.__confirmationSubmitFn = params.submitFn;
    this.__confirmationCancelFn = params.cancelFn;
    this.__confirmationBtnText = params.submitBtnText || this._i18n('common:ok');
    this.danger = !!params.danger;
    this.warning = !!params.warning;
    this.success = !!params.success;
    this.__addEventListeners();
    this.__dialogEl.open();
    if (params.focusEl)
      setTimeout(() => {
        this._getElement(params.focusEl).setFocus();
      }, 200);
  }

  close() {
    this.__removeEventListeners();
    this.__dialogEl.close();
  }

  enable() {
    this.__confirmationPause = false;
  }

  /**************************************************  Private Methods  *************************************************/

  _destructor() {
    super._destructor();
    this.__removeEventListeners();
  }

  __addEventListeners() {
    this.__removeEventListeners();
    this.__boundEnterHandler = this.__handleEnterKey.bind(this);
    this.addEventListener('keydown', this.__boundEnterHandler);
  }

  __removeEventListeners() {
    if (this.__boundEnterHandler) this.removeEventListener('keydown', this.__boundEnterHandler);
    this.__boundEnterHandler = null;
  }

  __handleEnterKey(e) {
    if (e.key === 'Enter' || e.keyCode === 13) this.__handleConfirmationSubmit();
  }

  __handleConfirmationSubmit() {
    this.__confirmationPause = true;
    if (this.__confirmationSubmitFn) this.__confirmationSubmitFn();
  }

  __handleConfirmationCancel() {
    this.close();
    if (this.__confirmationCancelFn) this.__confirmationCancelFn();
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog id="confirm-dialog" modal can-dismiss ?disabled=${this.__confirmationPause} @dismissed=${this.__handleConfirmationCancel}>
        >
        <div slot="modalHeader" class="modal-header">${this.__confirmationTitle}</div>
        <div id="modal-body" slot="modalBody">
          ${typeof this.__confirmationBody === 'string' ? unsafeHTML(this.__confirmationBody) : this.__confirmationBody}
        </div>
        <span slot="modalFooter" class="buttons modal-footer">
          <insight-button id="btn-cancel" @click=${this.__handleConfirmationCancel} ?disabled=${this.__confirmationPause}>
            ${this._i18n('common:cancel')}
          </insight-button>
          <insight-button
            id="btn-confirm"
            ?danger=${this.danger}
            ?success=${this.success}
            ?warning=${this.warning}
            contained
            @click=${this.__handleConfirmationSubmit}
            ?disabled=${this.__confirmationPause}
          >
            ${this.__confirmationBtnText}
          </insight-button>
        </span>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host #modal-body b {
          color: var(--mdc-theme-primary)
        }
        :host #confirm-dialog {
          --dialog-max-width: min(480px, 96vw);
        }
      </style>
    `;
  }
}
window.customElements.define('confirmation-dialog', ConfirmationDialog);
