import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/form/insight-form.js';
import '@insight/insight-common/components/form/insight-input.js';
import '@insight/insight-common/components/form/insight-readonly-field.js';

class RegisterCredentials extends InsightElement {
  static get properties() {
    return {
      registration: {type: Object}
    };
  }

  firstUpdated() {
    this._afterRender(() => {
      this._dispatchEvent('step-load', this);
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('registration')) this._afterRender(() => this._getElement('#ipt-newPassword').setFocus());
  }

  __passwordValidator(value) {
    if (!value.match(/[a-z]/g)) {
      return this._i18n('location:register.error.noLower');
    } else if (!value.match(/[A-Z]/g)) {
      return this._i18n('location:register.error.noUpper');
    } else if (!value.match(/[0-9]/g)) {
      return this._i18n('location:register.error.noNumber');
    } else if (!value.match(/[~!@#\$%\^&\*\(\)\-\+=\?\/<>\|\[\]{}_:;\.,\\`]/g)) {
      return this._i18n('location:register.error.noSpecial');
    } else if (value.length < 8) {
      return this._i18n('location:register.error.tooShort');
    }
    return true;
  }

  __confirmValidator(value) {
    if (!value.length || value !== this.registration.credentials.password) return this._i18n('location:register.error.passwordMismatch');
    return true;
  }

  __finish() {
    if (!this.__formEl.validate()) return;
    this._dispatchEvent('move-next', 'Credential step completed');
  }

  get __formEl() {
    return this._getElement('insight-form');
  }

  _render() {
    if (!this.registration) return;
    return html`
      ${unsafeHTML(this.__css)}
      <div>
        <div class="typo-title">${this._i18n('location:register.choosePassword')}</div>
        <insight-form id="frm-password">
          <insight-readonly-field
            dense
            no-border
            label="${this._i18n('common:userName')}"
            id="ipt-username"
            initial-value=${ifDefined(this.registration.credentials.username || undefined)}
          ></insight-readonly-field>
          <insight-input
            dense
            type="password"
            label="${this._i18n('common:password')}"
            id="ipt-newPassword"
            required
            initial-value=${ifDefined(this.registration.credentials.password)}
            .validator=${(v, e) => this.__passwordValidator(v, e)}
            error-message=${this._i18n('common:error.passwordReq')}
            @change=${e => (this.registration.credentials.password = e.detail)}
          ></insight-input>
          <insight-input
            dense
            type="password"
            label="${this._i18n('location:register.confirmPassword')}"
            id="ipt-confirm"
            required
            initial-value=${ifDefined(this.registration.credentials.password)}
            .validator=${(v, e) => this.__confirmValidator(v, e)}
            error-message=${this._i18n('common:error.passwordReq')}
            @change=${e => (this.__confirmedPW = e.detail)}
          ></insight-input>
        </insight-form>
        <div class="footer-btns">
          <insight-button contained @click=${this.__finish}>${this._i18n('common:next')}</insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host insight-form {
        margin-top: 12px;
        max-width: 480px;
        display: block;
      }
      :host .footer-btns {
        margin: 20px 0 10px;
      }
    </style>
  `;
  }
}
window.customElements.define('register-credentials', RegisterCredentials);
