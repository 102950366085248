import {ViewBase, html} from '@insight/insight-common/components/insight-view-base.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import sha256 from '@insight/insight-common/util/sha256.cjs';
import ky from 'ky';
import '@insight/insight-common/components/container/insight-card.js';
import '@insight/insight-common/components/container/insight-message-dialog.js';
import '@insight/insight-common/components/container/insight-stepper.js';
import '../../../components/registration/register-confirm.js';
import '../../../components/registration/register-credentials.js';
import '../../../components/registration/register-location.js';
import '../../../components/registration/register-plan.js';
import '../../../components/registration/register-review.js';

class CustomerRegister extends ViewBase {
  static get properties() {
    return {
      __subtitle: {type: String}
    };
  }

  constructor() {
    super();
    this.__subtitle = '';
    this.__registration = {contact: {}, location: {}, plan: {}, payment: {}, credentials: {}};
    this.__parseQueryString();
  }

  firstUpdated() {
    this._dispatchEvent('view-loaded');
    this._afterRender(() => {
      if (this.__expired) this.__msgEl.show(this._i18n('common:errorTitle'), this._i18n('location:register.expiredMsg'), true);
      else if (this.__invalid) this.__msgEl.show(this._i18n('common:errorTitle'), this._i18n('location:register.invalidMsg'), true);
      this.__checkInvitation();
      this.__postTelemetry('Registration confirmation link was clicked');
    });
  }

  __parseQueryString() {
    const params = new URLSearchParams(location.search);
    this.__registration.contact.fullName = '';
    this.__registration.contact.fullName += params.get('fn') || '';
    this.__registration.contact.fullName += (this.__registration.contact.fullName.length ? ' ' : '') + (params.get('ln') || '');
    this.__registration.contact.email = params.get('email');
    this.__registration.contact.phone = params.get('phone');
    this.__registration.credentials.username = this.__registration.contact.email;
    const plan = params.get('plan');
    if (!!plan && plan !== 'NONE') {
      this.__registration.plan.existing = true;
      this.__registration.plan.premium = plan === 'PREMIUM';
      this.__registration.plan.members = parseInt(params.get('accounts'));
      if (isNaN(this.__registration.plan.members)) this.__registration.plan.members = 100;
      this.__registration.plan.price = this.__registration.plan.premium ? 99 : 49;
      this.__registration.plan.price = this.__registration.plan.price + (this.__registration.plan.members - 100) / 10;
      this.__updateSubtitle(this.__registration.plan);
    } else {
      this.__updateSubtitle();
    }
    let exp = params.get('exp');
    if (!!exp) {
      exp = parseInt(exp) * 1000;
      const minsRemaining = (exp - new Date().getTime()) / 1000 / 60;
      if (minsRemaining <= 30) this.__expired = true;
    }
    if (!this.__registration.contact.fullName || !this.__registration.contact.email) {
      console.error('Missing parameters for customer name and/or email');
      this.__invalid = true;
    }
  }

  __stepViewLoaded({detail: stepEl}) {
    stepEl.registration = this.__registration;
  }

  __startOver() {
    this.__stepperEl.setActive(0);
  }

  __moveNext(e) {
    if (e && e.detail) this.__postTelemetry(e.detail);
    this.__stepperEl.moveNext();
  }

  __updateSubtitle(plan) {
    if (!plan) return (this.__subtitle = this._i18n('location:register.softwareEval'));
    this.__subtitle = this.__registration.plan.premium ? this._i18n('location:register.premPlan') : this._i18n('location:register.stdPlan');
    this.__subtitle += ' - $' + plan.price + ' ' + this._i18n('location:register.perMonth');
  }

  async __checkInvitation() {
    if (this._isMock()) return;
    const request = window.location.href;
    const hashed = sha256(`"${request}"`);
    try {
      const result = await ky.post(`https://api.gyminsight.com/verify-confirmation`, {json: request, headers: {'X-Anonymous-Hash': hashed}}).json();
    } catch (err) {
      await this.__handleAPIError(err, true);
    }
  }

  async __completeRegistration() {
    if (this._isMock()) {
      this.__registration.result = {billed_amount: this.__registration.plan.price, transaction_id: '12345678'};
      setTimeout(() => this.__moveNext(), 2000);
      return;
    }
    const name = this.__getNameParts(this.__registration.contact.fullName);
    const phone = this.__registration.location.phone ? this.__registration.location.phone : this.__registration.contact.phone;
    const request = {
      confirmation_link: window.location.href,
      first_name: name.first,
      last_name: name.last,
      email: this.__registration.credentials.username,
      password: this.__registration.credentials.password,
      location_name: this.__registration.location.name,
      location_phone_number: phone,
      location_timezone: this.__registration.location.timezone,
      plan_type: this.__registration.plan.premium ? 'PREMIUM' : 'STANDARD',
      starting_accounts: this.__registration.plan.members
    };
    if (this.__registration.payment !== 'trial') {
      if (this.__registration.payment.methodName === 'insight-ach') {
        request.payment = {
          type: 'ACH',
          ach_routing_number: this.__registration.payment.details.routingNumber,
          ach_account_number: this.__registration.payment.details.acctNumber,
          ach_bank_name: this.__registration.payment.details.bankName,
          ach_account_type: this.__registration.payment.details.accountType
        };
      } else {
        const billingName = this.__getNameParts(this.__registration.payment.details.cardholderName);
        request.payment = {
          type: 'CREDIT',
          first_name: billingName.first,
          last_name: billingName.last,
          billing_phone: this.__registration.payment.details.billingAddress.phone,
          billing_address: {
            line1: this.__registration.payment.details.billingAddress.addressLine[0],
            city: this.__registration.payment.details.billingAddress.city,
            state_province_county: this.__registration.payment.details.billingAddress.region,
            postal_code: this.__registration.payment.details.billingAddress.postalCode,
            country: this.__registration.payment.details.billingAddress.country
          },
          card_number: this.__registration.payment.details.cardNumber,
          card_expiration_month: this.__registration.payment.details.expiryMonth,
          card_expiration_year: this.__registration.payment.details.expiryYear,
          cvv: this.__registration.payment.details.cardSecurityCode,
          card_type: this.__getCardType(this.__registration.payment.details.cardType)
        };
      }
    }
    const hashed = sha256(JSON.stringify(request));
    try {
      const headers = {'X-Anonymous-Hash': hashed};
      if (new URLSearchParams(location.search).get('test') === 'true') headers['X-GymInsight-Test'] = true;
      const result = await ky.post(`https://api.gyminsight.com/complete`, {json: request, headers: headers}).json();
      this.__registration.result = result;
      this.__postTelemetry('Registration was completed');
      this.__moveNext();
    } catch (err) {
      await this.__handleAPIError(err, false);
      this.__postTelemetry('Error occured on registration: ' + err.message);
      this.__stepperEl.getActiveView().enableAfterError();
    }
  }

  __getCardType(card) {
    if (!card) return 'NONE';
    if (card === 'mastercard') card = 'MC';
    else if (card === 'discover') card = 'DIS';
    return card.toUpperCase();
  }

  __getNameParts(name) {
    const nameArr = name.split(/\s+/);
    return {first: nameArr.slice(0, -1).join(' '), last: nameArr.pop()};
  }

  async __postTelemetry(action) {
    if (this._isMock()) return;
    const request = {
      type: 'REGISTRATION',
      confirmation_link: window.location.href,
      latest_action: action
    };
    const hashed = sha256(JSON.stringify(request));
    try {
      const headers = {'X-Anonymous-Hash': hashed};
      await ky.post(`https://api.gyminsight.com/ui/telemetry`, {json: request, headers: headers}).json();
    } catch (err) {
      console.warn('Could not post telemetry: ' + err.message);
    }
  }

  async __handleAPIError(err, isFatal) {
    const error = await err.response.text();
    if (error.length) {
      const jsonResp = JSON.parse(error);
      if (jsonResp.errors) {
        let message = '';
        Object.values(jsonResp.errors).forEach(v => (message = `${message}<br/>${v}`));
        this.__msgEl.show(this._i18n('common:errorTitle'), '<em>' + message + '</em>', isFatal);
      } else {
        this.__msgEl.show(this._i18n('common:errorTitle'), '<em>' + jsonResp.title + '</em><br><br>' + jsonResp.detail, isFatal);
      }
    } else {
      this.__msgEl.show(
        this._i18n('common:errorTitle'),
        '<em>' + err.response.status + ' ' + err.name + '</em><br><br>An error occurred with url: ' + err.request.url,
        isFatal
      );
    }
  }

  get __stepperSteps() {
    return [
      {label: this._i18n('location:register.loginCredentials'), view: 'register-credentials'},
      {label: this._i18n('location:register.setupYourLoc'), view: 'register-location'},
      {label: this._i18n('location:register.planInfo'), view: 'register-plan'},
      {label: this._i18n('location:register.review'), view: 'register-review'},
      {label: this._i18n('location:register.confirmation'), view: 'register-confirm'}
    ];
  }

  get __stepperEl() {
    return this._getElement('insight-stepper');
  }

  get __msgEl() {
    return this._getElement('insight-message-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-card id="register-main">
        <div slot="cardContent" class="flex-layout-vertical">
          <div id="card-header" class="flex-layout-horizontal flex-layout-center flex-layout-justified">
            <img src=${`images/website-brand${document.body.hasAttribute('dark-mode') ? '-dark' : ''}.png`} title=${`Gym Insight - v${Insight.version}`} />
            <div>
              <div class="typo-headline card-title">${this._i18n('location:register.mainTitle')}</div>
              <div class="typo-subhead2 card-subtitle">${this.__subtitle}</div>
            </div>
          </div>
          <insight-stepper
            class="flex-layout-flex"
            .steps=${this.__stepperSteps}
            @step-load=${this.__stepViewLoaded}
            @move-next=${this.__moveNext}
            @start-over=${this.__startOver}
            @complete-reg=${this.__completeRegistration}
          ></insight-stepper>
        </div>
      </insight-card>
      <insight-message-dialog modal></insight-message-dialog>
    `;
  }

  get __css() {
    return `
    <style>
      :host {
        height: unset !important;
      }
      :host #register-main {
        display: block;
        width: 1000px;
        min-height: 600px;
        max-height: 90vh;
        --card-explicit-height: 90vh;
        --card-explicit-min-height: 600px;
        --card-explicit-max-height: 90vh;
        --card-padding: 20px 30px;
        --card-body-padding-top: 0;
      }
      :host div[slot="cardContent"] {
        height: 100%;
      }
      :host #card-header {
        padding-bottom: 10px;
        border-bottom: solid 1px var(--mdc-theme-dark-white, #16405c);
      }
      :host .card-title {
        font-weight: 700;
        color: var(--mdc-theme-dark-white, #16405c);
      }
      :host .card-subtitle {
        text-align: right;
        color: var(--mdc-theme-primary);
      }
      :host insight-stepper {
        overflow-y: auto;
      }
      :host insight-message-dialog {
        --dialog-max-width: 640px;
      }
      @media only screen and (max-width: 1040px), only screen and (max-height: 640px) {
        :host {
          padding: 0 !important;
          width: 100% !important;
          height: 100% !important;
        }
        :host #register-main {
          width: 100%;
          height: 100%;
          min-height: unset;
          max-height: unset;
          --card-explicit-min-height: unset;
          --card-explicit-max-height: unset;
          --card-explicit-height: 100%;
          --card-padding: 8px 12px;
        }
        :host insight-stepper {
          margin-right: -12px;
          padding-right: 36px;
        }
      }
      @media only screen and (max-width: 640px) {
        :host .card-title {
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.0125em;
          line-height: 32px;
          text-align: right;
        }
        :host insight-message-dialog {
          --dialog-max-width: 340px;
        }
      }
      @media only screen and (max-width: 440px) {
        :host #card-header {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        :host .card-title {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.0071428571em;
          line-height: 22px;
          margin-top: 4px;
          text-align: left;
        }
      }
    </style>
  `;
  }
}
window.customElements.define('customer-register-view', CustomerRegister);
