import cloneDeep from '../util/cloneDeep.js';

const appStateFactory = (function () {
  let _privateState;
  let _actions;
  let _mutations;
  let _callbacks;
  let _eventType;
  let _processCallbacks = (key, data, eventType) => {
    if (data === undefined || !_callbacks[key].length) return;
    let clearCache = false;
    _callbacks[key].forEach(callback => {
      if (callback(data, key, eventType)) clearCache = true;
    });
    if (clearCache) _privateState[key] = undefined;
  };
  let Enum = function () {
    for (var i in arguments) this[arguments[i]] = i;
  };

  class InsightAppState {
    constructor() {
      _actions = {};
      _mutations = {};
      _privateState = {};
      _callbacks = {};
      _eventType = new Enum('FETCH', 'CREATE', 'UPDATE', 'DELETE', 'RELATED');
      _privateState = new Proxy(
        {},
        {
          set: (state, key, value) => {
            state[key] = value;
            _processCallbacks(key, cloneDeep(_privateState[key]), _eventType.FETCH);
            return true;
          }
        }
      );
    }

    /****************************************************  Public Api  ****************************************************/

    get eventType() {
      return _eventType;
    }

    getEventTypeName(value) {
      return Object.keys(_eventType).find(k => _eventType[k] === value);
    }

    getCurrentStateForKey(key) {
      return cloneDeep(_privateState[key]);
    }

    setCurrentStateForKey(key, value) {
      _privateState[key] = value;
    }

    dispatch(actionKey, payload) {
      if (typeof _actions[actionKey] !== 'function') {
        console.error(`Action "${actionKey}" doesn't exist.`);
        return false;
      }
      _actions[actionKey](this, payload);
      return true;
    }

    fetchOneTime(key, callback, useCache, params = {}) {
      let value;
      if (useCache) value = this.getCurrentStateForKey(key);
      if (!value) {
        this._execOneTimeSubscription(_eventType.FETCH, key, callback, params);
      } else {
        callback(value, key, _eventType.FETCH);
      }
    }

    createOneTime(key, callback, params = {}) {
      this._execOneTimeSubscription(_eventType.CREATE, key, callback, params);
    }

    updateOneTime(key, callback, params = {}) {
      this._execOneTimeSubscription(_eventType.UPDATE, key, callback, params);
    }

    deleteOneTime(key, callback, params = {}) {
      this._execOneTimeSubscription(_eventType.DELETE, key, callback, params);
    }

    sendCommandOneTime(key, callback, params = {}) {
      this._execOneTimeSubscription(null, key, callback, params);
    }

    subscribe(key, callback) {
      if (typeof callback !== 'function') {
        console.error('You can only subscribe to state changes with a valid function');
        return false;
      }
      if (_callbacks[key]) {
        _callbacks[key].push(callback);
        return true;
      } else {
        console.error(`"${key}" is not a valid subscription key`);
        return false;
      }
    }

    unsubscribe(key, callback) {
      if (typeof callback !== 'function') {
        console.error('You can only unsubscribe to state changes with a valid function');
        return false;
      }
      if (_callbacks[key]) {
        const index = _callbacks[key].indexOf(callback);
        if (index !== -1) _callbacks[key].splice(index, 1);
        return true;
      } else {
        console.error(`"${key}" is not a valid unsubscription key`);
        return false;
      }
    }

    registerCreate(key, affectedKeys, actionFn) {
      this._registerUpdate(`create${this._capitalize(key)}`, key, affectedKeys, actionFn, _eventType.CREATE);
    }

    registerUpdate(key, affectedKeys, actionFn) {
      this._registerUpdate(`update${this._capitalize(key)}`, key, affectedKeys, actionFn, _eventType.UPDATE);
    }

    registerDelete(key, affectedKeys, actionFn) {
      this._registerUpdate(`delete${this._capitalize(key)}`, key, affectedKeys, actionFn, _eventType.DELETE);
    }

    registerFetch(key, actionFn) {
      this._registerCommand(`fetch${this._capitalize(key)}`, key, actionFn);
    }

    registerCommand(command, actionFn) {
      this._registerCommand(command, command, actionFn);
    }

    commit(key, payload) {
      if (typeof _mutations[key] !== 'function') {
        console.error(`Mutation "${key}" doesn't exist`);
        return false;
      }
      _mutations[key](_privateState, payload);
      return true;
    }

    /**************************************************  Private Methods  *************************************************/

    _execOneTimeSubscription(eventType, key, callback, params) {
      const wrappedCb = (s, k, t) => {
        const result = callback(s, k, t);
        this.unsubscribe(key, wrappedCb);
        return result;
      };
      this.subscribe(key, wrappedCb);
      let typeKey = this.getEventTypeName(eventType);
      if (typeKey) typeKey = typeKey.toLowerCase() + this._capitalize(key);
      else typeKey = key;
      this.dispatch(typeKey, params);
    }

    _registerUpdate(command, key, affectedKeys, actionFn, eventType) {
      if (['[object Function]', '[object AsyncFunction]'].includes({}.toString.call(affectedKeys))) {
        actionFn = affectedKeys;
        affectedKeys = [];
      }
      this._registerCommand(command, key, actionFn, (state, payload) => {
        _processCallbacks(key, payload, eventType);
        affectedKeys.forEach(k => _processCallbacks(k, payload, _eventType.RELATED));
      });
    }

    _registerCommand(command, key, actionFn, mutationFn) {
      const mutationKey = `handle${command[0].toUpperCase() + command.substr(1)}Result`;
      this._register(this._createAction(command, actionFn, mutationKey), this._createMutation(key, mutationKey, mutationFn), this._createInitialState(key));
    }

    _register(actions, mutations, initialState = {}) {
      _actions = Object.assign(_actions, actions);
      _mutations = Object.assign(_mutations, mutations);
      for (let key in initialState) {
        _callbacks[key] = [];
        _privateState[key] = initialState[key];
      }
    }

    _createAction(command, fn, mutationKey) {
      const action = {};
      if (typeof fn === 'string') action[command] = state => state.commit(mutationKey, state[fn]);
      else
        action[command] = function (state) {
          const args = [];
          args.push.apply(args, arguments) && args.shift();
          fn.call(null, data => state.commit(mutationKey, data), ...args);
        };
      return action;
    }

    _createMutation(key, mutationKey, fn) {
      const mutation = {};
      if (fn) {
        mutation[mutationKey] = fn;
      } else {
        mutation[mutationKey] = (state, payload) => {
          if (key) state[key] = payload;
        };
      }
      return mutation;
    }

    _createInitialState(key) {
      const initialState = {};
      if (key) initialState[key] = null;
      return initialState;
    }

    _capitalize(s) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    }
  }

  return function () {
    return new InsightAppState();
  };
})();

export default appStateFactory;
