import {InsightComponent, html} from '../../insight-component.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '../../button/insight-button.js';
import '../../button/insight-icon-button.js';
import '../../form/insight-dropdown-menu.js';
import '../../form/insight-form.js';
import '../../form/insight-input.js';
import '../../ui/insight-cc-icon.js';

class InsightPaymentAddCard extends InsightComponent {
  static get properties() {
    return {
      noPayment: {type: Boolean, attribute: 'no-payment'},
      cardDetails: {type: Object},
      options: {type: Object}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  getFocusEl() {
    return this._getElement('#ipt-card-num');
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.cardDetails = {};
  }

  firstUpdated() {
    this._afterRender(() => {
      this.__setupAddressDropdown();
      setTimeout(() => {
        this._getElement('#ipt-card-num').setFocus();
      }, 200);
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('options')) {
      if (this.options && this.options.editItem) {
        this.cardDetails = {
          editIndex:
            !isNaN(parseInt(this.options.editItem.editIndex)) && this.options.editItem.editIndex >= 0
              ? this.options.editItem.editIndex
              : this.options.cardsOnFile.indexOf(this.options.editItem),
          id: this.options.editItem.id,
          number: this.options.editItem.number,
          nameOnCard: this.options.editItem.nameOnCard,
          expireMonth: this.options.editItem.expireMonth,
          expireYear: this.options.editItem.expireYear,
          cvc: this.options.editItem.cvc,
          type: this.options.editItem.type,
          address: {
            address: this.options.editItem.address,
            city: this.options.editItem.city,
            state: this.options.editItem.state,
            country: this.options.editItem.country,
            postalCode: this.options.editItem.postalCode,
            phone: this.options.editItem.phone
          }
        };
      } else {
        this.cardDetails = {};
      }
      this.__setupAddressDropdown();
    }
  }

  __setupAddressDropdown() {
    const dd = this._getElement('#dd-address');
    if (!dd) return;
    let addresses = [];
    if (this.options) {
      if (this.options.addressesOnFile && !!this.options.addressesOnFile.length) {
        addresses = this.options.addressesOnFile.map(c => ({label: this.__buildAddressString(c), value: c}));
      }
      if (this.options.cardsOnFile && !!this.options.cardsOnFile.length) {
        addresses = addresses.concat(this.options.cardsOnFile.map(c => ({label: this.__buildAddressString(c), value: c})));
      }
      if (this.options.editItem && this.options.editItem.address) {
        const addyStr = this.__buildAddressString(this.options.editItem);
        const idx = addresses.findIndex(a => a.label === addyStr);
        if (idx < 0) addresses.unshift({label: addyStr, value: this.options.editItem, selected: true});
        else addresses[idx].selected = true;
      }
    }
    this.__noAddresses = !addresses.length;
    if (this.__noAddresses) addresses.push({label: this._i18n('payment', 'noAddresses', 'No billing addresses found'), value: 'no-address', selected: true});
    if (!addresses.some(a => a.selected === true)) {
      addresses[0].selected = true;
      this.__addressChanged({detail: addresses[0]});
    }
    dd.data = addresses;
  }

  __buildAddressString(address) {
    return `${address.address || ''}, ${address.city || ''}, ${address.state || ''} ${address.postalCode || ''} ${address.phone || ''}`;
  }

  __ccValidator(v) {
    return this.options.networks.indexOf(this.cardDetails.type) >= 0;
  }

  __updatePaymentExpiration(e) {
    const parts = e.detail.split('/');
    this.cardDetails.expireMonth = parts[0];
    this.cardDetails.expireYear = '20' + parts[1];
  }

  __getPaymentExpiration() {
    if (this.cardDetails.expireMonth) return `${this.cardDetails.expireMonth}/${this.cardDetails.expireYear.substr(-2)}`;
    else return undefined;
  }

  __updateCCType(e) {
    this.cardDetails.type = e.detail;
    this.requestUpdate();
  }

  __updateCard(e) {
    if (this.__noAddresses) {
      this._getElement('#dd-address').__setInvalid();
      return;
    }
    if (this.__formEl.validate()) {
      this.options.ach = null;
      this.options.paymentType = 'insight-card';
      const card = this.__mapCardDetails();
      if (this.cardDetails.editIndex >= 0) {
        this.options.cardsOnFile.splice(this.cardDetails.editIndex, 1, card);
        if (this.noPayment) this._dispatchEvent('finish');
        else this._dispatchEvent('change-view', 1);
      } else {
        this.options.cardsOnFile.unshift(card);
        if (this.noPayment) this._dispatchEvent('finish');
        else this._dispatchEvent('change-view', 0);
      }
    }
  }

  __addressChanged({detail: {value: address}}) {
    this.cardDetails.address = {
      address: address.address,
      city: address.city,
      postalCode: address.postalCode,
      state: address.state,
      country: address.country,
      phone: address.phone
    };
  }

  __mapCardDetails() {
    return {
      id: this.cardDetails.id,
      type: this.cardDetails.type,
      last4: this.cardDetails.number.slice(-4),
      number: this.cardDetails.number,
      nameOnCard: this.cardDetails.nameOnCard,
      cvc: this.cardDetails.cvc,
      address: this.cardDetails.address ? this.cardDetails.address.address : null,
      city: this.cardDetails.address ? this.cardDetails.address.city : null,
      state: this.cardDetails.address ? this.cardDetails.address.state : null,
      country: this.cardDetails.address ? this.cardDetails.address.country : null,
      postalCode: this.cardDetails.address ? this.cardDetails.address.postalCode : null,
      phone: this.cardDetails.address ? this.cardDetails.address.phone : null,
      expireMonth: this.cardDetails.expireMonth,
      expireYear: this.cardDetails.expireYear,
      editIndex: this.cardDetails.editIndex
    };
  }

  __addNewAddress() {
    this.options.editItem = this.__mapCardDetails();
    this._dispatchEvent('change-view', 6);
  }

  __goBack() {
    if (this.noPayment) {
      this._dispatchEvent('finish');
    } else {
      if (!this.options || !this.options.cardsOnFile || !this.options.cardsOnFile.length) this._dispatchEvent('change-view', 0);
      else this._dispatchEvent('change-view', 1);
    }
  }

  get __formEl() {
    return this._getElement('insight-form');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical addcard-container">
        <div class="flex-layout-horizontal flex-layout-center">
          ${!this.noPayment
            ? html`
                <insight-icon-button id="btnBack" icon-name="arrow_back" class="addcard-back" @click=${this.__goBack}></insight-icon-button>
              `
            : ''}
          <div class="typo-subhead addcard-title">
            <b>
              ${this._i18n('payment', this.cardDetails.editIndex >= 0 ? 'editCard' : 'addCard', this.cardDetails.editIndex >= 0 ? 'Edit Card' : 'Add Card')}
            </b>
          </div>
        </div>
        ${this.options.networks && this.options.networks.length
          ? html`
              <div class="typo-subhead acct-lbl">${this._i18n('payment', 'acceptCards', 'Accepted Cards')}</div>
              <div class="flex-layout-horizontal">
                ${this.options.networks.indexOf('amex') >= 0
                  ? html`
                      <insight-cc-icon cc-type="amex"></insight-cc-icon>
                    `
                  : ''}
                ${this.options.networks.indexOf('discover') >= 0
                  ? html`
                      <insight-cc-icon cc-type="discover"></insight-cc-icon>
                    `
                  : ''}
                ${this.options.networks.indexOf('mastercard') >= 0
                  ? html`
                      <insight-cc-icon cc-type="mastercard"></insight-cc-icon>
                    `
                  : ''}
                ${this.options.networks.indexOf('visa') >= 0
                  ? html`
                      <insight-cc-icon cc-type="visa"></insight-cc-icon>
                    `
                  : ''}
              </div>
            `
          : ''}
        <insight-form id="addcard-form" class="flex-layout-flex">
          <div class="flex-layout-horizontal form-row">
            <insight-input
              autocomplete="cc-number"
              dense
              class="flex-layout-flex"
              id="ipt-card-num"
              label=${this._i18n('payment', 'cardNum', 'Card Number')}
              required
              type="cc"
              error-message=${this._i18n('payment', 'error.unrecFormat', 'Unrecognized Format')}
              initial-value=${ifDefined(this.cardDetails.number)}
              @change=${e => (this.cardDetails.number = e.detail)}
              @cc-type-change=${this.__updateCCType}
              .validator=${this.__ccValidator.bind(this)}
            >
              <insight-cc-icon slot="prefix" cc-type=${this.cardDetails.type}></insight-cc-icon>
            </insight-input>
            <insight-input
              autocomplete="cc-exp"
              dense
              class="flex-layout-flex"
              id="ipt-expire"
              label=${this._i18n(null, 'expires', 'Expires')}
              required
              type="monthyear"
              placeholder="MM/YY"
              error-message=${this._i18n(null, 'requiredLbl', 'Required')}
              initial-value=${ifDefined(this.__getPaymentExpiration())}
              @change=${this.__updatePaymentExpiration}
            ></insight-input>
          </div>
          <div class="flex-layout-horizontal form-row">
            <insight-input
              autocomplete="name"
              dense
              class="flex-layout-flex"
              id="ipt-name-on-card"
              label=${this._i18n(null, 'nameOnCard', 'Name on Card')}
              required
              error-message=${this._i18n(null, 'error.nameReq', 'Name is Required')}
              initial-value=${ifDefined(this.cardDetails.nameOnCard)}
              @change=${e => (this.cardDetails.nameOnCard = e.detail)}
            ></insight-input>
            ${this.options.skipCVC
              ? ''
              : html`
                  <insight-input
                    autocomplete="cc-csc"
                    dense
                    class="flex-layout-flex"
                    id="ipt-cvc"
                    pattern="[0-9]{3,4}"
                    maxlength="4"
                    label=${this._i18n('payment', 'cvc', 'CVC')}
                    required
                    error-message=${this._i18n('payment', 'error.cvcReq', 'Invalid CVC')}
                    initial-value=${ifDefined(this.cardDetails.cvc)}
                    @change=${e => (this.cardDetails.cvc = e.detail)}
                  >
                    <insight-cc-icon id="cvc-icon" slot="prefix"></insight-cc-icon>
                  </insight-input>
                `}
          </div>
          <div class="flex-layout-horizontal form-row">
            <insight-dropdown-menu
              dense
              class="flex-layout-flex"
              id="dd-address"
              fixed-width
              label=${this._i18n('payment', 'billingAddress', 'Billing Address')}
              required
              error-message=${this._i18n(null, 'error.addressReq', 'Address is Required')}
              @change=${this.__addressChanged}
              ?disabled=${this.__noAddresses}
            ></insight-dropdown-menu>
            <insight-button id="btn-add-address" outlined @click=${this.__addNewAddress}>
              ${this._i18n(null, 'add', 'Add')}
            </insight-button>
          </div>
        </insight-form>
        <div class="flex-layout-horizontal flex-layout-end-justified addcard-footer">
          ${!!this.options && (this.options.noInitialCardsOnFile || this.noPayment)
            ? html`
                <insight-button
                  class=${!!this.options && this.options.acceptACH ? 'flex-layout-flex' : ''}
                  outlined
                  @click=${() => this._dispatchEvent('close-dialog')}
                >
                  ${this.options && this.options.cancelButtonLabel ? this._i18nDirect(this.options.cancelButtonLabel) : this._i18n(null, 'cancel', 'Cancel')}
                </insight-button>
              `
            : ''}
          ${!!this.options && this.options.acceptACH
            ? html`
                <insight-button id="btnACH" outlined @click=${() => this._dispatchEvent('change-view', 7)}>
                  ${this._i18n('payment', 'useAch', 'Use ACH')}
                </insight-button>
              `
            : ''}
          <insight-button id="btnUpdate" contained @click=${this.__updateCard}>
            ${this._i18n('payment', 'done', 'Done')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .addcard-container {
          height: 100%;
        }
        :host .addcard-back {
          margin-left: -16px;
          margin-top: -12px;
        }
        :host .addcard-title {
          margin-bottom: 12px;
        }
        :host #ipt-card-num {
          --input-prefix-width: 56px;
        }
        :host insight-cc-icon {
          font-size: 4px;
          margin: 0 8px 12px 0;
          width: 40px;
        }
        :host #ipt-card-num insight-cc-icon {
          margin: 0 0 0 4px;
        }
        :host #ipt-expire {
          margin-left: 24px;
          min-width: 200px;
          max-width: 200px;
        }
        :host #ipt-cvc {
          margin-left: 24px;
          min-width: 110px;
          max-width: 110px;
          --input-prefix-width: 56px;
        }
        :host #cvc-icon {
          margin-left: 4px;
          position: absolute;
        }
        :host #cvc-icon:after {
          content: '';
          border: 2px solid red;
          position: absolute;
          right: 3px;
          top: 18px;
          height: 5px;
          width: 8px;
          border-radius: 50%;
        }
        :host #addcard-form {
          margin-top: 8px;
        }
        :host #dd-address {
          margin-right: 24px;
        }
        :host #btn-add-address {
          --button-height: 40px;
        }
        :host .acct-lbl {
          transform: scale(0.75);
          transform-origin: left;
          color: var(--mdc-theme-text-secondary);
        }
        :host .addcard-footer {
          padding: 8px 0;
        }
        :host .addcard-footer insight-button {
          --button-min-width: 112px;
        }
        :host .addcard-footer insight-button:not(:first-child) {
          margin-left: 16px;
        }
        @media only screen and (max-width: 620px) {
          :host .form-row:first-child {
            flex-wrap: wrap;
          }
          :host .form-row:first-child > * {
            min-width: 100% !important;
            max-width: 100% !important;
            margin: 0 !important;
          }
        }
        @media only screen and (max-width: 400px) {
          :host .addcard-footer {
            flex-wrap: wrap;
          }
          :host .addcard-footer insight-button {
            flex: none;
            margin-top: 16px;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-add-card', InsightPaymentAddCard);
