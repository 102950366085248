const AccountUtils = {
  getAccountStatusCssColor(accountType, accountStatus) {
    if (accountType === 'GUEST') return 'var(--mdc-theme-text-secondary)';
    switch (accountStatus) {
      case 'GREEN':
        return 'var(--mdc-theme-text-success)';
      case 'YELLOW':
        return 'var(--mdc-theme-text-warning);text-transform:uppercase';
      case 'CANCELLED':
      case 'COLLECTIONS':
      case 'RED':
        return 'var(--mdc-theme-text-error);text-transform:uppercase';
      default:
        return 'var(--mdc-theme-text-secondary)';
    }
  },
  cleanUserRole(role) {
    const words = role.toLowerCase().split('_');
    return words.map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
  }
};

export {AccountUtils};
