import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nDate} from '@insight/insight-common/i18n/i18n-date.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/form/insight-checkbox.js';
import '@insight/insight-common/components/form/insight-dropdown-menu.js';
import '@insight/insight-common/components/form/insight-form.js';
import '@insight/insight-common/components/form/insight-input.js';

class RegisterLocation extends InsightElement {
  static get properties() {
    return {
      registration: {type: Object}
    };
  }

  firstUpdated() {
    this._afterRender(() => {
      this._dispatchEvent('step-load', this);
      this.__setupTZDropdown();
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('registration')) this._afterRender(() => this._getElement('#ipt-biz-name').setFocus());
  }

  __setupTZDropdown() {
    this.registration.location.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this._afterRender(() => {
      this._getElement('#dd-timezone').data = i18nDate.getTimezones().map(t => ({label: t, value: t, selected: t === this.registration.location.timezone}));
    });
  }

  __finish() {
    if (!this.__formEl.validate()) return;
    if (!!this.registration.clientGuid) {
      this.registration.contact.fullName = this.registration.contact.firstName + ' ' + this.registration.contact.lastName;
      this.registration.contact.phone = this.registration.location.phone;
    }
    this._dispatchEvent('move-next', 'Location information step completed');
  }

  get __formEl() {
    return this._getElement('insight-form');
  }

  _render() {
    if (!this.registration) return;
    return html`
      ${unsafeHTML(this.__css)}
      <div>
        <div class="typo-title">${this._i18n('location:register.yourLocation')}</div>
        <insight-form>
          <div>
            <insight-input
              dense
              label="${this._i18n('location:register.bizName')}"
              id="ipt-biz-name"
              required
              error-message=${this._i18n('location:register.error.bizNameReq')}
              initial-value=${ifDefined(this.registration.location.name)}
              @change=${e => (this.registration.location.name = e.detail)}
            ></insight-input>
            ${!!this.registration.clientGuid
              ? html`
                  <div class="flex-layout-wrap flex-layout-horizontal flex-layout-justified form-row">
                    <insight-input
                      dense
                      id="ipt-first-name"
                      label=${this._i18n('common:firstName')}
                      required
                      error-message=${this._i18n('common:error.firstNameReq')}
                      initial-value=${ifDefined(this.registration.contact.firstName)}
                      @change=${e => (this.registration.contact.firstName = e.detail)}
                    ></insight-input>
                    <insight-input
                      dense
                      id="ipt-last-name"
                      label=${this._i18n('common:lastName')}
                      required
                      error-message=${this._i18n('common:error.lastNameReq')}
                      initial-value=${ifDefined(this.registration.contact.lastName)}
                      @change=${e => (this.registration.contact.lastName = e.detail)}
                    ></insight-input>
                  </div>
                  <insight-input
                    dense
                    id="ipt-email"
                    label=${this._i18n('location:register.bizEmail')}
                    type="email"
                    required
                    error-message=${this._i18n('common:error.emailReq')}
                    initial-value=${ifDefined(this.registration.contact.email)}
                    @change=${e => (this.registration.contact.email = e.detail)}
                  ></insight-input>
                `
              : ''}
            <insight-input
              dense
              label="${this._i18n('location:register.bizPhone')}"
              id="ipt-biz-phone"
              autocomplete="billing tel"
              type="tel"
              required
              error-message=${this._i18n('location:register.error.bizNumReq')}
              initial-value=${ifDefined(this.registration.location.phone)}
              @change=${e => (this.registration.location.phone = e.detail)}
            ></insight-input>
            <insight-dropdown-menu
              dense
              id="dd-timezone"
              label=${this._i18n('location:register.locationTZ')}
              required
              error-message=${this._i18n('location:register.error.bizTZReq')}
              value-as-selected
              fixed-width
              @change=${e => (this.registration.location.timezone = e.detail.value)}
            ></insight-dropdown-menu>
            ${!this.registration.clientGuid
              ? html`
                  <insight-checkbox id="chk-terms" required required-message=${this._i18n('location:register.error.termsReq')}>
                    <span>
                      ${this._i18n('location:register.termsAgree')}
                      <a href="https://www.gyminsight.com/terms-of-service.html" target="_blank">${this._i18n('location:register.termsAgree2')}</a>
                    </span>
                  </insight-checkbox>
                `
              : ''}
          </div>
        </insight-form>
        <div class="footer-btns">
          <insight-button contained @click=${this.__finish}>${this._i18n('common:next')}</insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host insight-form {
        margin-top: 12px;
        max-width: 480px;
        display: block;
      }
      :host .form-row {
        margin: 0 -2%;
      }
      :host .form-row > * {
        flex-grow: 1;
        flex-basis: calc(calc(400px - 100%) * 999);
        margin: 0 2%;
      }
      :host insight-checkbox {
        margin-left: -10px;
      }
      :host .footer-btns {
        margin: 10px 0;
      }
      :host .terms-error {
        color: var(--mdc-theme-error);
      }
    </style>
  `;
  }
}
window.customElements.define('register-location', RegisterLocation);
