import {default as createAppState} from '@insight/insight-common/state/insight-state-factory.js';
import {Mutex} from 'async-mutex';
import InsightAPI from './api/insight-api.js';
import InsightSharedWebsocket from './api/insight-shared-websocket.js';

const __insightApi = new InsightAPI();
const __sharedSocket = new InsightSharedWebsocket();
window.Insightsocket = __sharedSocket;

const __startActivitySubscription = async (callback, payload) => {
  await __sharedSocket.startSubscription(__insightApi, 'access', 'startActivitySubscription', callback, payload);
};

const __startNotificationSubscription = async (callback, payload) => {
  await __sharedSocket.startSubscription(__insightApi, 'notifications', 'startNotificationSubscription', callback, payload);
};

/************************************************************************************************/
/****  App State  *******************************************************************************/
/************************************************************************************************/

let __appState = createAppState();

const __initialize = () => {
  //shell
  __appState.registerFetch('mvcCookie', (...args) => __insightApi.invoke('shell', 'getMvcCookie', ...args));
  __appState.registerFetch('locations', (...args) => __insightApi.invoke('shell', 'getLocations', ...args));
  __appState.registerFetch('locationDetails', (...args) => __insightApi.invoke('shell', 'getLocationDetails', ...args));
  __appState.registerCommand('changeLocation', (...args) => __insightApi.invoke('shell', 'changeLocation', ...args));
  __appState.registerUpdate('askAQuestion', (...args) => __insightApi.invoke('shell', 'postAskAQuestion', ...args));

  //user
  __appState.registerFetch('userDetails', (...args) => __insightApi.invoke('user', 'getUserDetails', ...args));
  __appState.registerFetch('userUpdates', (...args) => __insightApi.invoke('user', 'getUserUpdates', ...args));
  __appState.registerUpdate('userDetails', (...args) => __insightApi.invoke('user', 'updateUserDetails', ...args));
  __appState.registerUpdate('username', (...args) => __insightApi.invoke('user', 'updateUsername', ...args));
  __appState.registerUpdate('password', (...args) => __insightApi.invoke('user', 'updatePassword', ...args));
  __appState.registerFetch('userPermissions', (...args) => __insightApi.invoke('user', 'getUserPermissions', ...args));
  __appState.registerUpdate('userPermissions', (...args) => __insightApi.invoke('user', 'updateUserPermissions', ...args));
  __appState.registerFetch('userSubscriptions', (...args) => __insightApi.invoke('user', 'getUserSubscriptions', ...args));
  __appState.registerUpdate('userSubscription', (...args) => __insightApi.invoke('user', 'updateUserSubscription', ...args));
  __appState.registerFetch('commissionGroups', (...args) => __insightApi.invoke('user', 'getCommissionGroups', ...args));
  __appState.registerFetch('userAppointmentTypes', (...args) => __insightApi.invoke('user', 'getUserAppointmentTypes', ...args));
  __appState.registerCreate('userAppointmentType', (...args) => __insightApi.invoke('user', 'createUserAppointmentType', ...args));
  __appState.registerDelete('userAppointmentType', (...args) => __insightApi.invoke('user', 'deleteUserAppointmentType', ...args));
  __appState.registerFetch('userApptTimeSlots', (...args) => __insightApi.invoke('user', 'getUserApptTimeSlots', ...args));
  __appState.registerCreate('userApptTimeSlot', (...args) => __insightApi.invoke('user', 'createUserApptTimeSlot', ...args));
  __appState.registerDelete('userApptTimeSlot', (...args) => __insightApi.invoke('user', 'deleteUserApptTimeSlot', ...args));
  __appState.registerUpdate('userPreference', (...args) => __insightApi.invoke('user', 'updateUserPreference', ...args));

  //notifications
  __appState.registerFetch('recentNotifications', (...args) => __insightApi.invoke('notifications', 'getRecentNotifications', ...args));
  __appState.registerFetch('recentNotificationUpdates', __startNotificationSubscription.bind(this));

  //search
  __appState.registerFetch('search', (...args) => __insightApi.invoke('search', 'performSearch', ...args));

  //admin
  __appState.registerFetch('sybgs', (...args) => __insightApi.invoke('admin', 'getSybgs', ...args));
  __appState.registerFetch('sybg', (...args) => __insightApi.invoke('admin', 'getSybg', ...args));
  __appState.registerCreate('sybg', (...args) => __insightApi.invoke('admin', 'createSybg', ...args));
  __appState.registerUpdate('sybg', (...args) => __insightApi.invoke('admin', 'updateSybg', ...args));
  __appState.registerDelete('sybg', (...args) => __insightApi.invoke('admin', 'deleteSybg', ...args));
  __appState.registerFetch('purchaseOrderItems', (...args) => __insightApi.invoke('admin', 'getPurchaseOrderItems', ...args));
  __appState.registerCreate('purchaseOrder', (...args) => __insightApi.invoke('admin', 'createPurchaseOrder', ...args));
  __appState.registerFetch('clientPricingList', (...args) => __insightApi.invoke('admin', 'getClientPricingList', ...args));
  __appState.registerFetch('clientPricingChange', (...args) => __insightApi.invoke('admin', 'getClientPricingChange', ...args));
  __appState.registerUpdate('clientPricingChange', (...args) => __insightApi.invoke('admin', 'updateClientPricingChange', ...args));
  __appState.registerFetch('clientPricingHistory', (...args) => __insightApi.invoke('admin', 'getClientPricingHistory', ...args));
  __appState.registerFetch('locationPerformance', (...args) => __insightApi.invoke('admin', 'getLocationPerfromance', ...args));
  __appState.registerCommand('sendSystemMessage', (...args) => __insightApi.invoke('admin', 'sendSystemMessage', ...args));

  //client
  __appState.registerFetch('clientCustomPricing', (...args) => __insightApi.invoke('client', 'getCustomPricing', ...args));
  __appState.registerCreate('location', (...args) => __insightApi.invoke('client', 'createLocation', ...args));

  //location
  __appState.registerFetch('locationUsers', (...args) => __insightApi.invoke('location', 'getUsers', ...args));
  __appState.registerFetch('appointmentTypes', (...args) => __insightApi.invoke('location', 'getAppointmentTypes', ...args));
  __appState.registerCreate('appointmentType', (...args) => __insightApi.invoke('location', 'createAppointmentType', ...args));
  __appState.registerUpdate('appointmentType', (...args) => __insightApi.invoke('location', 'updateAppointmentType', ...args));
  __appState.registerDelete('appointmentType', (...args) => __insightApi.invoke('location', 'deleteAppointmentType', ...args));
  __appState.registerFetch('calendarWidgets', (...args) => __insightApi.invoke('location', 'getCalendarWidgets', ...args));
  __appState.registerCreate('calendarWidget', (...args) => __insightApi.invoke('location', 'createCalendarWidget', ...args));
  __appState.registerUpdate('calendarWidget', (...args) => __insightApi.invoke('location', 'updateCalendarWidget', ...args));
  __appState.registerDelete('calendarWidget', (...args) => __insightApi.invoke('location', 'deleteCalendarWidget', ...args));
  __appState.registerFetch('membershipWidgets', (...args) => __insightApi.invoke('location', 'getMembershipWidgets', ...args));
  __appState.registerCreate('membershipWidget', (...args) => __insightApi.invoke('location', 'createMembershipWidget', ...args));
  __appState.registerUpdate('membershipWidget', (...args) => __insightApi.invoke('location', 'updateMembershipWidget', ...args));
  __appState.registerDelete('membershipWidget', (...args) => __insightApi.invoke('location', 'deleteMembershipWidget', ...args));
  __appState.registerFetch('appointmentWidgets', (...args) => __insightApi.invoke('location', 'getAppointmentWidgets', ...args));
  __appState.registerCreate('appointmentWidget', (...args) => __insightApi.invoke('location', 'createAppointmentWidget', ...args));
  __appState.registerUpdate('appointmentWidget', (...args) => __insightApi.invoke('location', 'updateAppointmentWidget', ...args));
  __appState.registerDelete('appointmentWidget', (...args) => __insightApi.invoke('location', 'deleteAppointmentWidget', ...args));
  __appState.registerFetch('pricingLatest', (...args) => __insightApi.invoke('location', 'getPricingLatest', ...args));
  __appState.registerFetch('pricingHistory', (...args) => __insightApi.invoke('location', 'getPricingHistory', ...args));
  __appState.registerFetch('billingInfo', (...args) => __insightApi.invoke('location', 'getBillingInfo', ...args));
  __appState.registerFetch('billingHistory', (...args) => __insightApi.invoke('location', 'getBillingHistory', ...args));
  __appState.registerUpdate('locationPaymentMethod', (...args) => __insightApi.invoke('location', 'updatePaymentMethod', ...args));
  __appState.registerFetch('guruSettings', (...args) => __insightApi.invoke('location', 'getGuruSettings', ...args));
  __appState.registerUpdate('guruSettings', (...args) => __insightApi.invoke('location', 'updateGuruSettings', ...args));
  __appState.registerFetch('locationDocuments', (...args) => __insightApi.invoke('location', 'getLocationDocuments', ...args));
  __appState.registerUpdate('guruBackground', (...args) => __insightApi.invoke('location', 'uploadGuruBackground', ...args));
  __appState.registerDelete('guruBackground', (...args) => __insightApi.invoke('location', 'removeGuruBackground', ...args));

  //accounts
  __appState.registerFetch('accountDetails', (...args) => __insightApi.invoke('accounts', 'getAccountDetails', ...args));
  __appState.registerFetch('accountTasks', (...args) => __insightApi.invoke('accounts', 'getAccountTasks', ...args));
  __appState.registerFetch('accountOverview', (...args) => __insightApi.invoke('accounts', 'getAccountOverview', ...args));
  __appState.registerFetch('accountContactInfo', (...args) => __insightApi.invoke('accounts', 'getAccountContactInfo', ...args));
  __appState.registerFetch('accountBillingInfo', (...args) => __insightApi.invoke('accounts', 'getAccountBillingInfo', ...args));
  __appState.registerFetch('accountPaymentInfo', (...args) => __insightApi.invoke('accounts', 'getAccountPaymentInfo', ...args));
  __appState.registerFetch('accountInsightInfo', (...args) => __insightApi.invoke('accounts', 'getAccountInsightInfo', ...args));
  __appState.registerFetch('accountSchedPayments', (...args) => __insightApi.invoke('accounts', 'getSchedPayments', ...args));
  __appState.registerFetch('accountMembers', (...args) => __insightApi.invoke('accounts', 'getAccountMembers', ...args));
  __appState.registerFetch('accountStatement', (...args) => __insightApi.invoke('accounts', 'getAccountStatement', ...args));
  __appState.registerFetch('accountAgreements', (...args) => __insightApi.invoke('accounts', 'getAgreements', ...args));
  __appState.registerFetch('accountFiles', (...args) => __insightApi.invoke('accounts', 'getAccountFiles', ...args));
  __appState.registerFetch('documentsList', (...args) => __insightApi.invoke('accounts', 'getDocumentsList', ...args));
  __appState.registerFetch('accountSms', (...args) => __insightApi.invoke('accounts', 'getAccountSms', ...args));
  __appState.registerFetch('accountHistory', (...args) => __insightApi.invoke('accounts', 'getAccountHistory', ...args));
  __appState.registerFetch('accountSummary', (...args) => __insightApi.invoke('accounts', 'getAccountSummary', ...args));
  __appState.registerCreate('accountNote', (...args) => __insightApi.invoke('accounts', 'createAccountNote', ...args));
  __appState.registerCommand('checkinAccount', (...args) => __insightApi.invoke('accounts', 'checkinAccount', ...args));
  __appState.registerDelete('accountFlag', (...args) => __insightApi.invoke('accounts', 'deleteAccountFlag', ...args));
  __appState.registerDelete('accountTag', (...args) => __insightApi.invoke('accounts', 'deleteAccountTag', ...args));
  __appState.registerDelete('accountAgreement', (...args) => __insightApi.invoke('accounts', 'deleteAgreement', ...args));
  __appState.registerCreate('accountFile', (...args) => __insightApi.invoke('accounts', 'uploadFile', ...args));
  __appState.registerDelete('accountFile', (...args) => __insightApi.invoke('accounts', 'deleteFile', ...args));
  __appState.registerCommand('sendAgreementEmail', (...args) => __insightApi.invoke('accounts', 'emailAgreement', ...args));
  __appState.registerCommand('sendAccountFile', (...args) => __insightApi.invoke('accounts', 'emailFile', ...args));
  __appState.registerFetch('transactionItems', (...args) => __insightApi.invoke('accounts', 'getTransactionItems', ...args));
  __appState.registerUpdate('accountTransaction', (...args) => __insightApi.invoke('accounts', 'updateAccountTransaction', ...args));
  __appState.registerCreate('guestAccount', (...args) => __insightApi.invoke('accounts', 'createGuestAccount', ...args));
  __appState.registerCommand('cloneAccount', (...args) => __insightApi.invoke('accounts', 'cloneAccount', ...args));

  //accounts - members
  __appState.registerFetch('members', (...args) => __insightApi.invoke('accounts', 'getMembers', ...args));
  __appState.registerFetch('memberDetails', (...args) => __insightApi.invoke('accounts', 'getMemberDetails', ...args));
  __appState.registerFetch('memberLogins', (...args) => __insightApi.invoke('accounts', 'getMemberLogins', ...args));
  __appState.registerUpdate('memberLogins', (...args) => __insightApi.invoke('accounts', 'updateMemberLogins', ...args));
  __appState.registerUpdate('member', (...args) => __insightApi.invoke('accounts', 'updateMember', ...args));
  __appState.registerCreate('member', (...args) => __insightApi.invoke('accounts', 'addMember', ...args));
  __appState.registerFetch('memberHistory', (...args) => __insightApi.invoke('accounts', 'getMemberHistory', ...args));
  __appState.registerFetch('memberCredentials', (...args) => __insightApi.invoke('accounts', 'getMemberCredentials', ...args));
  __appState.registerFetch('memberVisits', (...args) => __insightApi.invoke('accounts', 'getMemberVisits', ...args));
  __appState.registerFetch('memberAttendance', (...args) => __insightApi.invoke('accounts', 'getMemberAttendance', ...args));
  __appState.registerFetch('memberAddons', (...args) => __insightApi.invoke('accounts', 'getMemberAddons', ...args));
  __appState.registerDelete('member', (...args) => __insightApi.invoke('accounts', 'deleteMember', ...args));
  __appState.registerDelete('memberCredential', (...args) => __insightApi.invoke('accounts', 'deleteMemberCredential', ...args));
  __appState.registerCreate('memberCredential', ['recentActivity'], (...args) => __insightApi.invoke('accounts', 'addCredentialToMember', ...args));
  __appState.registerUpdate('memberCredential', (...args) => __insightApi.invoke('accounts', 'updateMemberCredential', ...args));
  __appState.registerCommand('checkInAddon', (...args) => __insightApi.invoke('accounts', 'checkInAddon', ...args));
  __appState.registerCreate('memberAddon', (...args) => __insightApi.invoke('accounts', 'createMemberAddon', ...args));
  __appState.registerUpdate('memberAddon', (...args) => __insightApi.invoke('accounts', 'updateMemberAddon', ...args));
  __appState.registerDelete('memberAddon', (...args) => __insightApi.invoke('accounts', 'deleteMemberAddon', ...args));
  __appState.registerFetch('memberAddonSessions', (...args) => __insightApi.invoke('accounts', 'getMemberAddonSessions', ...args));
  __appState.registerDelete('memberAddonSession', (...args) => __insightApi.invoke('accounts', 'deleteMemberAddonSession', ...args));
  __appState.registerUpdate('memberPhoto', (...args) => __insightApi.invoke('accounts', 'updateMemberPhoto', ...args));
  __appState.registerCommand('resetMemberPassword', (...args) => __insightApi.invoke('accounts', 'resetMemberPassword', ...args));
  __appState.registerCommand('mergeMember', (...args) => __insightApi.invoke('accounts', 'mergeMember', ...args));
  __appState.registerCommand('moveMember', (...args) => __insightApi.invoke('accounts', 'moveMember', ...args));
  __appState.registerCreate('memberNote', (...args) => __insightApi.invoke('accounts', 'createMemberNote', ...args));
  __appState.registerCommand('setMemberPrimary', (...args) => __insightApi.invoke('accounts', 'setMemberPrimary', ...args));

  //training
  __appState.registerFetch('trainingClients', (...args) => __insightApi.invoke('training', 'getTrainingClients', ...args));
  __appState.registerFetch('oldTrainingClients', (...args) => __insightApi.invoke('training', 'getOldTrainingClients', ...args));
  __appState.registerFetch('oldTrainingClient', (...args) => __insightApi.invoke('training', 'getOldTrainingClient', ...args));
  __appState.registerDelete('oldTrainingClient', (...args) => __insightApi.invoke('training', 'deleteOldTrainingClient', ...args));
  __appState.registerCommand('migrateOldTrainingClient', (...args) => __insightApi.invoke('training', 'migrateOldTrainingClient', ...args));

  //access
  __appState.registerFetch('doors', (...args) => __insightApi.invoke('access', 'getDoors', ...args));
  __appState.registerFetch('recentActivity', (...args) => __insightApi.invoke('access', 'getRecentActivity', ...args));
  __appState.registerFetch('recentActivityUpdates', __startActivitySubscription.bind(this));

  //calendar
  __appState.registerFetch('calendarOptions', (...args) => __insightApi.invoke('calendar', 'getCalendarOptions', ...args));
  __appState.registerFetch('calendarData', (...args) => __insightApi.invoke('calendar', 'getCalendarData', ...args));
  __appState.registerCreate('event', (...args) => __insightApi.invoke('calendar', 'createEvent', ...args));
  __appState.registerFetch('event', (...args) => __insightApi.invoke('calendar', 'getEvent', ...args));
  __appState.registerUpdate('event', (...args) => __insightApi.invoke('calendar', 'updateEvent', ...args));
  __appState.registerDelete('event', (...args) => __insightApi.invoke('calendar', 'cancelEvent', ...args));
  __appState.registerFetch('eventReservations', (...args) => __insightApi.invoke('calendar', 'getEventReservations', ...args));
  __appState.registerCreate('eventReservation', (...args) => __insightApi.invoke('calendar', 'createReservation', ...args));
  __appState.registerDelete('eventReservation', (...args) => __insightApi.invoke('calendar', 'cancelReservation', ...args));
  __appState.registerFetch('scheduleReservations', (...args) => __insightApi.invoke('calendar', 'getScheduleReservations', ...args));
  __appState.registerCommand('sendMessageToClassReservations', (...args) => __insightApi.invoke('calendar', 'sendMessageToClassReservations', ...args));
  __appState.registerUpdate('eventAttendance', (...args) => __insightApi.invoke('calendar', 'updateEventAttendance', ...args));

  // location dashboard (deprecated)
  __appState.registerFetch('locationDashboardGoals', (...args) => __insightApi.invoke('dashboard', 'getGoals', ...args));
  __appState.registerFetch('locationDashboardEffectiveness', (...args) => __insightApi.invoke('dashboard', 'getEffectiveness', ...args));
  __appState.registerFetch('locationDashboardStatistics', (...args) => __insightApi.invoke('dashboard', 'getDailyStatistics', ...args));

  return __appState;
};

export default __initialize;
