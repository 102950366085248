import {InsightComponent, html} from '../../insight-component.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nMoney} from '../../../i18n/i18n-money.js';
import '../../button/insight-button.js';
import '../../button/insight-icon-button.js';

class InsightPaymentSummary extends InsightComponent {
  static get properties() {
    return {
      orderDetails: {type: Object},
      options: {type: Object}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  getFocusEl() {
    return this._getElement('#btnBack');
  }

  /**************************************************  Private Methods  *************************************************/

  __checkInvalid() {
    return (
      !this.options ||
      ((!this.options.cardsOnFile || !this.options.contactsOnFile || !this.options.cardsOnFile.length || !this.options.contactsOnFile.length) &&
        !this.options.ach)
    );
  }

  __purchase() {
    if (this.__checkInvalid()) this._dispatchEvent('change-view', 0);
    else this._dispatchEvent('purchase');
  }

  _render() {
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical summary-container">
        ${!this.orderDetails
          ? html`
              <div id="summary-loader" class="flex-layout-horizontal flex-layout-center-center">
                <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
              </div>
            `
          : html`
              <div class="flex-layout-horizontal flex-layout-center">
                <insight-icon-button
                  id="btnBack"
                  icon-name="arrow_back"
                  class="summary-back"
                  @click=${() => this._dispatchEvent('change-view', 0)}
                ></insight-icon-button>
                <div class="typo-subhead summary-title"><b>${this._i18n('payment', 'orderSummary', 'Order Summary')}</b></div>
              </div>
              <div class="flex-layout-horizontal summary-section">
                <div class="flex-layout-flex summary-section-value">
                  ${this.orderDetails.displayItems.map(i => {
                    return html`
                      <div class="flex-layout-horizontal flex-layout-justified typo-body2 summary-value-line">
                        <div>${i.label}</div>
                        <div>${i18nMoney.format(i.amount.value, i.amount.currency, this._i18nDirect)}</div>
                      </div>
                    `;
                  })}
                  <div class="flex-layout-horizontal flex-layout-justified typo-subhead2 summary-value-line summary-value-total">
                    <div class="flex-layout-flex">${this.orderDetails.total.label}</div>
                    <div class="summary-curr">${this.orderDetails.total.amount.currency}</div>
                    <div>${i18nMoney.format(this.orderDetails.total.amount.value, this.orderDetails.total.amount.currency, this._i18nDirect)}</div>
                  </div>
                </div>
              </div>
            `}
        <div class="flex-layout-flex"></div>
        <div class="flex-layout-horizontal flex-layout-end-justified summary-footer">
          <insight-button outlined @click=${() => this._dispatchEvent('close-dialog')}>
            ${this.options && this.options.cancelButtonLabel ? this._i18nDirect(this.options.cancelButtonLabel) : this._i18n(null, 'cancel', 'Cancel')}
          </insight-button>
          <insight-button id="btnPay" contained ?disabled=${!this.orderDetails} @click=${this.__purchase}>
            ${this.__checkInvalid(this.options)
              ? this._i18n(null, 'back', 'Back')
              : !!this.options.submitButtonLabel
              ? this._i18nDirect(this.options.submitButtonLabel)
              : this._i18n(null, 'pay', 'Pay')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .summary-container {
          height: 100%;
        }
        :host .summary-back {
          margin-left: -16px;
          margin-top: -12px;
        }
        :host .summary-title {
          margin-bottom: 12px;
        }
        :host .summary-section {
          overflow-y: auto;
          margin-right: -20px;
          padding-right: 20px;
          margin-bottom: 8px;
        }
        :host .summary-value-line {
          border-bottom: 1px solid var(--mdc-theme-divider-color);
          padding: 6px 0;
          line-height: 24px;
        }
        :host .summary-value-line > div:first-child {
          max-width: 464px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        :host .summary-curr {
          width: 52px;
        }
        :host .summary-footer {
          padding: 8px 0;
        }
        :host .summary-footer insight-button {
          margin-left: 16px;
          --button-min-width: 112px;
        }
        :host #summary-loader {
          height: 100%;
        }
        @media only screen and (max-width: 620px) {
          :host .summary-value-line > div:first-child {
            max-width: calc(95vw - 112px);
          }
          :host .summary-section {
            margin-right: -8px;
            padding-right: 8px;
            margin-bottom: 8px;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-summary', InsightPaymentSummary);
