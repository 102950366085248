import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nPhone} from '@insight/insight-common/i18n/i18n-phone.js';
import '@insight/insight-common/components/button/insight-button.js';

class RegisterReview extends InsightElement {
  static get properties() {
    return {
      registration: {type: Object},
      __mappedReg: {type: Object},
      __saving: {type: Boolean}
    };
  }

  enableAfterError() {
    this.__saving = false;
  }

  firstUpdated() {
    this._afterRender(() => {
      this._dispatchEvent('step-load', this);
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('registration')) {
      const isPrem = !!this.registration.plan.premium;
      this.__mappedReg = {
        'location:register.customer': {
          'common:name': this.registration.contact.fullName,
          'location:register.emailAddress': this.registration.contact.email,
          'common:phoneNumber': !!this.registration.contact.phone ? i18nPhone.format(this.registration.contact.phone.replace(/[^0-9]/g, '')) : ''
        },
        'common:location': {
          'common:locationName': this.registration.location.name,
          'location:register.businessPhone': this.registration.location.phone,
          'location:register.timezone': this.registration.location.timezone
        },
        'location:register.account': {
          'common:type':
            this._i18n(isPrem ? 'location:register.premPlan' : 'location:register.stdPlan') +
            (this.registration.payment === 'trial' ? ` (${this._i18n('location:register.trial')})` : ''),
          'common:price':
            '$' + this.registration.plan.price + '/mo' + (this.registration.payment === 'trial' ? ` (${this._i18n('location:register.afterTrial')})` : '')
        }
      };
      if (this.registration.owners && this.registration.owners.length) {
        this.__mappedReg['common:location']['location:register.owners'] = this.registration.owners.map(o => o.title).join(', ');
      }
      if (!this.registration.plan.fixed) {
        this.__mappedReg['location:register.account']['location:register.initialTier'] = this._i18n('location:register.initMemTier', {
          members: this.registration.plan.members
        });
        this.__mappedReg['location:register.account']['location:register.nextTier'] = this._i18n('location:register.nextMemTier', {
          members: this.registration.plan.members + 100
        });
      }
      const pymt = this.registration.payment.details;
      if (!!pymt) {
        if (this.registration.payment.methodName === 'insight-ach') {
          let acctType;
          switch (pymt.accountType) {
            case 'CHECKING':
              acctType = this._i18n('components:payment.checking');
              break;
            case 'SAVINGS':
              acctType = this._i18n('components:payment.savings');
              break;
            default:
              acctType = this._i18n('components:payment.bizChecking');
              break;
          }
          this.__mappedReg['common:paymentInfo'] = {
            'common:accountType': acctType,
            'common:bankName': pymt.bankName,
            'common:routingNum': pymt.routingNumber,
            'common:account': pymt.acctNumber
          };
        } else {
          this.__mappedReg['common:paymentInfo'] = {
            'common:type': pymt.cardType.charAt(0).toUpperCase() + pymt.cardType.slice(1),
            'common:cardNum': '•••••' + pymt.cardNumber.substr(-4),
            'common:nameOnCard': pymt.cardholderName,
            'common:expires': pymt.expiryMonth + '/' + pymt.expiryYear
          };
        }
      }
    }
  }

  __finish() {
    this.__saving = true;
    this._dispatchEvent('complete-reg');
  }

  _render() {
    if (!this.__mappedReg) return;
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <div>
        <div class="typo-title">${this._i18n('location:register.reviewAccount')}</div>
        <div class="flex-layout-horizontal flex-layout-wrap review-container">
          ${Object.keys(this.__mappedReg).map(s => {
            return html`
              <div class="review-section">
                <div class="typo-overline review-title">${this._i18n(s)}</div>
                ${Object.keys(this.__mappedReg[s]).map(f => {
                  return html`
                    <div class="flex-layout-horizontal flex-layout-end">
                      <div class="typo-subhead2 review-lbl">${this._i18n(f)}:</div>
                      <div class="typo-body2 review-val">${this.__mappedReg[s][f]}</div>
                    </div>
                  `;
                })}
              </div>
            `;
          })}
        </div>
        <div class="footer-btns">
          <insight-button @click=${() => this._dispatchEvent('start-over')} ?disabled=${this.__saving}>${this._i18n('common:back')}</insight-button>
          <insight-button contained @click=${this.__finish} ?disabled=${this.__saving}>
            <div id="btn-loader" ?hidden=${!this.__saving}>
              <div class="insight-loader" style="${window.Testophobia ? 'border-top-color:#ccc;' : ''}"></div>
            </div>
            ${this.__saving
              ? this._i18n('location:register.processing')
              : !!this.registration.payment.details
              ? `${this._i18n('common:pay')} $${this.registration.plan.price}`
              : this._i18n('location:register.createAccount')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host .review-container {
        max-width: 920px;
      }
      :host .review-section {
        width: 360px;
        margin: 10px 0;
      }
      :host .review-title {
        color: var(--mdc-theme-text-secondary);
        letter-spacing: 5px;
        line-height: 20px;
      }
      :host .review-lbl {
        max-width: 140px;
        min-width: 140px;
      }
      :host .review-val {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      :host .footer-btns {
        margin: 20px 0 10px;
      }
      :host .footer-btns insight-button:first-child {
        margin-right: 20px;
      }
      :host .footer-btns insight-button:last-child {
        --button-color: var(--mdc-theme-success);
      }
      :host #btn-loader {
        display: inline-block;
        transform: scale(.75);
        vertical-align: middle;
        margin: -2px 0 0 -4px;
      }
      :host #btn-loader[hidden] {
        display: none;
      }
      @media only screen and (max-width: 480px) {
        :host .review-section {
          width: 100%;
        }
      }
    </style>
  `;
  }
}
window.customElements.define('register-review', RegisterReview);
