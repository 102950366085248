import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/ui/insight-icon.js';
import scrollIntoView from 'scroll-into-view';

class InsightStepper extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      steps: {type: Array},
      __activeIndex: {type: Number}
    };
  }

  moveNext() {
    this.setActive(this.__activeIndex + 1);
  }

  movePrev() {
    this.setActive(this.__activeIndex - 1);
  }

  setActive(index) {
    this.__activeIndex = index;
    const scrollOpts = {align: {top: 0, left: 0}};
    if (window.Testophobia) scrollOpts.time = 300;
    scrollIntoView(this._getElement(`.step-row:nth-child(${index + 1})`), scrollOpts);
  }

  getActiveView() {
    return this.__activeView;
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.steps = [];
    this.__activeIndex = 0;
  }

  firstUpdated() {}

  __getView(step) {
    const el = document.createElement(step.view);
    this.__activeView = el;
    el.classList.add('stepper-view');
    return html`
      ${el}
    `;
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical step-container">
        ${this.steps.map((s, idx) => {
          const isComplete = idx < this.__activeIndex;
          const isActive = idx === this.__activeIndex;
          const isDone = this.__activeIndex === this.steps.length - 1;
          return html`
            <div class="step-row" ?data-active=${isActive} ?data-complete=${isComplete} ?data-done=${isDone}>
              <div class="flex-layout-horizontal">
                <div class="typo-caption step-num">
                  ${isComplete || isDone
                    ? html`
                        <insight-icon icon-name="check"></insight-icon>
                      `
                    : idx + 1}
                </div>
                <div class="${isActive ? 'typo-subhead2' : 'typo-body2'} step-lbl">${s.label}</div>
              </div>
              ${isActive ? this.__getView(s) : ''}
            </div>
          `;
        })}
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host .step-container {
        height: 100%;
      }
      :host .step-row {
        position: relative;
      }
      :host .step-row:before {
        content: '';
        border-left: 2px solid var(--mdc-theme-dark-bg-16, var(--mdc-theme-divider-color));
        height: calc(100% - 40px);
        width: 2px;
        position: absolute;
        left: 11px;
        top: 48px;
        z-index: 0;
      }
      :host .step-row:last-child:before {
        display: none;
      }
      :host .step-num {
        background-color: var(--mdc-theme-text-disabled);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: var(--mdc-theme-surface);
        margin: 16px 12px 16px 0;
        padding-left: 1px;
        z-index: 1;
        --icon-color: var(--mdc-theme-surface);
        --icon-width: 20px;
      }
      :host .step-row[data-active] .step-num,
      :host .step-row[data-complete] .step-num {
        background-color: var(--mdc-theme-primary);
      }
      :host .step-row[data-done] .step-num {
        background-color: var(--mdc-theme-success);
      }
      :host .step-lbl {
        color: var(--mdc-theme-text-disabled);
        line-height: 56px;
        height: 56px;
      }
      :host .step-row[data-active] .step-lbl,
      :host .step-row[data-complete] .step-lbl {
        color: var(--mdc-theme-text-primary);
      }
      :host .step-row[data-active] .stepper-view {
        width: calc(100% - 36px);
        display: block;
        margin-left: 36px;
        box-sizing: border-box;
      }
    </style>
  `;
  }
}
window.customElements.define('insight-stepper', InsightStepper);
