export default [
  {path: 'admin', view: 'admin-view', langKey: 'admin', importer: async () => await import('./views/admin/admin-view.js')},
  {path: 'admin/features', view: 'admin-features-view', langKey: 'admin', importer: async () => await import('./views/admin/features/admin-features-view.js')},
  {
    path: 'admin/client-pricing/:subView?',
    view: 'client-pricing-view',
    langKey: ['admin', 'location'],
    importer: async () => await import('./views/admin/client-pricing/client-pricing-view.js')
  },
  {path: 'admin/purchase-orders', view: 'admin-po-view', langKey: 'admin', importer: async () => await import('./views/admin/purchase-order/admin-po-view.js')},
  {
    path: 'admin/system-message',
    view: 'admin-sysmsg-view',
    langKey: 'admin',
    importer: async () => await import('./views/admin/system-messages/admin-sysmsg-view.js')
  },
  {path: 'events/:eventGuid/:subView?', view: 'event-view', langKey: 'calendar', importer: async () => await import('./views/events/event-view.js')},
  {path: 'calendar', view: 'calendar-view', langKey: 'calendar', importer: async () => await import('./views/calendar/calendar-view.js')},
  {path: 'checkin', view: 'account-view', langKey: 'account', importer: async () => await import('./views/account/account-view.js')},
  {path: 'dashboard', view: 'insight-dashboard-view', langKey: 'dashboard', importer: async () => await import('./views/dashboard/insight-dashboard-view.js')},
  {
    path: 'accounts/:accountGuid/view',
    view: 'account-view',
    langKey: ['account', 'member', 'training'],
    importer: async () => await import('./views/account/account-view.js')
  },
  {
    path: 'accounts/:accountGuid/move-member',
    view: 'move-member-view',
    langKey: 'account',
    importer: async () => await import('./views/account/move-member-view.js')
  },
  {path: 'members/:accountGuid/new', view: 'new-member-view', langKey: 'member', importer: async () => await import('./views/member/new-member-view.js')},
  {
    path: 'accounts/:accountGuid/addons/new',
    view: 'new-addon-view',
    langKey: 'member',
    importer: async () => await import('./views/member/new-addon-view.js')
  },
  {path: 'members/:memberGuid/edit', view: 'member-view', langKey: 'member', importer: async () => await import('./views/member/member-view.js')},
  {path: 'members/:memberGuid/merge', view: 'member-view', langKey: 'member', importer: async () => await import('./views/member/member-view.js')},
  {path: 'members/:memberGuid/set-picture', view: 'member-view', langKey: 'member', importer: async () => await import('./views/member/member-view.js')},
  {path: 'members/:memberGuid/view', view: 'member-view', langKey: ['member', 'training'], importer: async () => await import('./views/member/member-view.js')},
  {
    path: 'members/:memberGuid/addons/new',
    view: 'new-addon-view',
    langKey: 'member',
    importer: async () => await import('./views/member/new-addon-view.js')
  },
  {path: 'sales/guest/new', view: 'new-guest-view', langKey: 'sales', importer: async () => await import('./views/sales/new-guest-view.js')},
  {path: 'sales/:salesDate/new', view: 'new-sale-view', langKey: 'sales', importer: async () => await import('./views/sales/new-sale-view.js')},
  {path: 'training/home', view: 'training-home-view', langKey: 'training', importer: async () => await import('./views/training/training-home-view.js')},
  {
    path: 'training/migrate/:guid',
    view: 'migrate-client-view',
    langKey: 'training',
    importer: async () => await import('./views/training/migrate-client-view.js')
  },
  {
    path: 'settings/location/:subView?',
    view: 'location-settings-view',
    langKey: ['settings', 'location'],
    importer: async () => await import('./views/location/settings/location-settings-view.js')
  },
  {
    path: 'settings/applications/:subView?',
    view: 'location-applications-view',
    langKey: 'settings',
    importer: async () => await import('./views/location/applications/location-applications-view.js')
  },
  {
    path: 'location/new',
    view: 'new-location-view',
    langKey: 'location',
    importer: async () => await import('./views/admin/registration/new-location-view.js')
  },
  {
    path: 'location/users',
    view: 'location-users-view',
    langKey: 'location',
    importer: async () => await import('./views/location/users/location-users-view.js')
  },
  {
    path: 'settings/user/:userId/:subView?',
    view: 'user-settings-view',
    langKey: 'settings',
    importer: async () => await import('./views/settings/user-settings/user-settings-view.js')
  },
  {
    path: 'warnings/call-us',
    view: 'call-us-warning',
    langKey: 'warnings',
    importer: async () => await import('./views/warnings/call-us-warning.js')
  },
  {
    path: 'warnings/delinquent-warning',
    view: 'delinquent-warning',
    langKey: 'warnings',
    importer: async () => await import('./views/warnings/delinquent-warning.js')
  },
  {
    path: 'warnings/access-denied',
    view: 'access-denied-warning',
    langKey: 'warnings',
    importer: async () => await import('./views/warnings/access-denied-warning.js')
  },
  {path: 'playground/payments', view: 'pg-payment-view', langKey: 'admin', importer: async () => await import('./views/playground/pg-payment-view.js')},
  {
    path: 'playground/accounts/:accountGuid/sale',
    view: 'account-sale-view',
    langKey: 'account',
    importer: async () => await import('./views/account/account-sale-view.js')
  },
  {path: 'testophobia', view: 'testophobia-view', importer: async () => await import('./views/admin/testophobia-view.js')},
  {path: '*', view: 'legacy-frame-view', importer: async () => await import('./views/legacy-frame/legacy-frame-view.js')}
];
