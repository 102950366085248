import trackLastN from './last-n-tracking.js';

const postTelemetry = data => {
  const telem = {
    app: Insight.appName,
    session: Insight.auth && Insight.auth.sid ? Insight.auth.sid : null,
    subject: Insight.auth ? (Insight.auth.sub ? Insight.auth.sub : Insight.auth.clientId ? Insight.auth.clientId : null) : null,
    path: location.pathname + location.search,
    client: Insight.browser ? Insight.browser.toString() : null,
    addlData: data
  };
  trackLastN('nav-history', 5, telem);
  if (!window.location.hostname.includes('localhost') && Insight.configs && Insight.configs.telemetryUrl) {
    navigator &&
      navigator.serviceWorker &&
      navigator.serviceWorker.getRegistrations().then(regs => {
        if (!regs.length) return;
        telem.type = 'telemetry';
        telem.endpoint = Insight.configs.telemetryUrl;
        regs[0].active.postMessage(telem);
      });
  }
};

export default postTelemetry;
