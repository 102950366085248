import {InsightComponent, html} from '../../insight-component.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '../../button/insight-button.js';
import '../../button/insight-icon-button.js';
import '../../form/insight-dropdown-menu.js';
import '../../form/insight-form.js';
import '../../form/insight-input.js';
import '../../form/insight-state-dropdown.js';

class InsightPaymentAddAddress extends InsightComponent {
  static get properties() {
    return {
      addressDetails: {type: Object},
      options: {type: Object},
      __isCanada: {type: Boolean}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  getFocusEl() {
    return this._getElement('#ipt-address');
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.addressDetails = {};
  }

  firstUpdated() {
    this._afterRender(() => {
      this.__setupCountryDropdown();
      setTimeout(() => {
        this._getElement('#ipt-address').setFocus();
      }, 200);
    });
  }

  __setupCountryDropdown() {
    let cnty = this.addressDetails.country;
    if (!cnty)
      if (Intl.DateTimeFormat().resolvedOptions().locale.toLowerCase().endsWith('ca')) cntr = 'CA';
      else cnty = 'US';
    this.__isCanada = cnty && cnty === 'CA';
    this._getElement('#dd-country').data = [
      {label: 'Canada', value: 'CA', selected: this.__isCanada},
      {label: 'United States', value: 'US', selected: !this.__isCanada}
    ];
  }

  __updateAddress(e) {
    if (this.__formEl.validate()) {
      this.options.editItem.address = this.addressDetails.address;
      this.options.editItem.city = this.addressDetails.city;
      this.options.editItem.state = this.addressDetails.state;
      this.options.editItem.postalCode = this.addressDetails.postalCode;
      this.options.editItem.country = this.addressDetails.country || 'US';
      this.options.editItem.phone = this.addressDetails.phone;
      this._dispatchEvent('change-view', 4);
    }
  }

  get __formEl() {
    return this._getElement('insight-form');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical addaddy-container">
        <div class="flex-layout-horizontal flex-layout-center">
          <insight-icon-button
            id="btnBack"
            icon-name="arrow_back"
            class="addaddy-back"
            @click=${() => this._dispatchEvent('change-view', 4)}
          ></insight-icon-button>
          <div class="typo-subhead addaddy-title"><b>${this._i18n('payment', 'addAddress', 'Add Address')}</b></div>
        </div>
        <insight-form id="addaddy-form" class="flex-layout-flex">
          <insight-input
            autocomplete="billing street-address"
            dense
            class="flex-layout-flex"
            id="ipt-address"
            label=${this._i18n(null, 'address', 'Address')}
            required
            error-message=${this._i18n(null, 'error.addressReq', 'Address is Required')}
            initial-value=${ifDefined(this.addressDetails.address)}
            @change=${e => (this.addressDetails.address = e.detail)}
          ></insight-input>
          <insight-input
            autocomplete="billing locality"
            dense
            class="flex-layout-flex"
            id="ipt-city"
            label=${this._i18n('payment', 'city', 'City')}
            required
            error-message=${this._i18n(null, 'error.cityReq', 'City is Required')}
            initial-value=${ifDefined(this.addressDetails.city)}
            @change=${e => (this.addressDetails.city = e.detail)}
          ></insight-input>
          <div class="flex-layout-horizontal form-row">
            <insight-dropdown-menu
              dense
              autocomplete="billing country"
              id="dd-country"
              label=${this._i18n(null, 'country', 'Country')}
              required
              error-message=${this._i18n(null, 'requiredLbl', 'Required')}
              value-as-selected
              fixed-width
              @change=${e => {
                this.addressDetails.country = e.detail.value;
                delete this.addressDetails.postalCode;
                this._getElement('#ipt-zip').clear(true);
                this.__isCanada = e.detail.value !== 'US';
              }}
            ></insight-dropdown-menu>
            <insight-state-dropdown dense ?canada=${this.__isCanada} @change=${e => (this.addressDetails.state = e.detail.value)}></insight-state-dropdown>
            <insight-input
              autocomplete="billing postal-code"
              dense
              class="flex-layout-flex"
              id="ipt-zip"
              label=${this._i18n('payment', 'postalCode', 'Postal Code')}
              required
              type=${this.__isCanada ? 'postal' : 'zip'}
              error-message=${this._i18n('payment', 'error.postalCodeReq', 'Required')}
              initial-value=${ifDefined(this.addressDetails.postalCode)}
              @change=${e => (this.addressDetails.postalCode = e.detail)}
            ></insight-input>
          </div>
          <insight-input
            autocomplete="billing tel"
            dense
            class="flex-layout-flex"
            id="ipt-phone"
            type="tel"
            label=${this._i18n(null, 'phoneNumber', 'Phone Number')}
            required
            error-message=${this._i18n(null, 'error.phoneNumReq', 'Phone is Required')}
            initial-value=${ifDefined(this.addressDetails.phone)}
            @change=${e => (this.addressDetails.phone = e.detail)}
          ></insight-input>
        </insight-form>
        <div class="flex-layout-horizontal flex-layout-end-justified addaddy-footer">
          <insight-button id="btnUpdate" contained @click=${this.__updateAddress}>
            ${this._i18n('payment', 'done', 'Done')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .addaddy-container {
          height: 100%;
        }
        :host .addaddy-back {
          margin-left: -16px;
          margin-top: -12px;
        }
        :host .addaddy-title {
          margin-bottom: 12px;
        }
        :host #addaddy-form {
          margin-top: 8px;
        }
        :host .form-row > *:not(:last-child) {
          margin-right: 16px;
        }
        :host .addaddy-footer {
          padding: 8px 0;
        }
        @media only screen and (max-width: 620px) {
          :host .form-row {
            flex-wrap: wrap;
          }
          :host .form-row > * {
            min-width: 100% !important;
            max-width: 100% !important;
            margin: 0 !important;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-add-address', InsightPaymentAddAddress);
