import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/container/insight-dialog.js';

class InsightMessageDialog extends InsightElement {
  /****************************************************  Public Api  ****************************************************/
  static get properties() {
    return {
      modal: {attribute: 'modal', type: Boolean},
      fatal: {attribute: 'fatal', type: Boolean},
      __messageTitle: {type: String},
      __messageBody: {type: Object}
    };
  }

  show(title, body, isFatal) {
    this.__messageTitle = title;
    this.__messageBody = body;
    this.fatal = !!isFatal;
    this.__dialogEl.open(this.fatal ? this.__dialogEl : undefined);
  }

  close() {
    this.__dialogEl.close();
  }

  /**************************************************  Private Methods  *************************************************/

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog id="message-dialog" ?can-dismiss=${!this.fatal} ?modal=${this.modal}>
        <div slot="modalHeader" class="modal-header">${this.__messageTitle}</div>
        <div id="modal-body" slot="modalBody">
          ${typeof this.__messageBody === 'string' ? unsafeHTML(this.__messageBody) : this.__messageBody}
        </div>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host #modal-body b {
          color: var(--mdc-theme-primary)
        }
        :host #modal-body em {
          font-weight: bold;
          font-style: initial;
          color: var(--mdc-theme-error)
        }
      </style>
    `;
  }
}
window.customElements.define('insight-message-dialog', InsightMessageDialog);
