import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/container/insight-dropdown-container.js';
import '@insight/insight-common/components/form/insight-checkbox-list.js';
import '@insight/insight-common/components/form/insight-dropdown-menu.js';
import {UserPrefUtils} from '../../util/user-pref-utils.js';

class ActivityPanelHeader extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  get selectedDoor() {
    return this.__doorFilter;
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__doors = [];
    this.__muteOptions = [
      {label: this._i18n('appshell:activity.muteGreen'), value: 'green'},
      {label: this._i18n('appshell:activity.muteYellow'), value: 'yellow'},
      {label: this._i18n('appshell:activity.muteRed'), value: 'red'},
      {label: this._i18n('appshell:activity.muteDenied'), value: 'denied'},
      {label: this._i18n('appshell:activity.muteAlarm'), value: 'alarm'}
    ];
    Insight.state.fetchOneTime('doors', state => {
      this.__doors = state;
      this.__doorFilter = UserPrefUtils.get('access-activity-panel', 'doorFilter');
      this.__dataReady = true;
      this.__checkReady();
    });
  }

  firstUpdated() {
    this._afterRender(() => {
      this.__viewReady = true;
      this.__checkReady();
    });
  }

  __checkReady() {
    if (this.__viewReady && this.__dataReady) {
      this.__configureDoorDropdown();
      this.__muteChimesEl.selectedItems = (UserPrefUtils.get('access-activity-panel', 'mutedChimes') || []).map(m => ({value: m}));
    }
  }

  __configureDoorDropdown() {
    const data = [{label: this._i18n('appshell:activity.allDoors')}];
    if (this.__doors) this.__doors.forEach(d => data.push({label: d.name, id: d.id}));
    let filterIdx = this.__doorFilter ? data.findIndex(d => d.id === this.__doorFilter.id) : 0;
    if (filterIdx === -1) {
      filterIdx = 0;
      this.__doorFilter = null;
      UserPrefUtils.store('access-activity-panel', 'doorFilter', null);
    }
    data[filterIdx].selected = true;
    this.__doorSelectEl.data = data;
    this._dispatchEvent('door-change');
  }

  __togglePanel() {
    this._dispatchEvent('toggle-activity-panel');
  }

  __showMuteOptions() {
    this._getElement('#mute-list').open();
  }

  __toggleChime(e) {
    const sel = this.__muteChimesEl.selectedItems.map(i => i.value);
    UserPrefUtils.store('access-activity-panel', 'mutedChimes', sel);
  }

  __handleDoorSelect({detail}) {
    this.__doorFilter = detail.id ? detail : null;
    UserPrefUtils.store('access-activity-panel', 'doorFilter', this.__doorFilter);
    this._dispatchEvent('door-change');
  }

  get __doorSelectEl() {
    return this._getElement('#door-selector');
  }

  get __muteChimesEl() {
    return this._getElement('#mute-checkbox-list');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div id="activity-header" class="flex-layout-horizontal flex-layout-center">
        <div class="flex-layout-vertical">
          <div class="activity-title typo-caption">${this._i18n('appshell:activity.recentActivity')}</div>
          <insight-dropdown-menu
            id="door-selector"
            no-background
            no-bottom-margin
            no-underline
            invert-colors
            dense
            no-label
            @change=${this.__handleDoorSelect}
          ></insight-dropdown-menu>
        </div>
        <div class="header-buttons flex-layout-horizontal flex-layout-flex flex-layout-end-justified">
          <insight-icon-button icon-name="volume_up" title=${this._i18n('appshell:activity.mute')} @click=${this.__showMuteOptions}></insight-icon-button>
          <insight-dropdown-container id="mute-list" horizontal-align="right" vertical-offset="48" horizontal-offset="-24">
            <div>
              <insight-checkbox-list
                id="mute-checkbox-list"
                .options=${this.__muteOptions || []}
                no-select-all
                selected-icon-name="volume_off"
                deselected-icon-name="volume_up"
                @change=${this.__toggleChime}
              ></insight-checkbox-list>
            </div>
          </insight-dropdown-container>
          <insight-icon-button title="${this._i18n('appshell:activity.hide')}" icon-name="no_meeting_room" @click=${this.__togglePanel}></insight-icon-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host #activity-header {
          max-height: 48px;
          padding: 5px 0 4px;
          background: var(--mdc-theme-dark-bg-16, var(--mdc-theme-primary));
          border-bottom: 1px solid var(--mdc-theme-divider-color);
        }
        :host #activity-header insight-input {
          --input-color: white;
          --input-placeholder-color: rgba(255, 255, 255, 0.7);
          --input-active-color: white;
        }
        :host #activity-header insight-icon {
          --icon-color: white;
        }
        :host .activity-title {
          margin-left: 15px;
          color: white;
          opacity: 0.6;
          font-size: 11px;
        }
        :host #door-selector {
          margin-top: -10px;
          --input-active-color: white;
          --input-color: white;
          --icon-color: white;
        }
        :host #activity-header insight-icon-button {
          margin-left: -8px;
          --icon-color: var(--mdc-theme-on-primary);
        }
        :host #mute-list {
          --dropdown-padding: 8px;
        }
        :host #mute-checkbox-list {
          --checkbox-icon-unchecked-color: var(--mdc-theme-success);
          --checkbox-icon-checked-color: var(--mdc-theme-text-disabled);
        }
      </style>
    `;
  }
}
window.customElements.define('activity-panel-header', ActivityPanelHeader);
