import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/payment/insight-payment-request.js';

class RegisterPlan extends InsightElement {
  static get properties() {
    return {
      registration: {type: Object}
    };
  }

  firstUpdated() {
    this._afterRender(() => {
      this._dispatchEvent('step-load', this);
    });
  }

  __showPaymentCtrl(premium, price) {
    this.registration.plan.premium = premium;
    this.registration.plan.members = this.registration.plan.members || 100;
    this.registration.plan.price = !!price ? price : premium ? 99 : 49;
    const cost = this.registration.plan.price + '.00';
    let order = {
      total: {label: this._i18n('location:register.total'), amount: {currency: 'USD', value: cost}},
      displayItems: [
        {
          label: this._i18n(premium ? 'location:register.premPlan' : 'location:register.stdPlan'),
          amount: {currency: 'USD', value: cost}
        }
      ]
    };
    let options = {
      cancelButtonLabel: this.registration.plan.existing || this.registration.clientGuid ? 'common:cancel' : 'components:payment.skipPayment',
      acceptACH: true,
      submitButtonLabel: 'common:save',
      requestPayerName: true,
      requestPayerPhone: true,
      requestPayerEmail: true,
      networks: ['amex', 'mastercard', 'visa', 'discover'],
      cardsOnFile: [],
      contactsOnFile: [
        {
          fullName: this.registration.contact.fullName,
          email: this.registration.contact.email,
          phone: this.registration.contact.phone
        }
      ]
    };
    if (!!this.registration.payment.details) {
      options.contactsOnFile = [
        {
          fullName: this.registration.payment.payerName,
          email: this.registration.payment.payerEmail,
          phone: this.registration.payment.payerPhone
        }
      ];
      const dtl = this.registration.payment.details;
      if (this.registration.payment.methodName === 'insight-ach') {
        options.ach = {
          accountType: dtl.accountType,
          bankName: dtl.bankName,
          routingNumber: dtl.routingNumber,
          acctNumber: dtl.acctNumber
        };
      } else {
        options.cardsOnFile = [
          {
            type: dtl.cardType,
            last4: dtl.cardNumber.substr(-4),
            number: dtl.cardNumber,
            nameOnCard: dtl.cardholderName,
            address: dtl.billingAddress.addressLine[0],
            city: dtl.billingAddress.city,
            state: dtl.billingAddress.region,
            country: dtl.billingAddress.country,
            postalCode: dtl.billingAddress.postalCode,
            expireMonth: dtl.expiryMonth,
            expireYear: dtl.expiryYear,
            phone: dtl.billingAddress.phone,
            default: true
          }
        ];
      }
    }
    this.__paymentEl.show(order, options).then(response => {
      if (!this.registration.plan.existing) {
        if (response.cancelled !== true) {
          this.registration.payment = response;
          response.complete();
          this._dispatchEvent('move-next', 'Trial - Payment info entered');
        } else if (!this.registration.clientGuid) {
          this.registration.payment = 'trial';
          this._dispatchEvent('move-next', 'Trial - Payment info skipped');
        }
      } else if (!!response.details) {
        this.registration.payment = response;
        response.complete();
        this._dispatchEvent('move-next', 'Payment step completed');
      } else {
        response.complete();
      }
    });
  }

  get __paymentEl() {
    return this._getElement('insight-payment-request');
  }

  _render() {
    if (!this.registration) return;
    return html`
      ${unsafeHTML(this.__css)}
      <div>
        ${!!this.registration.plan.existing
          ? html`
              <div class="plan-section">
                <div class="typo-title">${this._i18n('location:register.yourPlan')}</div>
                <div class="flex-layout-horizontal flex-layout-end">
                  <div class="typo-subhead2 plan-lbl">${this._i18n('location:register.planType')}:</div>
                  <div class="typo-body2 plan-val">
                    ${this._i18n(this.registration.plan.premium ? 'location:register.premPlan' : 'location:register.stdPlan')}
                  </div>
                </div>
                <div class="flex-layout-horizontal flex-layout-end">
                  <div class="typo-subhead2 plan-lbl">${this._i18n('common:price')}:</div>
                  <div class="typo-body2 plan-val">$${this.registration.plan.price}.00/mo</div>
                </div>
                ${!this.registration.plan.fixed
                  ? html`
                      <div class="flex-layout-horizontal flex-layout-end">
                        <div class="typo-subhead2 plan-lbl">${this._i18n('location:register.initialTier')}:</div>
                        <div class="typo-body2 plan-val">${this._i18n('location:register.initMemTier', {members: this.registration.plan.members})}</div>
                      </div>
                      <div class="flex-layout-horizontal flex-layout-end">
                        <div class="typo-subhead2 plan-lbl">${this._i18n('location:register.nextTier')}:</div>
                        <div class="typo-body2 plan-val">${this._i18n('location:register.nextMemTier', {members: this.registration.plan.members + 100})}</div>
                      </div>
                    `
                  : ''}
              </div>
              <div class="footer-btns">
                <insight-button contained @click=${e => this.__showPaymentCtrl(this.registration.plan.premium, this.registration.plan.price)}>
                  ${this._i18n('location:register.addPaymentInfo')}
                </insight-button>
              </div>
            `
          : html`
              <div class="typo-title main-title">${this._i18n('location:register.choosePlan')}</div>
              ${!this.registration.clientGuid
                ? html`
                    <div class="typo-body1">
                      ${unsafeHTML(this._i18n('location:register.planDesc', {trialType: this._i18n('location:register.softwareEval')}))}
                    </div>
                  `
                : ''}
              <div class="flex-layout-horizontal flex-layout-justified flex-layout-wrap card-container">
                <div class="pricing-card">
                  <div class="ribbon"><span>${this._i18n('location:register.recommended')}</span></div>
                  <div class="pricing-card-heading">${this._i18n('location:register.premium')}</div>
                  <div class="sub-price-text">${this._i18n('location:register.startingat')}</div>
                  <div class="price-text">$99</div>
                  <div class="sub-price-text">${this._i18n('location:register.perMonth')}</div>
                  <insight-button contained @click=${e => this.__showPaymentCtrl(true)}>${this._i18n('location:register.selectPlan')}</insight-button>
                </div>
                <div class="pricing-card">
                  <div class="pricing-card-heading">${this._i18n('location:register.standard')}</div>
                  <div class="sub-price-text">${this._i18n('location:register.startingat')}</div>
                  <div class="price-text">$49</div>
                  <div class="sub-price-text">${this._i18n('location:register.perMonth')}</div>
                  <insight-button contained @click=${e => this.__showPaymentCtrl(false)}>${this._i18n('location:register.selectPlan')}</insight-button>
                </div>
              </div>
              <div class="lnk-compare">
                <a href="https://www.gyminsight.com/pricing.html" target="_blank">${this._i18n('location:register.comparePlans')}</a>
              </div>
            `}
      </div>
      <insight-payment-request ?align-top=${!this.registration.clientGuid}></insight-payment-request>
    `;
  }

  get __css() {
    return `
    <style>
      :host .card-container {
        max-width: 640px;
      }
      :host .main-title {
        margin-bottom: 8px;
      }
      :host .plan-section {
        width: 360px;
        margin-bottom: 10px;
      }
      :host .plan-section > * {
        margin-bottom: 10px;
      }
      :host .plan-title {
        color: var(--mdc-theme-text-secondary);
        letter-spacing: 5px;
        line-height: 20px;
      }
      :host .plan-lbl {
        width: 140px;
      }
      :host .footer-btns {
        margin: 20px 0 10px;
      }
      :host .pricing-card {
        margin-top: 20px;
        width: 300px;
        box-sizing: border-box;
        border-radius: 20px;
        z-index: 1;
        text-align: center;
        background-color: #3498db;
        position: relative;
        padding: 15px 30px 30px;
      }
      :host .ribbon {
        width: 100px;
        height: 100px;
        overflow: hidden;
        position: absolute;
        top: -10px;
        left: -10px;
      }
      :host .ribbon::before {
        top: 0;
        right: 0;
      }
      :host .ribbon::after {
        bottom: 0;
        left: 0;
      }
      :host .ribbon::after,
      :host .ribbon::before {
        border-top-color: transparent;
        border-left-color: transparent;
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
        border: 5px solid #0c2231;
      }
      :host .ribbon span {
        display: block;
        position: absolute;
        width: 140px;
        padding: 8px 0;
        background-color: #16405c;
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        color: #fff;
        font-weight: 700;
        font-size: 10px;
        line-height: 18px;
        text-shadow: 0 1px 1px rgba(0,0,0,.2);
        text-transform: uppercase;
        right: -8px;
        top: 22px;
        transform: rotate(-45deg);
      }
      :host .pricing-card-heading {
        line-height: 40px;
        font-weight: 700;
        text-transform: capitalize;
        color: #f7f7f7;
        font-size: 24px;
      }
      :host .sub-price-text {
        box-sizing: border-box;
        color: #fff;
        font-size: 10px;
        font-style: italic;
        font-weight: 300;
      }
      :host .price-text {
        color: #fff;
        font-size: 36px;
        line-height: 48px;
        font-weight: 700;
      }
      :host .pricing-card insight-button {
        display: block;
        margin-top: 20px;
        --button-color: #e96e40;
        --button-width: 200px;
        --button-height: 48px;
        --button-font-size: 16px;
        --mdc-typography-button-font-weight: 700;
      }
      :host .lnk-compare {
        text-align: center;
        max-width: 640px;
        line-height: 60px;
      }
      @media only screen and (max-width: 720px) {
        :host .lnk-compare {
          max-width: 300px;
        }
      }
      @media only screen and (max-width: 480px) {
        :host .plan-section {
          width: unset;
        }
      }
    </style>
  `;
  }
}
window.customElements.define('register-plan', RegisterPlan);
