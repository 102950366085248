const InsightMvcUrls = {
  accountBalance: 'accounts/{account}/balance',
  accountCancel: 'accounts/{account}/cancel',
  accountCreateAgreement: 'accounts/{account}/agreement',
  accountDelete: 'accounts/{account}/delete',
  accountDeletePayment: 'accounts/{account}/delete-payment',
  accountEdit: 'accounts/{account}/edit',
  accountFreeze: '{account}/freeze',
  accountMoveToCol: 'collections/{account}/enter',
  accountNew: 'accounts/new-account',
  accountNewPayment: 'accounts/{account}/new-payment',
  accountNewSchedPayment: 'scheduled-payments/{account}/new',
  accountSale: 'accounts/{account}/sale',
  accountSignAgreement: 'accounts/{account}/sign-agreement',
  accountSignDocument: 'accounts/{account}/sign-document',
  accountStatement: 'accounts/{account}/statement',
  accountStatementPrint: 'accounts/{account}/statement/print',
  accountUncancel: 'accounts/{account}/uncancel',
  accountUnfreeze: 'accounts/{account}/unfreeze',
  accountUpdateGreen: 'accounts/{account}/update-green',
  accountUpdatePayment: 'accounts/{account}/update-payment',
  attendanceReport: 'reports/class-attendance/show?start={start}&end={end}&context={event}',
  clientsView: 'admin/clients/{client}/view',
  credentialReport: 'reports/access/show',
  delinquentOwnerNotice: 'delinquent-account/owner-notice',
  delinquentUserNotice: 'delinquent-account/user-notice',
  delinquentOwnerUpdate: 'delinquent-account/owner-update-required',
  delinquentUserUpdate: 'delinquent-account/user-update-required',
  delinquentUpdate: 'delinquent-account/update',
  gatewaySetup: 'https://secure.gyminsight.com/category/3-payment-gateways',
  guruHelp: 'https://secure.gyminsight.com/category/5-sales-guru',
  knowledgeArticle: 'https://secure.gyminsight.com/article',
  knowledgeBase: 'https://secure.gyminsight.com/docs',
  locationGroups: 'groups/list',
  locationReports: 'reports',
  locationSms: 'sms',
  memberCalendar: 'https://members.gyminsight.com/classcalendar/{location}',
  memberEvent: 'https://members.gyminsight.com/events/{event}',
  memberPortal: 'https://members.gyminsight.com',
  reportClassReservations: 'reports/class-reservations/show?start={start}&end={end}',
  reportCollections: 'reports/collections-accounts/show?start={start}&end={end}',
  reportDailyDues: 'reports/daily-dues-change/show?start={start}&end={end}',
  reportDclCharges: 'reports/declined-charges/show?start={start}&end={end}',
  reportEndOfDay: 'reports/end-of-day/show?start={start}&end={end}',
  reportEventReservations: 'reports/event-reservations/show?start={start}&end={end}&paid=any&status={status}',
  reportEventReservationsByDate: 'reports/reservations-by-event-date/show?start={start}&end={end}&paid=any&status={status}',
  reportFrozenAccounts: 'reports/frozen-accounts/show?start={start}&end={end}',
  reportLocPerf: 'reports/location-performance/show',
  reportMemRev: 'reports/membership-revenue/show?start={start}&end={end}',
  reportNewAccounts: 'reports/new-accounts/show?start={start}&end={end}',
  reportNewGuests: 'reports/guest-creation/show?start={start}&end={end}',
  reportSucCharges: 'reports/successful-charges/show?start={start}&end={end}',
  reportUnfrozenAccts: 'reports/unfrozen-accounts/show?start={start}&end={end}',
  reportXcldAccounts: 'reports/cancelled-accounts/show?start={start}&end={end}',
  secureRoot: 'https://secure.gyminsight.com',
  smsPlans: 'sms-plans',
  smsSend: 'sms/{account}/send',
  taskNew: 'tasks/new',
  trialExpired: 'trials/location/expired',
  useradmin: 'admin',
  usercommissions: 'commissions/list',
  userEdit: 'users/{user}/edit',
  userNew: 'users/new',
  userpipeline: 'customers/pipeline'
};

export {InsightMvcUrls};
