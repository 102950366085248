import {InsightComponent, html} from '../../insight-component.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '../../button/insight-button.js';
import '../../button/insight-icon-button.js';
import '../../ui/insight-cc-icon.js';
import '../../ui/insight-icon.js';

class InsightPaymentList extends InsightComponent {
  static get properties() {
    return {
      orderDetails: {type: Object},
      options: {type: Object},
      listType: {type: String}
    };
  }

  static get ListType() {
    return {
      Contact: 0,
      Payment: 1
    };
  }

  /****************************************************  Public Api  ****************************************************/

  getFocusEl() {
    return this._getElement('#btnBack');
  }

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this._afterRender(() => {
      if (this.listType === InsightPaymentList.ListType.Contact) {
        if (!this.options.contactsOnFile.length) this._dispatchEvent('change-view', 5);
      } else {
        if (!this.options.cardsOnFile.length) this._dispatchEvent('change-view', 4);
      }
    });
  }

  __getCardLabel(card) {
    return card.type + ' •••••' + card.last4;
  }

  __addItem() {
    this.__editItem(null);
  }

  __editItem(item) {
    this.options.editItem = item;
    if (this.listType === InsightPaymentList.ListType.Contact) this._dispatchEvent('change-view', 5);
    else this._dispatchEvent('change-view', 4);
  }

  __itemSelected(e, idx, arr) {
    const path = this._getEventPath(e);
    if (path.some(el => el.classList && el.classList.contains('btn-item-edit'))) return;
    if (idx > 0) arr.unshift(arr.splice(idx, 1)[0]);
    this._dispatchEvent('change-view', 0);
  }

  _render() {
    const title =
      this.listType === InsightPaymentList.ListType.Contact ? this._i18n(null, 'contactInfo', 'Contact Info') : this._i18n('payment', 'payment', 'Payment');
    const btnLbl =
      this.listType === InsightPaymentList.ListType.Contact
        ? this._i18n('payment', 'addContactInfo', 'Add Contact Info')
        : this._i18n('payment', 'addCard', 'Add Card');
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical list-container">
        ${
          !this.orderDetails
            ? html`
                <div id="list-loader" class="flex-layout-horizontal flex-layout-center-center">
                  <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
                </div>
              `
            : html`
                <div class="flex-layout-horizontal flex-layout-center">
                  <insight-icon-button
                    id="btnBack"
                    icon-name="arrow_back"
                    class="list-back"
                    @click=${() => this._dispatchEvent('change-view', 0)}
                  ></insight-icon-button>
                  <div class="typo-subhead list-title"><b>${title}</b></div>
                </div>
                <div class="list-wrapper">
                  ${this.listType === InsightPaymentList.ListType.Contact
                    ? !this.options.contactsOnFile.length
                      ? html`
                          <div class="typo-body2 lbl-no-items">
                            Click
                            <b>Add Contact Info</b>
                            to add new contact information
                          </div>
                        `
                      : html`
                          ${this.options.contactsOnFile.map((c, idx) => {
                            return html`
                              <div class="flex-layout-horizontal list-row" @click=${e => this.__itemSelected(e, idx, this.options.contactsOnFile)}>
                                <div class="flex-layout-flex list-row-value">
                                  ${!!this.options.requestPayerName
                                    ? html`
                                        <div class="flex-layout-horizontal flex-layout-justified typo-body2 list-value-line">
                                          <div>${c.fullName}</div>
                                        </div>
                                      `
                                    : ''}
                                  ${!!this.options.requestPayerEmail
                                    ? html`
                                        <div class="flex-layout-horizontal flex-layout-justified typo-body2 list-value-line">
                                          <div>${c.email}</div>
                                        </div>
                                      `
                                    : ''}
                                  ${!!this.options.requestPayerPhone
                                    ? html`
                                        <div class="flex-layout-horizontal flex-layout-justified typo-body2 list-value-line">
                                          <div>${c.phone}</div>
                                        </div>
                                      `
                                    : ''}
                                </div>
                                <div class="flex-layout-vertical flex-layout-center-justified flex-layout-end">
                                  <insight-icon icon-name="check" ?hidden=${idx > 0}></insight-icon>
                                </div>
                                <div class="flex-layout-vertical flex-layout-center-justified flex-layout-end">
                                  <insight-icon-button icon-name="edit" class="btn-item-edit" @click=${() => this.__editItem(c)}></insight-icon-button>
                                </div>
                              </div>
                            `;
                          })}
                        `
                    : !this.options.cardsOnFile.length
                    ? html`
                        <div class="typo-body2 lbl-no-items">
                          Click
                          <b>Add Card</b>
                          to add a new payment method
                        </div>
                      `
                    : html`
                        ${this.options.cardsOnFile.map((c, idx) => {
                          return html`
                            <div class="flex-layout-horizontal list-row" @click=${e => this.__itemSelected(e, idx, this.options.cardsOnFile)}>
                              <div class="flex-layout-flex list-row-value">
                                <div class="flex-layout-horizontal flex-layout-justified typo-body2 list-value-line">
                                  <div class="card-icon">${this.__getCardLabel(c)}</div>
                                </div>
                                <div class="flex-layout-horizontal flex-layout-justified typo-body2 list-value-line">
                                  <div>${c.nameOnCard}</div>
                                </div>
                              </div>
                              <div class="flex-layout-vertical flex-layout-center-justified flex-layout-end">
                                <insight-icon icon-name="check" ?hidden=${idx > 0}></insight-icon>
                              </div>
                              <div class="flex-layout-horizontal flex-layout-center">
                                <insight-cc-icon cc-type=${c.type}></insight-cc-icon>
                              </div>
                              <div class="flex-layout-vertical flex-layout-center-justified flex-layout-end">
                                <insight-icon-button icon-name="edit" class="btn-item-edit" @click=${() => this.__editItem(c)}></insight-icon-button>
                              </div>
                            </div>
                          `;
                        })}
                      `}
                </div>
              `
        }
          </div>
        <div class="flex-layout-flex"></div>
        <div class="flex-layout-horizontal flex-layout-end-justified list-footer">
          ${
            !!this.options && this.options.acceptACH && this.listType === InsightPaymentList.ListType.Payment
              ? html`
                  <insight-button outlined @click=${() => this._dispatchEvent('change-view', 7)}>
                    ${this._i18n('payment', 'useAch', 'Use ACH')}
                  </insight-button>
                `
              : ''
          }
          <insight-button id="btnAdd" contained ?disabled=${!this.orderDetails} @click=${this.__addItem}>
            ${btnLbl}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .list-container {
          height: calc(100% - 52px);
        }
        :host .list-back {
          margin-left: -16px;
          margin-top: -12px;
        }
        :host .list-title {
          margin-bottom: 12px;
        }
        :host .list-wrapper {
          overflow-y: auto;
          margin-right: -20px;
          padding-right: 20px;
        }
        :host .list-row {
          padding: 8px 0;
          border-bottom: 1px solid var(--mdc-theme-divider-color);
          cursor: pointer;
          position: relative;
        }
        :host .list-row:hover:before {
          content: '';
          background: var(--mdc-theme-surface);
          opacity: 0.1;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        :host .list-value-line {
          line-height: 24px;
        }
        :host .list-value-line > div {
          max-width: 440px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        :host .card-icon {
          text-transform: capitalize;
        }
        :host insight-icon[icon-name="check"] {
          margin: 12px;
          --icon-color: var(--mdc-theme-success);
        }
        :host #list-loader {
          height: 100%;
        }
        :host .list-footer {
          padding: 8px 0;
        }
        :host .list-footer insight-button {
          margin-left: 16px;
          --button-min-width: 112px;
        }
        :host .lbl-no-items {
          margin-top: 16px;
          color: var(--mdc-theme-text-secondary);
        }
        @media only screen and (max-width: 620px) {
          :host .list-value-line > div {
            max-width: calc(95vw - 136px);
          }
          :host .list-wrapper {
            overflow-y: auto;
            margin-right: -8px;
            padding-right: 8px;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-list', InsightPaymentList);
