import {InsightElement, html} from '../insight-element.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import InfiniteList from 'infinite-list/src/InfiniteList.js';

class InsightList extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      rowHeight: {attribute: 'row-height', type: Number},
      cardView: {attribute: 'card-view', type: Boolean, reflect: true},
      selectionEnabled: {attribute: 'selection-enabled', type: Boolean},
      multiselect: {attribute: 'multiselect', type: Boolean},
      noDeselect: {attribute: 'no-deselect', type: Boolean},
      scrollTarget: {attribute: 'scroll-target', type: String},
      loadMoreTpl: {type: String},
      itemHeightCalc: {type: Function}
    };
  }

  get items() {
    return this.__items;
  }

  set scrollTarget(el) {
    this.__scrollTarget = el;
  }

  get selectedItems() {
    if (this.multiselect) return this.__items.filter((s, i) => this.__selectedIndexes.indexOf(i) >= 0);
    else if (this.__items && this.__selectedIndexes.length) return [this.__items[this.__selectedIndexes[0]]];
    else return [];
  }

  findElements(selector) {
    return this._getElement(selector, true);
  }

  setItems(items, hasMore) {
    this.__hasMore = hasMore;
    this.__selectedIndexes = [];
    this.__items = items;
    this.__itemsReady = true;
    if (items === null) {
      if (this.__list) {
        this.__list.detach(this.__listEl);
        this.__list = null;
      }
    } else {
      if (this.__compReady) this.__renderListTemplate();
    }
  }

  appendItems(newItems) {
    this.__items = this.__items.concat(newItems);
  }

  clearSelection() {
    this.__selectedIndexes = [];
    this.__applySelections();
  }

  selectItem(item) {
    if (!item) return;
    this.selectIndex(this.__items.indexOf(item));
  }

  selectIndex(itemIndex) {
    this.__selectIndex(itemIndex);
  }

  selectItemById(itemId) {
    if (itemId) {
      const idx = this.__items.findIndex(i => i.id === itemId);
      if (idx >= 0) this.selectIndex(idx);
      else this.clearSelection();
    } else {
      this.clearSelection();
    }
  }

  deselectItem(item) {
    if (!item || !this.__selectedIndexes.length) return;
    const itemIdx = this.__items.indexOf(item);
    if (itemIdx === -1 || this.__selectedIndexes.indexOf(itemIdx) === -1) return;
    this.__selectedIndexes.splice(this.__selectedIndexes.indexOf(itemIdx), 1);
    this.__applySelections();
  }

  itemUpdated(index) {
    if (this.__list) this.__list.refresh();
  }

  setItemEventHandlers(handlers) {
    this.__itemEventHandlers = handlers;
    if (this.__itemsReady && this.__compReady) this.__renderListTemplate();
  }

  forceRefresh() {
    this.__renderListTemplate();
  }

  updateItems() {
    if (this.__list) this.__list.refresh();
  }

  ensureSized() {
    if (this.__noInitialRender) {
      this.__noInitialRender = false;
      this.__renderListTemplate();
    }
  }

  getScrollPosition() {
    return this.__scrollTarget ? this.__scrollTarget.scrollTop : this.__listEl.querySelector('div').scrollTop;
  }

  setScrollPosition(pos) {
    this.__scrollTarget ? (this.__scrollTarget.scrollTop = pos) : (this.__listEl.querySelector('div').scrollTop = pos);
  }

  get hasScrollbar() {
    const st = this.__scrollTarget ? this.__scrollTarget : this.__listEl.querySelector('div');
    return st.scrollHeight > st.clientHeight;
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__items = [];
    this.__selectedIndexes = [];
    this.__debouncedUpdate = this._debounce(50, this.__itemsUpdated);
  }

  firstUpdated() {
    this.__compReady = true;
    if (this.__itemsReady) this._renderListTemplate();
  }

  __applySelections() {
    if (this.__list) {
      const renderedItems = this.shadowRoot.querySelectorAll('#list-container > div > div > div > *');
      for (let i = 0; i < renderedItems.length; i++) {
        if (renderedItems[i].hasAttribute('data-selected')) {
          if (this.__selectedIndexes.indexOf(Number(renderedItems[i].getAttribute('data-index'))) === -1) renderedItems[i].removeAttribute('data-selected');
        } else {
          if (this.__selectedIndexes.indexOf(Number(renderedItems[i].getAttribute('data-index'))) >= 0) renderedItems[i].setAttribute('data-selected', '');
        }
      }
    }
  }

  __selectIndex(itemIndex, userInvoked) {
    if (this.multiselect) {
      this.__selectedIndexes.push(itemIndex);
    } else {
      this.__selectedIndexes = [itemIndex];
    }
    if (this.__list) {
      this.__applySelections();
      if (!userInvoked) this.__scrollTheScroller(this.__selectedIndexes[0]);
    }
  }

  __handleSelection(row) {
    const isSelected = this.__selectedIndexes.length && this.__selectedIndexes.indexOf(row) >= 0;
    if (this.multiselect) {
      if (isSelected) {
        if (this.noDeselect && this.__selectedIndexes.length === 1) return;
        this.__selectedIndexes.splice(this.__selectedIndexes.indexOf(row), 1);
        this.__applySelections();
      } else {
        this.__selectIndex(row, true);
      }
    } else if (isSelected) {
      if (this.noDeselect) return;
      this.clearSelection();
    } else {
      this.__selectIndex(row, true);
    }
    this._dispatchEvent('selection-change', this.multiselect ? this.selectedItems : this.selectedItems[0]);
  }

  __renderListTemplate() {
    const container = this.__listEl;
    if (!container.clientHeight) {
      this.__noInitialRender = true;
      return;
    }
    if (this.__list) {
      this.__list.detach(container);
      this.__list = null;
    }
    if (this.__items === undefined || this.__items === null || !this.__items.length) return;
    if (!this.__tpl) this.__tpl = this.querySelector('template');
    if (this.__tpl) {
      const _this = this;
      while (this.__tpl.firstChild) this.__tpl.removeChild(this.__tpl.firstChild);
      const styles = this.__tpl.content.querySelectorAll('style');
      for (let i = 0; i < styles.length; i++) {
        this.__tpl.content.removeChild(styles[i]);
        this.shadowRoot.appendChild(styles[i]);
      }
      const links = this.__tpl.content.querySelectorAll('link');
      if (!this.__linksHtml) {
        this.__linksHtml = '';
        for (let i = 0; i < links.length; i++) {
          this.__tpl.content.removeChild(links[i]);
          this.__linksHtml += links[i].outerHTML + '\n';
        }
      }
      this.shadowRoot.appendChild(this.__tpl);
      this.requestUpdate();
      this.__dispatchNextRenderEvent = true;
      this.__list = new InfiniteList({
        scroller: this.__scrollTarget || null,
        initialPage: {
          itemsCount: this.__items.length,
          hasMore: this.__hasMore
        },
        itemRenderer: (row, el) => {
          if (!_this.__items || !_this.__items[row]) return;
          const clone = _this.__tpl.content.cloneNode(true);
          const replaceExpressions = str => {
            return str.replace(/\[\[[^\]]+\]\]/g, match => {
              switch (match) {
                case '[[index]]':
                  return row;
                case '[[tabIndex]]':
                  return -1;
              }
              if (match.startsWith('[[item.') || match.startsWith('[[!item.') || match.startsWith('[[?item.') || match.startsWith('[[*item.')) {
                const isNested = match.startsWith('[[*item.');
                const isNegated = match.startsWith('[[!item.');
                const isBoolean = match.startsWith('[[?item.');
                const cleanExpression = exp =>
                  exp
                    .substring(isNested || isNegated || isBoolean ? 8 : 7)
                    .replace(']]', '')
                    .split('.');
                const props = cleanExpression(match);
                let matchVal = props.reduce((t, v) => (isNegated ? !t[v] : _this.__cleanValue(t[v], isBoolean)), _this.__items[row]);
                if (isNested) matchVal = replaceExpressions(matchVal);
                return matchVal;
              }
              return match;
            });
          };
          let outer = clone.firstElementChild.outerHTML;
          outer = replaceExpressions(outer);
          const outerDoc = new DOMParser().parseFromString(outer, 'text/html').body.firstElementChild;
          for (let i = outerDoc.attributes.length - 1; i >= 0; i--) {
            let node = outerDoc.attributes[i];
            if (node.name.indexOf('?') === 0) {
              outerDoc.removeAttribute(node.name);
              if (['false', 'undefined', 'null'].indexOf(node.value) === -1)
                outerDoc.setAttribute(node.name.substr(1), node.value === 'true' ? '' : node.value);
            }
          }
          el.innerHTML = outerDoc.outerHTML;
          el.firstElementChild.setAttribute('data-index', row);
          if (_this.__items[row].cssClass) el.firstElementChild.classList.add(_this.__items[row].cssClass);
          if (_this.__itemEventHandlers) {
            _this.__itemEventHandlers.forEach(h => {
              const target = el.querySelector(h.selector);
              if (target) {
                target.addEventListener(h.event, e => {
                  e.eventTarget = target;
                  h.handler(e);
                });
              }
            });
          }
          if (!_this.__items[row].disabled && _this.selectionEnabled) {
            el.firstElementChild.addEventListener('click', e => _this.__handleSelection(row));
            el.firstElementChild.addEventListener('keydown', e => {
              const isEnter = e.key === 'Enter' || e.keyCode === 13;
              const isSpace = e.key === 'Space' || e.keyCode === 32;
              if (isEnter || isSpace) _this.__handleSelection(row);
            });
          }
          if (_this.__selectedIndexes.indexOf(row) >= 0) el.firstElementChild.setAttribute('data-selected', '');
          this._dispatchEvent('item-update', {row, el});
        },
        itemHeightGetter: this.itemHeightCalc ? this.itemHeightCalc : index => Number(this.rowHeight) || 64,
        loadMoreRenderer: (index, el) => {
          el.innerHTML = this.loadMoreTpl
            ? this.loadMoreTpl
            : `<div id="list-loader" class="flex-layout-horizontal flex-layout-center-center"><div class="insight-loader" style="${
                window.Testophobia ? 'border-top-color:#ccc;' : ''
              }"></div></div>`;
        },
        pageFetcher: (fromIndex, callback) => {
          this._dispatchEvent('more-items', {fromIndex, callback});
        },
        renderComplete: () => {
          if (this.__dispatchNextRenderEvent) {
            this._dispatchEvent('list-rendered');
            this.__dispatchNextRenderEvent = false;
          }
        }
      }).attach(container);
    }
  }

  __cleanValue(v, isBoolean) {
    if (isBoolean) return v === true || v === 'true' ? 'true' : 'false';
    else return v === null || v === undefined ? '' : v;
  }

  __itemsUpdated(index, el) {
    this._dispatchEvent('item-update', {index, el});
  }

  __scrollTheScroller(index) {
    if (this.__list) this.__list.scrollToItem(index, false, false);
  }

  get __listEl() {
    return this._getElement('#list-container');
  }

  _render() {
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)} ${this.__linksHtml ? unsafeHTML(this.__linksHtml) : ''}
      <div id="list-container"></div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          display: block;
          position: relative;
        }
        :host .vrow {
          width: 100%;
        }
        :host #list-container {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
        :host #list-container > div {
          overflow-y: auto !important;
        }
        :host([always-scroll]) #list-container > div {
          overflow-y: scroll !important;
        }
        :host([allow-horizontal-scroll]) #list-container > div > div > div {
          overflow: unset !important;
        }
        :host([card-view]) #list-container > div > div {
          background: var(--mdc-theme-background);
          margin: 0 0 32px;
        }
        @media only screen and (min-width: 768px) {
          :host([card-view]) #list-container > div > div {
            width: calc(100% - 48px) !important;
            margin: 0 24px 32px;
            box-shadow: var(--mdc-theme-shadow-1);
          }
        }
        :host #list-loader {
          background: var(--mdc-theme-background);
          height: 100%;
        }
        :host #list-loader[hidden] {
          display: none;
        }
        :host .insight-loader {
          position: absolute;
          bottom: 15px;
        }
        :host([disabled]) {
          opacity: 0.5 !important;
          pointer-events: none;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-list', InsightList);
