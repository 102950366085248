import {InsightElement, html} from './insight-element.js';
export {html};

export class InsightComponent extends InsightElement {
  _i18n(componentModule, key, defValue, obj) {
    if (!defValue) defValue = '?????';
    const keys = componentModule ? [`components:${componentModule}.${key}`, defValue] : [`common:${key}`, `components:${key}`, defValue];
    return super._i18n(keys, obj);
  }

  _i18nDirect(key, obj) {
    return super._i18n(key, obj);
  }
}
