import {InsightElement, html} from './insight-element.js';
export {html};
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import getBrowserInfo from '../util/browser-detection.js';
import postTelemetry from './infrastructure/insight-telemetry.js';
import './infrastructure/insight-logging.js';
import {InsightRouter} from './util/insight-router.js';
import {loadTranslationFiles, loadCommonTranslationFile} from './util/insight-localization.js';

export class InsightAppBase extends InsightElement {
  static get AuthType() {
    return {
      None: 0,
      OAuth: 1,
      Client: 2
    };
  }

  constructor(appName, defaultXlateModules = ['common']) {
    super();
    Insight.appName = appName;
    this.__defaultXlateModules = defaultXlateModules;
    this.__authType = InsightAppBase.AuthType.OAuth;
    this.__modulesLoaded = [];
    if (window.location.pathname === '/reset') this.__resetApplication();
    this.__router = new InsightRouter();
    Insight.browser = getBrowserInfo();
    this.__checkWidgetId();
  }

  async _configure() {
    if (!!this.__startupCache && !!this.__startupCache.appConfigs) Insight.configs = this.__startupCache.appConfigs;
    else await this.__loadConfiguration();
    await this.__checkClock();
  }

  async _init(state) {
    Insight.state = state;
    if (this.__authType === InsightAppBase.AuthType.OAuth) await import('./auth/insight-auth.js');
    else if (this.__authType === InsightAppBase.AuthType.Client) await import('./auth/insight-auth-client.js');
    this.requestUpdate();
    await new Promise((resolve, reject) => {
      this._afterRender(async () => {
        try {
          if (!this._noAuthReq) await this._authEl.init(Insight.configs);
        } catch (e) {
          console.warn('Error occurred initializing auth', e);
          let message = e.message;
          if (message.includes('in the past') || message.includes('in the future')) {
            message = 'please check your system date and time';
            this._applicationLoadFailure('Authentication error: ' + message);
            reject();
            return;
          }
        }
        this._checkMobileRes();
        this._addEventListeners();
        resolve();
      });
    });
    if (!this._noAuthReq) this.__initialRoute = await this._authEl.getRedirectUrl();
  }

  _setAuthType(type) {
    this.__authType = type;
  }

  get _noAuthReq() {
    return this.__authType === InsightAppBase.AuthType.None;
  }

  async _setupRouter(routerModel) {
    if (routerModel && routerModel.length) this.__router.init(routerModel, this._handleNavigation.bind(this));
    localStorage.removeItem('redirectUrl');
    this.__router.navigate(this.__initialRoute || '/', true);
  }

  _addEventListeners() {
    this.addEventListener('click', () => {}); // https://github.com/material-components/material-components-web/issues/4441
    this.addEventListener('navigate-app', this._performNavigation.bind(this));
    this.addEventListener('navigate-external', this._navigateExternal.bind(this));
    this._listenForWindowResize(() => this._checkMobileRes());
  }

  _parseAPIError(detail) {
    let msg;
    if (typeof detail === 'string') {
      msg = detail;
    } else {
      msg = Object.keys(detail)
        .reduce((arr, i) => arr.push(this._i18n(`common:error.${detail[i]}`, {field: i})), [])
        .join('\n');
    }
    return msg;
  }

  _performNavigation({detail: route}) {
    if (route === undefined || route === null) return;
    if (typeof route === 'string') {
      this.__router.navigate(`/${route}`);
    } else {
      if (route.event && route.event instanceof MouseEvent && (route.event.type === 'click' || route.event.type === 'auxclick')) {
        if (route.event.ctrlKey || route.event.metaKey || route.event.shiftKey || route.event.which === 2) {
          this._dispatchEvent('reload-app', {newTab: true, url: window.location.origin + '/' + route.path});
          return;
        }
      }
      this.__router.navigate(`/${route.path}`, route.replaceHistory);
    }
  }

  _navigateExternal({detail: url}) {
    if (!this._isMock()) window.location.href = url;
    else this._dispatchEvent('show-snackbar', 'Navigate: ' + url);
  }

  _handleNavigation(route, context) {
    if (!route) return;
    this._afterRender(() => postTelemetry(route.view));
    const sameRoute = this._activeRoute === route;
    this._activeRoute = route;
    const finishNav = () => {
      if (!sameRoute) {
        const el = document.createElement(route.view);
        el.classList.add('active-view');
        this._view = html`
          ${el}
        `;
        this.requestUpdate();
        this._afterRender(() => this._checkMobileRes());
      }
      if (context) this.__proxyRouteParams(context);
    };
    if (!!route.importer && !this.__modulesLoaded.includes(route.view)) {
      this.__modulesLoaded.push(route.view);
      route.importer().then(() => finishNav());
    } else {
      finishNav();
    }
  }

  _applicationReady() {
    this._removeSplashScreen();
    const vp = this._viewportEl;
    if (vp) vp.style.opacity = 1;
    this._appReady = true;
  }

  _removeSplashScreen() {
    const splash = document.getElementById('splash-screen');
    if (splash) document.body.removeChild(splash);
  }

  _updateSplashStatus(message) {
    return new Promise(resolve => {
      const splash = document.querySelector('.splash-status');
      if (splash) splash.innerHTML = message;
      this._afterRender(resolve);
    });
  }

  _applicationLoadFailure(message) {
    if (this.__loadFailed) return;
    this.__loadFailed = true;
    const splash = document.querySelector('.splash-status');
    if (splash) {
      splash.innerHTML = message;
      splash.style.display = 'block';
      document.querySelector('.splash-loader').style.display = 'none';
    }
  }

  _checkMobileRes() {
    const bc = this.parentElement.getBoundingClientRect();
    document.documentElement.style.setProperty('--vh', `${bc.height * 0.01}px`);
    window.Insight.resolution.width = bc.width;
    window.Insight.resolution.height = bc.height;
    window.Insight.resolution.mobile = this._mobileRes = bc.width < 768;
    window.Insight.resolution.tablet = this._tabletRes = bc.width <= 1024;
  }

  async performUpdate() {
    if (!this.__i18nReady) {
      await loadCommonTranslationFile();
      await loadTranslationFiles(this.__defaultXlateModules);
      this.__i18nReady = true;
    }
    super.performUpdate();
  }

  async __loadConfiguration() {
    await this._updateSplashStatus('Configuring Application...');
    const configfile = new URLSearchParams(location.search).get('demo-mode') ? 'demo' : 'config';
    await fetch(`${configfile}.json?d=${new Date().getTime()}`)
      .then(r => r.json())
      .then(c => (Insight.configs = Object.assign({}, Insight.configs, c)));
  }

  async __checkClock() {
    if (this._isMock()) {
      let FakeTimers = await import('@sinonjs/fake-timers');
      FakeTimers.install({now: 2069000375000, shouldAdvanceTime: true});
    }
  }

  __proxyRouteParams(context) {
    this._afterRender(() => {
      if (context && this._viewportEl.lastElementChild._handleRouteParams) this._viewportEl.lastElementChild._handleRouteParams(context);
    });
  }

  __resetApplication() {
    localStorage.clear();
    window.location.href = '/';
  }

  __serverLogout() {
    if (!this._noAuthReq) this._authEl.logout();
  }

  __checkWidgetId() {
    const widget = new URLSearchParams(location.search).get('widgetId');
    if (!!widget) Insight.widgetId = widget;
  }

  get _authEl() {
    if (this._noAuthReq) return null;
    return this._getElement(this.__authType === InsightAppBase.AuthType.Client ? 'insight-auth-client' : 'insight-auth');
  }

  get _routerEl() {
    return this.__router;
  }

  /*abstract*/ get _viewportEl() {
    return null;
  }

  /*abstract*/ get _viewEl() {
    return null;
  }

  /*abstract*/ _getSubclassTemplate() {
    return '';
  }

  _render() {
    return html`
      ${unsafeHTML(this.___css)} ${this._getSubclassTemplate()}
      ${this.__authType === InsightAppBase.AuthType.Client
        ? html`
            <insight-auth-client></insight-auth-client>
          `
        : this.__authType === InsightAppBase.AuthType.OAuth
        ? html`
            <insight-auth @server-logout=${this.__serverLogout}></insight-auth>
          `
        : ''}
    `;
  }

  get ___css() {
    return `
      <style>
        :host .viewport {
          opacity: 0;
          transition: opacity 0.135s linear;
          position: relative;
          background-color: var(--mdc-theme-surface);
          box-shadow: inset 1px 1px 1px 0 rgba(0,0,0,.08), inset 1px 1px 2px 1px rgba(0,0,0,.1), inset -1px 0 1px 0 rgba(0,0,0,.08), inset -1px 0 2px 1px rgba(0,0,0,.1);
        }
        :host .viewport > *:last-child {
          opacity: 0;
          transition: opacity 0.135s linear;
          padding: 16px 32px 32px;
          display: block;
          height: 100%;
          box-sizing: border-box;
        }
        @media only screen and (max-width: 680px) {
          :host .viewport > *:last-child {
            padding: 4px;
          }
        }
      </style>
    `;
  }
}
