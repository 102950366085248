import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';

class InsightBadge extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      for: {attribute: 'for', type: String},
      label: {attribute: 'label', type: String},
      icon: {attribute: 'icon', type: String}
    };
  }

  updatePosition() {
    const target = this.__getBadgeTarget();
    if (!target || !this.offsetParent) return;
    const parentRect = this.offsetParent.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();
    const thisRect = this.getBoundingClientRect();
    this.style.left = targetRect.left - parentRect.left + (targetRect.width - thisRect.width / 2) + 'px';
    this.style.top = targetRect.top - parentRect.top - thisRect.height / 2 + 'px';
  }

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this.updatePosition();
  }

  __cleanLabel() {
    if (isNaN(this.label)) return this.label;
    return Number(this.label) > 99 ? '+99' : this.label;
  }

  __getOwnerRoot() {
    return this.getRootNode();
  }

  __getBadgeTarget() {
    const parentNode = this.parentNode;
    const ownerRoot = this.__getOwnerRoot();
    if (this.for) {
      return ownerRoot.querySelector('#' + this.for);
    } else {
      return parentNode.nodeType == Node.DOCUMENT_FRAGMENT_NODE ? ownerRoot.host : parentNode;
    }
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="badge flex-layout-center-center flex-layout-horizontal">
        <insight-icon icon-name=${this.icon} ?hidden=${!this.icon}></insight-icon>
        <span id="badge-text">${this.__cleanLabel()}</span>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          display: block;
          position: absolute;
          outline: none;
          z-index: 1;
          pointer-events: none;
        }
        :host([hidden]) {
          display: none !important;
        }
        :host insight-icon {
          --icon-width: var(--badge-icon-width, 12px);
        }
        :host .badge {
          font-weight: normal;
          font-size: 11px;
          line-height: 11px;
          border-radius: 50%;
          margin: var(--badge-margin, 6px 0 0 -16px);
          width: var(--badge-width, 20px);
          height: var(--badge-height, 20px);
          background-color: var(--badge-bg-color, var(--mdc-theme-secondary));
          color: var(--badge-fg-color, white);
          --icon-color: var(--badge-fg-color, white);
        }
        :host #badge-text {
          letter-spacing: var(--badge-spacing, 0px);
        }
      </style>
    `;
  }
}
window.customElements.define('insight-badge', InsightBadge);
