const UserPrefUtils = {
  get: (category, key) => {
    return !!Insight.user.preferences[category] ? Insight.user.preferences[category][key] : null;
  },
  store: (category, key, value) => {
    if (!window.Insight.user.preferences[category]) window.Insight.user.preferences[category] = {};
    window.Insight.user.preferences[category][key] = value;
    const newPref = {};
    newPref[category] = window.Insight.user.preferences[category];
    if (!Insight.auth.giAdminSub) {
      Insight.state.updateOneTime('userPreference', state => (!state ? console.error('An error occurred saving the user preference!') : null), {
        preferences: newPref
      });
    }
  }
};

export {UserPrefUtils};
