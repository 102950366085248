import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-dropdown-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import {InsightMvcUrls} from '../../util/insight-mvc-urls.js';

class HelpMenu extends InsightElement {
  static get properties() {
    return {
      modalsEl: {type: HTMLElement}
    };
  }
  /****************************************************  Public Api  ****************************************************/

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this._afterRender(() => {
      this.__configureHelpMenu();
    });
  }

  __configureHelpMenu() {
    const dd = this._getElement('#help-menu');
    dd.data = [
      {key: 'help', label: this._i18n('appshell:helpmenu.helpLbl')},
      {key: 'gateway', label: this._i18n('appshell:helpmenu.gatewayLbl')},
      {key: 'features', label: this._i18n('appshell:helpmenu.featuresLbl')},
      {key: 'askquestion', label: this._i18n('appshell:helpmenu.askquestionLbl')},
      {key: 'support', label: this._i18n('appshell:helpmenu.supportLbl') + ': 1-855-367-4967', divider: 'before'}
    ];
  }

  __handleHelpAction({detail}) {
    switch (detail.key) {
      case 'help':
        window.open(InsightMvcUrls.knowledgeBase, '_blank');
        break;
      case 'gateway':
        window.open(InsightMvcUrls.gatewaySetup, '_blank');
        break;
      case 'features':
        this._dispatchEvent('toggle-drawer');
        setTimeout(() => this.modalsEl.showSybgHistory(), 300);
        break;
      case 'askquestion':
        this._dispatchEvent('toggle-drawer');
        setTimeout(() => this.modalsEl.showAskAQuestion(), 300);
        break;
      case 'support':
        window.location.href = 'tel:1-855-367-4967';
        break;
    }
  }

  get __helpEl() {
    return this._getElement('insight-help-article');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dropdown-button
        id="help-menu"
        @change=${this.__handleHelpAction}
        horizontal-offset=${this._mobileRes ? '-10' : '40'}
        horizontal-align=${this._mobileRes ? 'right' : 'left'}
      >
        <insight-icon-button icon-name="help" slot="dropdown-button"></insight-icon-button>
      </insight-dropdown-button>
      <insight-help-article></insight-help-article>
    `;
  }

  get __css() {
    return `
      <style>
        :host #help-menu {
          position: absolute;
          bottom: 48px;
          padding: 4px;
          outline: none;
          cursor: pointer;
          --icon-color: #fff;
        }
        :host([mobile-res]) #help-menu {
          bottom: unset;
          top: 8px;
          right: 30px;
        }
/*        :host #help-menu {
          display: block;
        }
        @media only screen and (max-width: 767px) {
          :host #help-menu {
            margin-right: -12px;
          }
        }*/
      </style>
    `;
  }
}
window.customElements.define('help-menu', HelpMenu);
