import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';

class InsightIcon extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      iconName: {type: String, attribute: 'icon-name'}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <i role="presentation" class="material-icons">${this.iconName}</i>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          user-select: none;
        }
        :host .material-icons {
          opacity: var(--icon-opacity, unset);
          margin: var(--icon-margin, unset);
          max-width: var(--icon-width, 24px);
          font-size: var(--icon-width, 24px);
          vertical-align: var(--icon-align, middle);
          color: var(--icon-color, var(--mdc-theme-text-icon));
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'liga';
        }
      </style>
    `;
  }
}
window.customElements.define('insight-icon', InsightIcon);
