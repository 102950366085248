import {InsightComponent, html} from '../insight-component.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import './insight-dropdown-menu.js';

class InsightStateDropdown extends InsightComponent {
  static get statesData() {
    return [{label:'Alabama', value:'AL'},{label:'Alaska', value:'AK'},{label:'American Samoa', value:'AS'},{label:'Arizona', value:'AZ'},{label:'Arkansas', value:'AR'},{label:'Armed Forces Americas', value:'AA'},{label:'Armed Forces Others', value:'AE'},{label:'Armed Forces Pacific', value:'AP'},{label:'California', value:'CA'},{label:'Colorado', value:'CO'},{label:'Connecticut', value:'CT'},{label:'Delaware', value:'DE'},{label:'District Of Columbia', value:'DC'},{label:'Florida', value:'FL'},{label:'Georgia', value:'GA'},{label:'Guam', value:'GU'},{label:'Hawaii', value:'HI'},{label:'Idaho', value:'ID'},{label:'Illinois', value:'IL'},{label:'Indiana', value:'IN'},{label:'Iowa', value:'IA'},{label:'Kansas', value:'KS'},{label:'Kentucky', value:'KY'},{label:'Louisiana', value:'LA'},{label:'Maine', value:'ME'},{label:'Maryland', value:'MD'},{label:'Massachusetts', value:'MA'},{label:'Michigan', value:'MI'},{label:'Minnesota', value:'MN'},{label:'Mississippi', value:'MS'},{label:'Missouri', value:'MO'},{label:'Montana', value:'MT'},{label:'Nebraska', value:'NE'},{label:'Nevada', value:'NV'},{label:'New Hampshire', value:'NH'},{label:'New Jersey', value:'NJ'},{label:'New Mexico', value:'NM'},{label:'New York', value:'NY'},{label:'North Carolina', value:'NC'},{label:'North Dakota', value:'ND'},{label:'Northern Mariana Islands', value:'MP'},{label:'Ohio', value:'OH'},{label:'Oklahoma', value:'OK'},{label:'Oregon', value:'OR'},{label:'Pennsylvania', value:'PA'},{label:'Puerto Rico', value:'PR'},{label:'Rhode Island', value:'RI'},{label:'South Carolina', value:'SC'},{label:'South Dakota', value:'SD'},{label:'Tennessee', value:'TN'},{label:'Texas', value:'TX'},{label:'US Minor Islands', value:'UM'},{label:'Utah', value:'UT'},{label:'Vermont', value:'VT'},{label:'Virgin Islands', value:'VI'},{label:'Virginia', value:'VA'},{label:'Washington', value:'WA'},{label:'West Virginia', value:'WV'},{label:'Wisconsin', value:'WI'},{label:'Wyoming', value:'WY'}]; // prettier-ignore
  }

  static get provincesData() {
    return [{label: 'Alberta', value: 'AB'},{label: 'British Columbia', value: 'BC'},{label: 'Manitoba', value: 'MB'},{label: 'New Brunswick', value: 'NB'},{label: 'Newfoundland', value: 'NL'},{label: 'Northwest Territories', value: 'NT'},{label: 'Nova Scotia', value: 'NS'},{label: 'Nunavut', value: 'NU'},{label: 'Ontario', value: 'ON'},{label: 'Prince Edward Island', value: 'PE'},{label: 'Quebec', value: 'QC'},{label: 'Saskatchewan', value: 'SK'},{label: 'Yukon', value: 'YT'}]; // prettier-ignore
  }

  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      disabled: {attribute: 'disabled', type: Boolean},
      notRequired: {attribute: 'not-required', type: Boolean},
      dense: {attribute: 'dense', type: Boolean},
      initialValue: {attribute: 'initial-value', type: String},
      isCanada: {attribute: 'canada', type: Boolean}
    };
  }

  get selected() {
    return this.__dropdownEl.selected;
  }
  set selected(value) {
    if (!value) return;
    const selIndex = this.isCanada
      ? InsightStateDropdown.provincesData.findIndex(d => d.value === value)
      : InsightStateDropdown.statesData.findIndex(d => d.value === value);
    if (this.__initialized) {
      this.__dropdownEl.selectedIndex = selIndex;
    } else {
      this.__initialIndex = selIndex;
    }
  }

  get selectedIndex() {
    return this.__dropdownEl.selectedIndex;
  }
  set selectedIndex(value) {
    this.__dropdownEl.selectedIndex = value;
  }

  validate() {
    return this.__dropdownEl.validate();
  }

  clear() {
    this.__dropdownEl.clear();
  }

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this._afterRender(() => {
      this.__initData();
      this.__initialized = true;
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('isCanada')) {
      if (this.__initialized) {
        this.__initialIndex = -1;
        this.clear();
        this.__initData();
      } else {
        this.selected = this.initialValue;
      }
    }
    if (changedProperties.has('initialValue')) {
      this.selected = this.initialValue;
    }
  }

  __initData() {
    const dd = this.__dropdownEl;
    const data = JSON.parse(JSON.stringify(this.isCanada ? InsightStateDropdown.provincesData : InsightStateDropdown.statesData));
    if (this.__initialIndex >= 0) data[this.__initialIndex].selected = true;
    dd.data = data;
  }

  get __dropdownEl() {
    return this._getElement('insight-dropdown-menu');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dropdown-menu
        ?disabled=${this.disabled}
        ?dense=${this.dense}
        autocomplete="billing region"
        id="dd-state"
        label=${this._i18n(null, this.isCanada ? 'province' : 'state', this.isCanada ? 'Province' : 'State')}
        ?required=${!this.notRequired}
        error-message=${this._i18n(null, 'requiredLbl', 'Field is required!')}
        value-as-selected
        fixed-width
      ></insight-dropdown-menu>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          width: 120px;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-state-dropdown', InsightStateDropdown);
