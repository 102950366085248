import {InsightComponent, html} from '../insight-component.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '../button/insight-button.js';
import '../button/insight-fab.js';
import '../container/insight-dialog.js';
import '../ui/insight-icon.js';

class InsightHelpArticle extends InsightComponent {
  static get properties() {
    return {
      __article: {type: Object}
    };
  }
  /****************************************************  Public Api  ****************************************************/

  show(article) {
    this.__article = article;
    this.__dialogEl.open();
  }

  /**************************************************  Private Methods  *************************************************/

  __articleClicked(e) {
    e.preventDefault();
    const path = this._getEventPath(e);
    const anchor = path.find(el => el.getAttribute && !!el.getAttribute('href'));
    if (anchor) window.open('https://secure.gyminsight.com' + anchor.getAttribute('href'), '_blank');
  }

  __openInNewTab() {
    window.open(`https://secure.gyminsight.com/article/${this.__article.articleId}-${this.__article.slug}`, '_blank');
    this.__dialogEl.close();
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog modal can-dismiss no-footer @closed=${() => (this.__article = null)}>
        <div slot="modalHeader">
          <div class="flex-layout-horizontal flex-layout-center">
            <insight-icon icon-name="menu_book"></insight-icon>
            <div>${this.__article ? this.__article.title : ''}</div>
          </div>
        </div>
        <div slot="modalBody">
          <div class="article-wrapper" @click=${this.__articleClicked}>
            ${this.__article ? unsafeHTML(this.__article.body) : ''}
          </div>
          <insight-fab icon="launch" @click=${this.__openInNewTab} title=${this._i18n(null, 'openNewTab', 'Open in a new browser tab')}></insight-fab>
        </div>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-dialog {
          --dialog-padding-body: 10px 24px 0;
          --dialog-bg: white;
          --dialog-min-width: 95vw;
          --dialog-min-height: calc(calc(var(--vh, 1vh) * 100) - 64px);
        }
        :host insight-icon {
          margin: -4px 12px 0 0;
        }
        :host .article-wrapper {
          line-height: 1.6;
          color: #555;
          -moz-font-feature-settings: "liga","kern";
          text-rendering: optimizelegibility;
          background-color: #fff;
          font-size: 14px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-weight: 400;
          margin: 0;
        }
        :host([mobile-res]) .article-wrapper {
          font-size: 12px;
        }
        :host .article-wrapper img {
          border-style: none;
          vertical-align: middle;
          max-width: 100%;
          height: auto;
        }
        :host .article-wrapper dd,
        :host .article-wrapper h1,
        :host .article-wrapper h2,
        :host .article-wrapper h3,
        :host .article-wrapper h4,
        :host .article-wrapper h5,
        :host .article-wrapper h6,
        :host .article-wrapper label {
          margin-bottom: .5em;
        }
        :host .article-wrapper h1,
        :host .article-wrapper h2,
        :host .article-wrapper h3,
        :host .article-wrapper h4,
        :host .article-wrapper h5,
        :host .article-wrapper h6 {
          line-height: 1.4;
          margin-top: 0;
          margin-bottom: .5em;
          font-weight: 500;
          color: inherit;
        }
        :host .article-wrapper h1 {
          font-size: 2.5em;
        }
        :host .article-wrapper h2 {
          font-size: 2em;
        }
        :host .article-wrapper h3 {
          font-size: 1.75em;
        }
        :host .article-wrapper h4 {
          font-size:1.5em;
        }
        :host .article-wrapper h5 {
          font-size: 1.25em;
        }
        :host .article-wrapper h6 {
          font-size: 1em;
        }
        :host .article-wrapper b,
        :host .article-wrapper strong {
          font-weight: bolder;
        }
        :host .article-wrapper hr {
          margin-top: 2em;
          margin-bottom: 2em;
          border: 0;
          border-top: 1px solid rgba(0,0,0,.1);
          -webkit-box-sizing: content-box;
          box-sizing: content-box;
          height: 0;
          overflow: visible;
        }
        :host .article-wrapper a {
          color: #3498db;
          outline: 0;
          text-decoration: none;
          background-color: transparent;
          -webkit-text-decoration-skip: objects;
        }
        :host .article-wrapper dl,
        :host .article-wrapper ol,
        :host .article-wrapper p,
        :host .article-wrapper ul {
          margin-top: 0;
          margin-bottom: 1em;
        }
        :host .article-wrapper iframe {
          width: calc(95vw - 48px);
          height: calc(100vh - 133px);
        }
        :host insight-fab {
          position: absolute;
          bottom: 8px;
          right: 8px;
          --button-color: #F44336;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-help-article', InsightHelpArticle);
