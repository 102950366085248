import {InsightElement, html} from '../insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import './insight-icon.js';

class InsightAvatar extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      photoUrl: {type: String, attribute: 'photo-url'},
      userName: {type: String, attribute: 'user-name'},
      iconName: {type: String, attribute: 'icon-name'},
      isInsightLogo: {type: Boolean, attribute: 'insight-logo'}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  __getInitials(name) {
    if (!name) name = '?';
    if (name.indexOf(' ') > 0) {
      const parts = name.split(' ');
      return parts[0].substr(0, 1).toUpperCase() + parts.pop().substr(0, 1).toUpperCase();
    } else {
      return name.substr(0, 1).toUpperCase();
    }
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      ${!!this.photoUrl || this.isInsightLogo
        ? html`
            <img src=${this.isInsightLogo ? '/images/insight-logo.png' : this.photoUrl} class="photo" />
          `
        : this.iconName
        ? html`
            <insight-icon icon-name=${this.iconName} class="icon"></insight-icon>
          `
        : html`
            <div class="initials">
              <div class="typo-subhead"><b>${this.__getInitials(this.userName)}</b></div>
            </div>
          `}
    `;
  }

  get __css() {
    return `
      <style>
        :host .photo,
        :host .icon,
        :host .initials {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          object-fit: cover; /*safari causing weird artifacts with fill/default */
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          max-width: 40px;
          max-height: 40px;
          margin-right: 16px;
          border-radius: 4px;
          background: var(--mdc-theme-surface);
          color: var(--mdc-theme-text-icon);
        }
        :host([insight-logo]) .photo {
          border: 2px solid #81fa59;
          box-sizing: border-box;
          background: var(--mdc-theme-primary-dark);
          border-style: ridge;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-avatar', InsightAvatar);
