import {InsightElement, html} from '../insight-element.js';

class InsightForm extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      disabled: {attribute: 'disabled', type: Boolean},
      formClass: {attribute: 'form-class', type: String}
    };
  }

  validate() {
    let valid = true;
    const elements = this.__getValidatableElements();
    for (let el, i = 0; (el = elements[i]), i < elements.length; i++) {
      if (el.validate) valid = !!el.validate() && valid;
    }
    return valid;
  }

  getFirstInvalidElement() {
    const elements = this.__getValidatableElements();
    for (let el, i = 0; (el = elements[i]), i < elements.length; i++) if (el.validate && !el.validate()) return el;
    return null;
  }

  clear() {
    const elements = this.__getValidatableElements();
    for (let el, i = 0; (el = elements[i]), i < elements.length; i++) el.clear();
  }

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this.__formEl.addEventListener('submit', e => {
      e.preventDefault();
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('disabled')) this._afterRender(() => this.__checkDisabled());
  }

  __getValidatableElements() {
    const frm = this.__formEl;
    return frm ? this.__findElements(frm, true, false) : [];
  }

  __findElements(parent, ignoreName, skipSlots, skipDisabled, submittable) {
    submittable = submittable || [];
    const nodes = parent.querySelectorAll('*');
    for (let i = 0; i < nodes.length; i++) {
      if (!skipSlots && (nodes[i].localName === 'slot' || nodes[i].localName === 'content')) {
        this.__searchSubmittableInSlot(submittable, nodes[i], ignoreName, skipDisabled);
      } else {
        this.__searchSubmittable(submittable, nodes[i], ignoreName, skipDisabled);
      }
    }
    return submittable;
  }

  __searchSubmittableInSlot(submittable, node, ignoreName, skipDisabled) {
    const assignedNodes = node.assignedNodes({flatten: true});
    for (let i = 0; i < assignedNodes.length; i++) {
      if (assignedNodes[i].nodeType === Node.TEXT_NODE) {
        continue;
      }
      this.__searchSubmittable(submittable, assignedNodes[i], ignoreName, skipDisabled);
      const nestedAssignedNodes = assignedNodes[i].querySelectorAll('*');
      for (let j = 0; j < nestedAssignedNodes.length; j++) {
        this.__searchSubmittable(submittable, nestedAssignedNodes[j], ignoreName, skipDisabled);
      }
    }
  }

  __searchSubmittable(submittable, node, ignoreName, skipDisabled) {
    if (this.__isSubmittable(node, ignoreName, skipDisabled)) {
      submittable.push(node);
    } else if (node.root) {
      this.__findElements(node.root, ignoreName, true, skipDisabled, submittable);
    }
  }

  __isSubmittable(node, ignoreName, skipDisabled) {
    return (skipDisabled || !node.disabled) && (ignoreName ? node.name || typeof node.validate === 'function' : node.name);
  }

  __checkDisabled() {
    const frm = this.__formEl;
    if (frm) {
      const els = this.__findElements(frm, true, false, true);
      for (let i = 0; i < els.length; i++) {
        if (this.disabled) els[i].setAttribute('disabled', '');
        else els[i].removeAttribute('disabled');
      }
    }
  }

  get __formEl() {
    return this.shadowRoot.querySelector('form');
  }

  _render() {
    return html`
      <form style="height:var(--form-height, 100%)" class=${this.formClass}>
        <slot></slot>
      </form>
    `;
  }
}
window.customElements.define('insight-form', InsightForm);
