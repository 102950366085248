import {Mutex} from 'async-mutex';

class InsightAPI {
  constructor() {
    this.__api = {};
    this.__mockEdits = {
      editedItems: {member: {}, activity: {}},
      newItems: {activity: []},
      removedItems: {}
    };
  }
  async invoke(domain, fn, ...args) {
    let impl = this.__api[domain];
    if (impl instanceof Mutex) {
      (await this.__api[domain].acquire())();
      impl = this.__api[domain];
    } else if (!impl) {
      this.__api[domain] = new Mutex();
      const release = await this.__api[domain].acquire();
      let api = await import(`./${!Insight.configs.mock ? 'server' : 'mock'}/${domain}-api.js`);
      impl = this.__api[domain] = new api.default(this.__mockEdits);
      release();
    }
    if (impl[fn]) {
      if (window.Testophobia && args && args.length > 1) {
        const valid = await impl.__validateTestData(fn, args[1]);
        if (!valid) return args[0]({});
      }
      return await impl[fn](...args);
    }
  }
}

export default InsightAPI;
