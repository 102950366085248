import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nDate} from '@insight/insight-common/i18n/i18n-date.js';
import '@insight/insight-common/components/container/insight-dialog.js';
import '@insight/insight-common/components/container/insight-list.js';

class SybgHistoryDialog extends InsightElement {
  static get properties() {
    return {
      __loading: {type: Boolean},
      __sybgs: {type: Array}
    };
  }
  /****************************************************  Public Api  ****************************************************/

  show() {
    this.__dialogEl.open();
    this.__fetchSYBG();
  }

  close() {
    this.__dialogEl.close();
  }

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this._afterRender(() => this.__checkReady());
  }

  __fetchSYBG() {
    this.__loading = true;
    Insight.state.fetchOneTime(
      'sybgs',
      state => {
        this.__sybgs = state.items;
        this.__dataReady = true;
        this.__loading = false;
        this.__checkReady();
      },
      false,
      {future: false}
    );
  }

  __checkReady() {
    if (this.__listEl && this.__dataReady === true) {
      this.__sybgs = this.__sybgs.map(u => {
        u.shownOnLbl = i18nDate.formatDateInLocale(u.showOnDateUtc, Insight.location.ianaTimezone);
        return u;
      });
      this.__listEl.setItems(this.__sybgs);
    }
  }

  __handleListClick(e) {
    const hasClass = (el, clazz) => el.classList && el.classList.contains(clazz);
    const path = this._getEventPath(e);
    if (path.some(el => hasClass(el, 'sybg-item'))) {
      const id = path.find(el => el.classList && el.classList.contains('sybg-item')).getAttribute('data-id');
      this.close();
      setTimeout(() => this._dispatchEvent('show-sybg', id), 200);
    }
  }

  get __listEl() {
    return this._getElement('insight-list');
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <insight-dialog id="sybg-dialog" can-dismiss>
        <div slot="modalHeader">
          <div>${this._i18n('components:sybgHistory.title')}</div>
        </div>
        <div slot="modalBody">
          <div class="sybg-list-main">
            <insight-list id="sybg-list" class="flex-layout-flex" @click=${this.__handleListClick}>
              <template>
                <style>
                  .sybg-item {
                    height: 48px;
                    padding: 8px 8px 7px;
                    background-color: var(--mdc-theme-background);
                    border-bottom: solid 1px var(--mdc-theme-divider-color);
                    cursor: pointer;
                  }
                  .sybg-item:hover {
                    background-color: var(--mdc-theme-dark-bg-3, rgba(0, 0, 0, 0.04));
                  }
                  .sybg-item:active {
                    background-color: var(--mdc-theme-selected-item);
                  }
                  .sybg-item .sybg-title {
                    font-weight: 500;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                  .sybg-item .sybg-shownOn {
                    width: 100px;
                    margin-left: 16px;
                  }
                  @media only screen and (max-width: 600px) {
                    .sybg-item .sybg-shownOn,
                    .sybg-item .sybg-beta {
                      display: none;
                    }
                    .sybg-item .sybg-title {
                      margin-left: 16px;
                      font-size: 12px;
                      line-height: 14px;
                      white-space: initial;
                      display: -webkit-box;
                      -webkit-line-clamp: 3;
                      -webkit-box-orient: vertical;
                    }
                  }
                </style>
                <div class="sybg-item flex-layout-horizontal flex-layout-center" data-id="[[item.id]]">
                  <div class="typo-body1 sybg-shownOn">[[item.shownOnLbl]]</div>
                  <div class="flex-layout-flex typo-body1 sybg-title" title="[[item.title]]">[[item.title]]</div>
                </div>
              </template>
            </insight-list>
          </div>
          <div id="list-loader" class="flex-layout-horizontal flex-layout-center-center" ?hidden=${!this.__loading}>
            <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
          </div>
        </div>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host #sybg-dialog {
          --dialog-max-width: 80vw;
          --dialog-width: 800px;
          --dialog-min-width: 340px;
          --dialog-min-height: 340px;
          --dialog-padding-body: 0;
        }
        :host div[slot="modalBody"] {
          border-top: 1px solid var(--mdc-theme-divider-color);
        }
        :host .sybg-list-main {
          height: 400px;
          max-height: calc(100vh - 96px);
        }
        :host insight-list {
          height: 100%;
        }
        :host #list-loader {
          position: absolute;
          top: 41px;
          bottom: 0;
          width: 100%;
          background: var(--mdc-theme-background);
        }
        :host #list-loader[hidden] {
          display: none;
        }
      </style>
    `;
  }
}
window.customElements.define('sybg-history-dialog', SybgHistoryDialog);
