import i18next from 'i18next';
import Fetch from 'i18next-fetch-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Backend from 'i18next-chained-backend';
import Pseudo from 'i18next-pseudo';

export function loadTranslationFiles(namespaces) {
  return new Promise(resolve => {
    i18next.loadNamespaces(namespaces).then(() => resolve());
  });
}

export function loadCommonTranslationFile() {
  if (i18next.store && i18next.store.data[i18next.language]['common']) return;
  const lang = navigator.language.substring(0, 2);
  if (lang !== 'en') moment.locale(lang);
  return new Promise(resolve => {
    const version = Insight.version === 'DEV' ? new Date().getTime() : Insight.version;
    //prettier-ignore
    i18next
      .use(Backend)
      // .use(new Pseudo({enabled: true, repeatedLetters: '', uglifedLetterObject: {a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮', f: '𝓯', g: '𝓰', h: '𝓱', i: '𝓲', j: '𝓳', k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷', o: '𝓸', p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽', u: '𝓾', v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂', z: '𝔃', A: '𝓐', B: '𝓑', C: '𝓒', D: '𝓓', E: '𝓔', F: '𝓕', G: '𝓖', H: '𝓗', I: '𝓘', J: '𝓙', K: '𝓚', L: '𝓛', M: '𝓜', N: '𝓝', O: '𝓞', P: '𝓟', Q: '𝓠', R: '𝓡', S: '𝓢', T: '𝓣', U: '𝓤', V: '𝓥', W: '𝓦', X: '𝓧', Y: '𝓨', Z: '𝓩'}}))
      .init(
        {
          fallbackLng: 'en',
          initImmediate: false,
          postProcess: ['pseudo'],
          lng: lang,
          ns: ['common'],
          defaultNS: 'common',
          fallbackNS: 'common',
          backend: {
            backends: [LocalStorageBackend, Fetch],
            backendOptions: [
              {
                prefix: Insight.appName + '_',
                expirationTime: 604800000,
                versions: {
                  en: version
                }
              },
              {
                loadPath: function(lng, ns) {
                  if (ns.indexOf('/') >= 0) {
                    const parts = ns.split('/');
                    return `./locales/${lng}/${parts[0]}/${parts[1]}.json?v=${version}`;
                  } else {
                    return `./locales/${lng}/${ns}.json?v=${version}`;
                  }
                }
              }
            ]
          }
        },
        () => {
          resolve();
        }
      );
  });
}
