import {InsightComponent, html} from '../insight-component.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '../button/insight-button.js';
import '../container/insight-dialog.js';
import '../form/insight-textarea.js';
import '../ui/insight-icon.js';

class InsightAskQuestion extends InsightComponent {
  /****************************************************  Public Api  ****************************************************/

  show() {
    this.__textareaEl.clear(true);
    this.__dialogEl.open();
  }

  /**************************************************  Private Methods  *************************************************/

  __dialogOpened() {
    this._afterRender(() => this.__textareaEl.setFocus(true));
  }

  __sendQuestion() {
    if (this.__textareaEl.validate()) {
      this._dispatchEvent('submit', this.__textareaEl.value);
      this.__dialogEl.close();
    }
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  get __textareaEl() {
    return this._getElement('insight-textarea');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog id="question-dialog" @opened=${this.__dialogOpened}>
        <div slot="modalHeader">
          <div>${this._i18n('askAQuestion', 'title', 'Ask a Question')}</div>
          <div class="typo-caption">
            <small>
              We
              <insight-icon icon-name="favorite"></insight-icon>
              questions!
            </small>
          </div>
        </div>
        <div slot="modalBody">
          <insight-textarea
            id="question-input"
            required
            outlined
            placeholder=${this._i18n('askAQuestion', 'placeholder', 'Type your question here...')}
          ></insight-textarea>
        </div>
        <span slot="modalFooter" class="buttons modal-footer">
          <insight-button id="btn-cancel" dialog-dismiss tabindex="0">${this._i18n(null, 'cancel', 'Cancel')}</insight-button>
          <insight-button id="btn-save" contained tabindex="0" @click=${this.__sendQuestion}>
            <span>${this._i18n(null, 'send', 'Send')}</span>
          </insight-button>
        </span>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host #question-input {
          display: block;
          max-width: 100%;
          width: 480px;
          height: 240px;
        }
        :host insight-icon {
          --icon-color: red;
          --icon-width: 12px;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-ask-question', InsightAskQuestion);
