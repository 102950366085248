import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';

class AppBanner extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      __bannerShown: {type: Boolean}
    };
  }

  showBanner() {
    this.__bannerShown = true;
  }

  hideBanner() {
    this.__bannerShown = false;
  }

  get bannerShown() {
    return this.__bannerShown;
  }

  updateSize(shown) {
    const el = this.__bannerEl;
    if (!el) return 0;
    if (shown) {
      el.style.maxHeight = 'unset';
      return el.offsetHeight;
    } else {
      el.style.maxHeight = '58px';
      return 0;
    }
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__bannerShown = false;
  }

  get __bannerEl() {
    return this._getElement('#app-banner');
  }

  get __bannerMessageEl() {
    return this._getElement('slot[name="bannerMessage"]');
  }

  get __bannerIconTpl() {
    const msgSlot = this.__bannerMessageEl;
    if (msgSlot && msgSlot.assignedNodes().length) {
      const iconSlot = msgSlot.assignedNodes()[0];
      const iconClr = iconSlot.hasAttribute('banner-icon-color') ? `--icon-color:${iconSlot.getAttribute('banner-icon-color')}` : '';
      if (iconSlot.hasAttribute('banner-icon'))
        return html`
          <insight-icon id="app-banner-icon" icon-name=${iconSlot.getAttribute('banner-icon')} style=${iconClr}></insight-icon>
        `;
    }
    return '';
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div id="app-banner" ?data-shown=${this.__bannerShown}>
        ${this.__bannerIconTpl}
        <div id="app-banner-msg">
          <slot name="bannerMessage"></slot>
        </div>
        <div id="app-banner-btns">
          <slot name="bannerButtons"></slot>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host #app-banner {
          box-sizing: border-box;
          min-height: 58px;
          background: var(--mdc-theme-dark-bg-16, var(--mdc-theme-background));
          border: 1px solid var(--mdc-theme-divider-color);
          padding: 6px 15%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-left: 1px;
          opacity: 0;
        }
        :host #app-banner[data-shown] {
          opacity: 1;
        }
        :host([mobile-res]) #app-banner,
        :host([tablet-res]) #app-banner {
          padding: 6px;
          margin-left: 0;
        }
        :host #app-banner-icon {
          margin-right: 16px;
          color: white;
          background: var(--mdc-theme-text-primary));
          border-radius: 50%;
          padding: 5px;
          max-height: 30px;
          --icon-width: 30px;
          --icon-height: 30px;
        }
        :host #app-banner-btns {
          margin-left: 24px;
        }
        :host([mobile-res]) #app-banner {
          display: block;
          padding: 6px 16px;
        }
        :host([mobile-res]) #app-banner-msg {
          height: 40px;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -ms-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
        }
        :host([mobile-res]) #app-banner-msg > div {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        :host([mobile-res]) #app-banner-icon {
          float: left;
        }
        :host([mobile-res]) #app-banner-btns {
          margin-top: 0;
          text-align: right;
        }
      </style>
    `;
  }
}
window.customElements.define('app-banner', AppBanner);
