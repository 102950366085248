import {InsightComponent, html} from '../insight-component.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '../button/insight-button.js';
import '../container/insight-dialog.js';
import './parts/insight-payment-add-ach.js';
import './parts/insight-payment-add-address.js';
import './parts/insight-payment-add-card.js';
import './parts/insight-payment-add-contact.js';
import './parts/insight-payment-add-cvc.js';
import './parts/insight-payment-list.js';
import './parts/insight-payment-review.js';
import './parts/insight-payment-summary.js';

class InsightPaymentRequest extends InsightComponent {
  static get properties() {
    return {
      alignTop: {type: Boolean, attribute: 'align-top'},
      __orderDetails: {type: Object},
      __options: {type: Object},
      __view: {type: Number},
      __processing: {Type: Boolean}
    };
  }

  static get Views() {
    return {
      Review: 0,
      Payment: 1,
      Contact: 2,
      Summary: 3,
      AddCard: 4,
      AddContact: 5,
      AddAddress: 6,
      AddAch: 7,
      CVC: 8
    };
  }

  /****************************************************  Public Api  ****************************************************/

  /*
    Options:
      acceptACH - the ability to add ACH as a payment type
      skipCVC - don't require CVC value
      ach - the current ACH info on file (to use or update)
      cancelButtonLabel - label to use on the button the cancels the payment control
      cardsOnFile - the credit cards on file (to use or update)
      contactsOnFile - the billing contact info on file
      addressesOnFile - additional addresses on file
      networks - which credit card types supported (e.g. ['amex', 'mastercard', 'visa', 'discover'])
      requestPayerEmail - require a contact info email be present to complete purchase
      requestPayerName - require a contact info name be present to complete purchase
      requestPayerPhone - require a contact info phone number be present to complete purchase
      submitButtonLabel - button label to complete purchase/update
  */

  show(orderDetails, options) {
    options.paymentType = 'insight-card';
    return new Promise(resolve => {
      this.__orderDetails = orderDetails;
      this.__options = options;
      this.__findPaymentCard(options);
      this.__callback = resolve;
      if (options.networks && options.networks.length && (!options.cardsOnFile || !options.cardsOnFile.length) && !options.ach) {
        options.noInitialCardsOnFile = true;
        this.__view = InsightPaymentRequest.Views.AddCard;
      }
      this._afterRender(() => {
        this.__dialogEl.open(this.__viewElement.getFocusEl());
      });
    });
  }

  /**************************************************  Private Methods  *************************************************/

  __close(complete) {
    this.__dialogEl.close();
    if (!complete) this.__callback(this.__cancelledTemplate);
  }

  __dialogClosed() {
    this.__processing = false;
    this.__view = InsightPaymentRequest.Views.Review;
  }

  __findPaymentCard(options) {
    if (!options.cardsOnFile || !options.cardsOnFile.length) return;
    const defIdx = options.cardsOnFile.findIndex(c => c.default === true);
    if (defIdx > 0) options.cardsOnFile.unshift(options.cardsOnFile.splice(defIdx, 1)[0]);
  }

  __getCurrentView(view, processing) {
    if (processing) {
      return html`
        <div id="payment-loader" class="flex-layout-vertical flex-layout-center-center">
          <div class="typo-title loader-title">${this._i18n('payment', 'processing', 'Processing')}...</div>
          <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
        </div>
      `;
    }
    switch (view) {
      case InsightPaymentRequest.Views.Payment:
      case InsightPaymentRequest.Views.Contact:
        return html`
          <insight-payment-list
            .orderDetails=${this.__orderDetails}
            .options=${this.__options}
            .listType=${view === InsightPaymentRequest.Views.Payment ? 1 : 0}
            @change-view=${e => (this.__view = e.detail)}
          ></insight-payment-list>
        `;
      case InsightPaymentRequest.Views.Summary:
        return html`
          <insight-payment-summary
            .orderDetails=${this.__orderDetails}
            .options=${this.__options}
            @purchase=${this.__purchase}
            @close-dialog=${() => this.__close()}
            @change-view=${e => (this.__view = e.detail)}
          ></insight-payment-summary>
        `;
      case InsightPaymentRequest.Views.AddCard:
        return html`
          <insight-payment-add-card
            .options=${this.__options}
            @change-view=${e => (this.__view = e.detail)}
            @close-dialog=${() => this.__close()}
          ></insight-payment-add-card>
        `;
      case InsightPaymentRequest.Views.AddAddress:
        return html`
          <insight-payment-add-address .options=${this.__options} @change-view=${e => (this.__view = e.detail)}></insight-payment-add-address>
        `;
      case InsightPaymentRequest.Views.AddContact:
        return html`
          <insight-payment-add-contact .options=${this.__options} @change-view=${e => (this.__view = e.detail)}></insight-payment-add-contact>
        `;
      case InsightPaymentRequest.Views.AddAch:
        return html`
          <insight-payment-add-ach .options=${this.__options} @change-view=${e => (this.__view = e.detail)}></insight-payment-add-ach>
        `;
      case InsightPaymentRequest.Views.CVC:
        return html`
          <insight-payment-add-cvc
            .options=${this.__options}
            @change-view=${e => (this.__view = e.detail)}
            @complete-purchase=${this.__finishPurchase}
          ></insight-payment-add-cvc>
        `;
      default:
        return html`
          <insight-payment-review
            .orderDetails=${this.__orderDetails}
            .options=${this.__options}
            @close-dialog=${() => this.__close()}
            @purchase=${this.__purchase}
            @change-view=${e => (this.__view = e.detail)}
          ></insight-payment-review>
        `;
    }
  }

  __purchase(e) {
    // if (this.__options.cardsOnFile && this.__options.cardsOnFile.length && !this.__options.cardsOnFile[0].id && !this.__options.skipCVC)
    //   this.__view = InsightPaymentRequest.Views.CVC;
    // else this.__finishPurchase();
    this.__finishPurchase();
  }

  __finishPurchase() {
    this.__processing = true;
    const result = this.__resultTemplate;
    result.methodName = this.__options.paymentType;
    if (this.__options.requestPayerName) result.payerName = this.__options.contactsOnFile[0].fullName;
    if (this.__options.requestPayerEmail) result.payerEmail = this.__options.contactsOnFile[0].email;
    if (this.__options.requestPayerPhone) result.payerPhone = this.__options.contactsOnFile[0].phone;
    if (this.__options.ach) {
      result.details = this.__options.ach;
    } else {
      const paymentCard = this.__options.cardsOnFile[0];
      result.details = {
        billingAddress: {
          addressLine: [paymentCard.address],
          city: paymentCard.city,
          country: paymentCard.country,
          dependentLocality: '',
          organization: '',
          phone: paymentCard.phone,
          postalCode: paymentCard.postalCode,
          recipient: paymentCard.nameOnCard,
          region: paymentCard.state,
          sortingCode: ''
        },
        id: paymentCard.id,
        cardType: paymentCard.type,
        cardNumber: paymentCard.number,
        cardSecurityCode: paymentCard.cvc,
        cardholderName: paymentCard.nameOnCard,
        expiryMonth: paymentCard.expireMonth,
        expiryYear: paymentCard.expireYear
      };
    }
    this.__callback(result);
  }

  get __resultTemplate() {
    return {
      complete: () => this.__close(true)
    };
  }

  get __cancelledTemplate() {
    return {
      cancelled: true
    };
  }

  get __viewElement() {
    return this._getElement('#modal-body > div > *');
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <insight-dialog id="payment-dialog" modal full-height-content ?slide-down-overlay=${this.alignTop} @closed=${this.__dialogClosed}>
        <div id="modal-body" slot="modalBody">
          <div>
            ${this.__getCurrentView(this.__view, this.__processing)}
          </div>
        </div>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-dialog {
          --dialog-max-width: 600px;
          --dialog-min-width: 600px;
          --dialog-max-height: 500px;
          --dialog-min-height: 500px;
          --dialog-padding-body: 16px 20px 8px;
          --dialog-margin-top: 112px;
        }
        :host #modal-body,
        :host #modal-body > div {
          height: 100%;
        }
        :host #payment-loader {
          height: 100%;
        }
        :host .loader-title {
          margin-bottom: 16px;
        }
        @media only screen and (max-width: 620px) {
          :host insight-dialog {
            --dialog-max-width: 95vw;
            --dialog-min-width: 95vw;
            --dialog-padding-body: 16px 8px 8px;
          }
        }
        @media only screen and (max-height: 620px) {
          :host insight-dialog {
            --dialog-max-height: 80vh;
            --dialog-min-height: 80vh;
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-request', InsightPaymentRequest);
