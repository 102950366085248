import i18next from 'i18next';
import Fetch from 'i18next-fetch-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Backend from 'i18next-chained-backend';
import Pseudo from 'i18next-pseudo';
import {loadTranslationFiles} from './insight-localization.js';

export class InsightRouter {
  init(routes, handler) {
    try {
      page(
        '/',
        (ctx, next) => this.__handlePreload(routes[0], ctx, next),
        () => handler(routes[0])
      );
      routes.forEach(r =>
        page(
          `/${r.path}`,
          (ctx, next) => this.__handlePreload(r, ctx, next),
          ctx => handler(r, ctx)
        )
      );
      page('*', () => page.redirect('/'));
      page({dispatch: false});
    } catch (e) {
      console.error('Error occurred setting up app router', e);
    }
  }

  navigate(route, replaceHistory) {
    page.show(route, null, null, false);
    if (replaceHistory) window.history.replaceState(null, '', route);
    else window.history.pushState(null, '', route);
    document.dispatchEvent(new CustomEvent('url-change', {detail: route}));
  }

  async __handlePreload(route, ctx, next) {
    if (route.langKey) await loadTranslationFiles(Array.isArray(route.langKey) ? route.langKey : [route.langKey]);
    next();
  }
}
