import trackLastN from './last-n-tracking.js';

const consoleOverride = (function (origConsole) {
  return {
    debug: (...args) => origConsole.debug(...args),
    dir: (...args) => origConsole.dir(...args),
    log: (...args) => origConsole.log(...args),
    info: (...args) => origConsole.info(...args),
    warn: (...args) => origConsole.warn(...args),
    error: (...args) => {
      origConsole.error(...args);
      const msgObj = {
        appName: Insight.appName,
        appVersion: Insight.version + (!!Insight.mvcVersion ? ' (MVC - ' + Insight.mvcVersion + ')' : ''),
        timestamp: new Date().getTime(),
        browser: Insight.browser ? ' - ' + Insight.browser.toString() : null,
        detail: JSON.stringify(args)
      };
      if (Insight.auth && Insight.auth.clientId) msgObj.clientId = Insight.auth.clientId;
      if (Insight.auth && Insight.auth.locationId) msgObj.locationId = Insight.auth.locationId;
      if (Insight.auth && Insight.auth.sub) msgObj.sub = Insight.auth.sub;
      if (Insight.auth && Insight.auth.name) msgObj.name = Insight.auth.name;
      if (window.location) msgObj.path = window.location.href;
      const message = JSON.stringify(msgObj);
      trackLastN('error-history', 5, message);
      if (!window.location.hostname.includes('localhost') && Insight.configs && Insight.configs.insightApiUrl) {
        navigator &&
          navigator.serviceWorker &&
          navigator.serviceWorker.getRegistrations().then(regs => {
            if (!regs.length) return;
            regs[0].active.postMessage({
              type: 'log',
              apiUrl: Insight.configs.insightApiUrl,
              level: 4,
              message: message
            });
          });
      }
    },
    time: (...args) => origConsole.time(...args),
    timeEnd: (...args) => origConsole.timeEnd(...args),
    timeLog: (...args) => origConsole.timeLog(...args)
  };
})(window.console);
if (Insight.version !== 'DEV') window.console = consoleOverride;
