import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {AccountUtils} from '../../util/account-utils.js';
import {InsightMvcUrls} from '../../util/insight-mvc-urls.js';
import '@insight/insight-common/components/form/insight-search-input.js';
import '@insight/insight-common/components/ui/insight-icon.js';

class GlobalSearch extends InsightElement {
  static get SearchType() {
    return {
      Accounts: 0,
      Features: 1,
      Reports: 2,
      HelpArticles: 3
    };
  }

  /****************************************************  Public Api  ****************************************************/
  static get properties() {
    return {
      modalsEl: {type: HTMLElement}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__currentResults = [];
    this.__staticResults = [];
    this.__resetTypeOnSearch = true;
    this.__debouncedSearch = this._debounce(750, e => {
      if (this.__resetTypeOnSearch) this.__searchType = GlobalSearch.SearchType.Accounts;
      else this.__resetTypeOnSearch = true;
      this.__performSearch(e);
    });
    this._subscribe('search', state => this.__finishSearch(state));
  }

  __performSearch({detail: searchTerm}) {
    this.__searchTerm = searchTerm;
    this.__currentResults = [];
    this.__staticResults = [];
    this.__searchEl.setResults(this.__currentResults, true);
    let searchTypes = [];
    switch (this.__searchType) {
      case GlobalSearch.SearchType.Accounts:
        searchTypes = searchTypes.concat(['accounts', 'credentials']);
        if (this.__searchTerm.includes('addr:')) searchTypes.push('addresses');
        if (Insight.user.administrator || this._hasAccess('EDIT_USER')) searchTypes.push('users');
        if (Insight.user.administrator) searchTypes.push('locations');
        if (this.__searchTerm.includes('sw:')) searchTypes.push('global-static-0');
        if (this.__searchTerm.includes('rpt:')) searchTypes.push('global-static-1');
        if (this.__searchTerm.includes('help:')) searchTypes.push('kb');
        break;
      case GlobalSearch.SearchType.Features:
        searchTypes.push('global-static-0');
        break;
      case GlobalSearch.SearchType.Reports:
        searchTypes.push('global-static-1');
        break;
      case GlobalSearch.SearchType.HelpArticles:
        searchTypes.push('kb');
        break;
    }
    this.__searchTypesCount = searchTypes.length;
    Insight.state.dispatch('fetchSearch', {searchTerms: this.__searchTerm, types: searchTypes});
  }

  __finishSearch(searchResult) {
    this.__searchTypesCount--;
    switch (searchResult.type) {
      case 'users':
        this.__currentResults = this.__currentResults.concat(
          searchResult.results.map(u => {
            return {
              id: u.userId,
              url: Insight.user.administrator
                ? InsightMvcUrls.clientsView.replace(/\{client\}/, u.clientId)
                : InsightMvcUrls.userEdit.replace(/\{user\}/, u.userId),
              button: Insight.user.administrator
                ? {
                    label: this._i18n('appshell:search.become'),
                    handler: e => {
                      e.stopImmediatePropagation();
                      this._dispatchEvent('become-user', u.userId);
                    }
                  }
                : undefined,
              title: `${u.fullName} - ${this._i18n('appshell:search.user')}`,
              titleTpl: html`${u.fullName}<span style="font-weight:400;"> - ${this._i18n('appshell:search.user')}</span>`, //prettier-ignore
              description: `${u.locationName} - ${u.locationRole.toLowerCase().replace('_', ' ')} - ${u.username}`,
              avatar: {name: u.fullName, photo: u.imageUrl || undefined}
            };
          })
        );
        break;
      case 'accounts':
      case 'addresses':
        this.__currentResults = this.__currentResults.concat(
          searchResult.results.map(u => {
            return {
              id: u.accountGuid,
              url: `accounts/${u.accountGuid}/view`,
              iconButton: {
                icon: 'person_search',
                title: this._i18n('appshell:search.viewMember'),
                handler: e => {
                  e.stopImmediatePropagation();
                  this.__searchEl.value = '';
                  this._navigateOnClick(e, `members/${u.memberId}/view`);
                }
              },
              title: `${u.fullName} - ${
                u.accountType === 'GUEST' ? u.accountType.toLowerCase() : u.accountStatus.toLowerCase() + ' ' + this._i18n('common:member').toLowerCase()
              }`,
              titleTpl: html`${u.fullName}<span style="font-weight:400;"> - ${u.accountType === 'GUEST' ? u.accountType.toLowerCase() : html`<span style="color:${AccountUtils.getAccountStatusCssColor(u.accountType, u.accountStatus)};">${u.accountStatus.toLowerCase()}</span> ${this._i18n('common:member').toLowerCase()}`}${u.memberCancelled ? html` <i style="text-transform:lowercase;color:var(--mdc-theme-text-error);">(${this._i18n('common:cancelled')})</i>` : ''}</span>`, //prettier-ignore
              description: `${searchResult.type === 'addresses' ? u.address + ' - ' : ''}${u.accountNumber} at ${u.locationName}`,
              avatar: {name: u.fullName, photo: u.imageUrl || undefined}
            };
          })
        );
        break;
      case 'credentials':
        this.__currentResults = this.__currentResults.concat(
          searchResult.results.map(u => {
            return {
              id: u.memberGuid,
              url: `members/${u.memberGuid}/view`,
              title: `${u.fullName || this._i18n('appshell:search.notAssigned')} - ${this._i18n('appshell:search.key')}`,
              titleTpl: html`${u.fullName || this._i18n('appshell:search.notAssigned')}<span style="font-weight:400;"> - ${this._i18n('appshell:search.key')}</span>`, //prettier-ignore
              description: `${u.keyNumber} at ${u.locationName}`,
              avatar: {name: u.fullName, photo: u.imageUrl || undefined}
            };
          })
        );
        break;
      case 'locations':
        this.__currentResults = this.__currentResults.concat(
          searchResult.results.map(u => {
            return {
              id: u.locationId,
              url: InsightMvcUrls.clientsView.replace(/\{client\}/, u.clientId),
              title: u.name,
              description: this._i18n('common:location'),
              avatar: {
                name: u.name,
                icon: 'location_on'
              },
              suffixTpl: html`
                <div style="min-width:168px;min-height:40px;max-width:168px;max-height:40px;">
                  <img src="${u.logoUrl}" style="max-width:168px;max-height:40px;" onerror="this.style.display='none';" />
                </div>
              `
            };
          })
        );
        break;
      case 'global-static-0':
      case 'global-static-1':
        this.__currentResults = searchResult.results;
        break;
      case 'kb':
        this.__currentResults = searchResult.results.map(u => {
          return {
            id: u.articleId,
            url: InsightMvcUrls.knowledgeArticle + `/${u.articleId}-${u.slug}`,
            title: `${u.title} - ${this._i18n('appshell:search.help')}`,
            titleTpl: html`${u.title}<span style="font-weight:400;"> - ${this._i18n('appshell:search.help')}</span>`, //prettier-ignore
            description: '',
            descriptionTpl: html`
              <span style="margin-left:-3px;">${unsafeHTML(u.summary.split('&nbsp;').join('').trim())}</span>
            `,
            avatar: {name: u.title, icon: 'menu_book'},
            onSelect: () => this.modalsEl.showHelpArticle(u)
          };
        });
        break;
    }
    if (this.__searchTypesCount === 0 && this.__searchType === GlobalSearch.SearchType.Accounts && !this.__searchTerm.includes(':')) {
      const getStaticTpl = text => html`<i style="color:var(--mdc-theme-text-secondary)">${text}<i>`; //prettier-ignore
      this.__staticResults = [
        {
          titleTpl: getStaticTpl(unsafeHTML(this._i18n('appshell:search.showReports', {term: this.__searchTerm}))),
          avatar: {icon: 'insert_chart'},
          searchType: GlobalSearch.SearchType.Reports
        },
        {
          titleTpl: getStaticTpl(unsafeHTML(this._i18n('appshell:search.showFeatures', {term: this.__searchTerm}))),
          avatar: {icon: 'wysiwyg'},
          searchType: GlobalSearch.SearchType.Features
        },
        {
          titleTpl: getStaticTpl(unsafeHTML(this._i18n('appshell:search.showHelp', {term: this.__searchTerm}))),
          avatar: {icon: 'menu_book'},
          searchType: GlobalSearch.SearchType.HelpArticles
        }
      ];
    }
    this.__searchEl.setResults(this.__currentResults.concat(this.__staticResults), this.__searchTypesCount > 0);
  }

  __searchResultSelected({detail: {item, event}}) {
    if (!!item.searchType) {
      this.__searchEl.focus();
      this.__searchType = item.searchType;
      this.__resetTypeOnSearch = false;
      this.__searchEl.__performSearch();
    } else {
      let url = item.url;
      if (url.startsWith('/')) url = url.substr(1);
      if (item.onSelect) {
        this.__searchEl.clear(true);
        item.onSelect();
      } else {
        if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.which !== 2) this.__searchEl.clear(true);
        this._dispatchEvent('navigate-app', {path: url, event: event});
      }
    }
  }

  __addPrefix(key) {
    const el = this.__searchEl;
    el.value = key + '""';
    el.focus();
    const pos = key.length + 1;
    el.__inputEl.__inputEl.setSelectionRange(pos, pos);
  }

  get __searchEl() {
    return this._getElement('insight-search-input');
  }

  get __articleEl() {
    return this._getElement('insight-help-article');
  }

  __getHelpTplRow(key, label) {
    return html`
      <div class="flex-layout-horizontal flex-layout-center" style="cursor:pointer;margin:8px 0;" @click=${() => this.__addPrefix(key)}>
        <insight-icon icon-name="search" style="--icon-width:18px;"></insight-icon>
        <div class="typo-body2" style="height:20px;background:var(--mdc-theme-surface);border-radius:4px;margin:0 8px;padding: 0 4px;">${key}</div>
        <div class="typo-body2">${this._i18n(label)}</div>
      </div>
    `;
  }

  __getHelpTpl() {
    return html`
      <div class="flex-layout-vertical" style="padding:16px 24px;">
        <div class="typo-body2" style="margin-bottom:8px;color:var(--mdc-theme-text-secondary)">${this._i18n('appshell:search.helpTitle')}</div>
        ${this.__getHelpTplRow('name:', 'appshell:search.helpByName')}
        ${Insight.user.administrator ? this.__getHelpTplRow('loc:', 'appshell:search.helpByLoc') : ''}
        ${this.__getHelpTplRow('acct:', 'appshell:search.helpByAcct')} ${this.__getHelpTplRow('phone:', 'appshell:search.helpByPhone')}
        ${this.__getHelpTplRow('key:', 'appshell:search.helpByKey')} ${this.__getHelpTplRow('addr:', 'appshell:search.helpByAddr')}
        ${this.__getHelpTplRow('rpt:', 'appshell:search.helpByRpt')}${this.__getHelpTplRow('sw:', 'appshell:search.helpBySw')}
        ${this.__getHelpTplRow('help:', 'appshell:search.helpByHelp')}
      </div>
    `;
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-search-input
        outlined
        .helpTpl=${this.__getHelpTpl()}
        ?search-as-overlay=${this._mobileRes}
        @item-select=${this.__searchResultSelected}
        @perform-search=${this.__debouncedSearch}
      ></insight-search-input>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          height: 48px;
          --dropdown-width: 100%;
        }
        :host(:not([mobile-res])) insight-search-input {
          margin-top: 4px;
        }
      </style>
    `;
  }
}
window.customElements.define('global-search', GlobalSearch);
