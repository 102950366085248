import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nDate} from '@insight/insight-common/i18n/i18n-date.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/container/insight-dialog.js';
import '@insight/insight-common/components/ui/insight-icon.js';
import {InsightMvcUrls} from '../../util/insight-mvc-urls.js';
import '../member-search/member-search.js';

class CredentialMemberAssoc extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      __selectedMember: {type: Object},
      __credId: {type: String},
      __credDate: {type: String},
      __memberToAssoc: {type: String},
      __queryActive: {type: Boolean},
      __credentialAssigned: {type: Object}
    };
  }

  show(credId, credDate, memberToAssign) {
    this.__credentialAssigned = null;
    this.__queryActive = true;
    this.__credId = credId;
    this.__credDate = i18nDate.formatDateTimeInLocale(credDate, Insight.location.ianaTimezone, true);
    this.__memberToAssoc = memberToAssign;
    if (this.__memberToAssoc) {
      this.__viewedMemberDialogEl.open();
    } else {
      this.__searchEl.reset();
      this.__credDialogEl.open();
    }
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this._listenForWindowResize(() => {
      const memberDialog = this.__viewedMemberDialogEl;
      if (memberDialog) memberDialog.close();
      const credDialog = this.__credDialogEl;
      if (credDialog) credDialog.close();
    });
  }

  __dialogOpened() {
    Insight.state.fetchOneTime(
      'search',
      state => {
        if (!state.results || state.results.length === 0) {
          this.__queryActive = false;
          this._afterRender(() => this.__searchEl.focusSearch());
        } else {
          const cred = state.results[0];
          if (!!cred.memberGuid) {
            this.__credentialAssigned = {
              guid: cred.memberGuid,
              name: cred.fullName
            };
          } else {
            this.__queryActive = false;
            this._afterRender(() => this.__searchEl.focusSearch());
          }
        }
      },
      false,
      {searchTerms: this.__credId, types: ['credentials']}
    );
  }

  __memberSelected({detail: member}) {
    this.__selectedMember = member;
  }

  __handleAssign() {
    this.__queryActive = true;
    Insight.state.createOneTime(
      'memberCredential',
      state => {
        if (this.__memberToAssoc) {
          this.__viewedMemberDialogEl.close();
        } else {
          this.__credDialogEl.close();
          this.__viewedMemberDialogEl.close();
        }
        if (!!state.success) {
          this._dispatchEvent('show-snackbar', this._i18n('account:member.credentialAssigned'));
          this._dispatchEvent('navigate-app', `members/${this.__memberToAssoc ? this.__memberToAssoc : this.__selectedMember.memberId}/view`);
        }
        this.__memberToAssoc = this.__selectedMember = null;
        this.__queryActive = false;
      },
      {
        memberGuid: this.__memberToAssoc ? this.__memberToAssoc : this.__selectedMember.memberId,
        credentialNumber: this.__credId
      }
    );
  }

  __viewMember(e) {
    if (this._getEventPath(e)[0].classList.contains('cred-member')) {
      this._dispatchEvent('navigate-app', `members/${this.__credentialAssigned.guid}/view`);
      this.__credDialogEl.close();
      this.__viewedMemberDialogEl.close();
    }
  }

  get __credDialogEl() {
    return this._getElement('#credential-assoc-dialog');
  }

  get __viewedMemberDialogEl() {
    return this._getElement('#member-assign-dialog');
  }

  get __searchEl() {
    return this._getElement('member-search');
  }

  _render() {
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <insight-dialog id="credential-assoc-dialog" @opened=${this.__dialogOpened} modal>
        <div slot="modalHeader">
          <div>${this._i18n('components:memberCredentialAssoc.title')}</div>
        </div>
        <div slot="modalBody">
          <div class="cred-detail flex-layout-horizontal">
            <insight-icon icon-name="credit_card"></insight-icon>
            <div>
              <div class="cred-row">
                <div class="typo-caption cred-lbl">${this._i18n('components:memberCredentialAssoc.credNum')}:</div>
                <div class="typo-caption cred-num">#${this.__credId}</div>
              </div>
              <div class="cred-row">
                <div class="typo-caption cred-lbl">${this._i18n('components:memberCredentialAssoc.lastUsed')}:</div>
                <div class="typo-caption cred-used">${this.__credDate}</div>
              </div>
            </div>
          </div>
          <member-search @member-select=${this.__memberSelected} ?hidden=${this.__queryActive || this.__credentialAssigned}></member-search>
          <div id="search-loader" class="flex-layout-horizontal flex-layout-center-justified" ?hidden=${!this.__queryActive}>
            <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''} ?hidden=${this.__credentialAssigned}></div>
            <div class="typo-body2 assigned-message" ?hidden=${!this.__credentialAssigned} @click=${this.__viewMember}>
              ${unsafeHTML(
                this._i18n('components:memberCredentialAssoc.alreadyAssigned', {fullName: this.__credentialAssigned ? this.__credentialAssigned.name : ''})
              )}
            </div>
          </div>
        </div>
        <span slot="modalFooter" class="buttons modal-footer">
          <insight-button id="btn-cancel" dialog-dismiss tabindex="0">${this._i18n('common:cancel')}</insight-button>
          <insight-button
            id="btn-save"
            contained
            tabindex="0"
            @click=${this.__handleAssign}
            ?disabled=${this.__queryActive || !this.__selectedMember}
            ?hidden=${this.__credentialAssigned}
          >
            ${this._i18n('account:member.assign')}
          </insight-button>
        </span>
      </insight-dialog>
      <insight-dialog id="member-assign-dialog" @opened=${this.__dialogOpened} modal>
        <div slot="modalHeader" class="modal-header">${this._i18n('components:memberCredentialAssoc.title')}</div>
        <div id="modal-body" slot="modalBody">
          <div id="search-loader" class="flex-layout-horizontal flex-layout-center-justified" ?hidden=${!this.__queryActive}>
            <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''} ?hidden=${this.__credentialAssigned}></div>
            <div class="assigned-message" ?hidden=${!this.__credentialAssigned} @click=${this.__viewMember}>
              ${unsafeHTML(
                this._i18n('components:memberCredentialAssoc.alreadyAssigned', {fullName: this.__credentialAssigned ? this.__credentialAssigned.name : ''})
              )}
            </div>
          </div>
          ${!this.__queryActive
            ? html`
                ${unsafeHTML(this._i18n('account:member.assignToViewedMemberDesc', {credential: this.__credId}))}
              `
            : ''}
        </div>
        <span slot="modalFooter" class="buttons modal-footer">
          <insight-button id="btn-cancel" dialog-dismiss tabindex="0">${this._i18n('common:cancel')}</insight-button>
          <insight-button
            id="btn-confirm"
            contained
            tabindex="0"
            @click=${this.__handleAssign}
            ?disabled=${this.__queryActive}
            ?hidden=${this.__credentialAssigned}
          >
            ${this._i18n('account:member.assign')}
          </insight-button>
        </span>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host #credential-assoc-dialog {
          --dialog-padding-body: 20px 24px 0;
          --dialog-padding-footer: 8px 24px;
        }
        :host #member-assign-dialog {
          --dialog-max-width: 360px;
        }
        :host .cred-detail {
          border: 1px solid var(--mdc-theme-divider-color);
          border-radius: 4px;
          padding: 4px 8px;
        }
        :host .cred-detail insight-icon {
          background: var(--mdc-theme-primary);
          border-radius: 50%;
          padding: 12px;
          --icon-color: white;
          --icon-width: 32px;
        }
        :host .cred-detail > div {
          padding: 8px 8px 8px 16px;
        }
        :host .cred-row {
          height: 20px;
          white-space: nowrap;
        }
        :host .cred-lbl {
          width: 80px;
        }
        :host .cred-lbl,
        :host .cred-num,
        :host .cred-used {
          display: inline-block;
        }
        :host .cred-num {
          font-weight: 700;
          color: var(--mdc-theme-primary);
        }
        :host #credential-assoc-dialog #search-loader {
          width: 482px;
          height: 294px;
          margin-top: 24px;
          max-width: calc(100vw - 66px);
        }
        :host #member-assign-dialog #search-loader {
          width: 312px;
          height: 48px;
          max-width: calc(100vw - 66px);
        }
        :host #search-loader[hidden] {
          display: none;
        }
        :host .insight-loader {
          width: 24px;
          height: 24px;
        }
        :host .cred-member {
          font-weight: 700;
          cursor: pointer;
          color: var(--mdc-theme-secondary);
        }
        :host .cred-member:hover {
          opacity: 0.65;
        }
        :host .cred-member:active {
          opacity: 1;
        }
      </style>
    `;
  }
}
window.customElements.define('credential-member-assoc', CredentialMemberAssoc);
