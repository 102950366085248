import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/assistance/insight-ask-question.js';
import '@insight/insight-common/components/assistance/insight-help-article.js';
import '@insight/insight-common/components/assistance/insight-sybg.js';
import '../common/account-summary.js';
import '../common/confirmation-dialog.js';
import '../app-shell/sybg-history-dialog.js';
import '../credential-member-assoc/credential-member-assoc.js';

class ShellModals extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  showAskAQuestion() {
    this.__questionEl.show();
  }

  showHelpArticle(article) {
    this.__articleEl.show(article);
  }

  showCredentialAssoc(credId, credDate, member) {
    this._getElement('credential-member-assoc').show(credId, credDate, member);
  }

  showSYBG(sybg) {
    if (!sybg) return;
    if (Array.isArray(sybg)) {
      sybg = sybg.filter(s => {
        if (s.isPreview) return true;
        if (!s.showInApp) return false;
        if (!!s.betaFeature && !Insight.user.currentLocationBetaTest) return false;
        return true;
      });
      if (sybg.length) this.__sybgEl.show(sybg);
    } else {
      Insight.state.fetchOneTime('sybg', state => this.__sybgEl.show([state]), false, {id: sybg});
    }
  }

  showSybgHistory() {
    this.__sybgHistoryEl.show();
  }

  showConfirmation(params) {
    const dialog = this.__confirmEl;
    dialog.show({
      title: params.title,
      body: params.message,
      danger: !!params.danger,
      warning: !!params.warning,
      success: !!params.success,
      submitFn: e => params.onAccept(() => dialog.close(), dialog),
      cancelFn: e => (!!params.onDecline ? params.onDecline(dialog) : null),
      submitBtnText: params.confirmBtnText,
      focusEl: params.focusEl
    });
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__addListeners();
  }

  __addListeners() {
    document.addEventListener('toggle-acct-summary', e => this.__toggleAccountSummary(e.detail));
  }

  __postQuestion({detail}) {
    Insight.state.dispatch('updateAskAQuestion', {question: detail, route: window.location.href});
    this._dispatchEvent('show-snackbar', this._i18n('appshell:helpmenu.thankYou'));
  }

  __toggleAccountSummary({id, x, y}) {
    this._getElement('account-summary').toggle(id, x, y);
  }

  get __articleEl() {
    return this._getElement('insight-help-article');
  }

  get __questionEl() {
    return this._getElement('insight-ask-question');
  }

  get __sybgEl() {
    return this._getElement('insight-sybg');
  }

  get __sybgHistoryEl() {
    return this._getElement('sybg-history-dialog');
  }

  get __confirmEl() {
    return this._getElement('confirmation-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-ask-question @submit=${this.__postQuestion}></insight-ask-question>
      <insight-sybg></insight-sybg>
      <credential-member-assoc></credential-member-assoc>
      <insight-help-article></insight-help-article>
      <account-summary></account-summary>
      <sybg-history-dialog></sybg-history-dialog>
      <confirmation-dialog></confirmation-dialog>
    `;
  }

  get __css() {
    return `
      <style>

      </style>
    `;
  }
}
window.customElements.define('shell-modals', ShellModals);
