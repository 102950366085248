import {InsightElement, html} from '../insight-element.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import './insight-input.js';
import '../button/insight-dropdown-button.js';
import '@insight/insight-common/components/ui/insight-icon.js';

class InsightDropdownMenu extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      disabled: {attribute: 'disabled', type: Boolean},
      label: {attribute: 'label', type: String},
      required: {attribute: 'required', type: Boolean},
      outlined: {attribute: 'outlined', type: Boolean},
      dense: {attribute: 'dense', type: Boolean},
      noUnderline: {attribute: 'no-underline', type: Boolean},
      errorMessage: {attribute: 'error-message', type: String},
      noLabel: {attribute: 'no-label', type: Boolean},
      alwaysFloatLabel: {attribute: 'always-float-label', type: Boolean},
      invertColors: {attribute: 'invert-colors', type: Boolean},
      fixedWidth: {attribute: 'fixed-width', type: Boolean},
      noBackground: {attribute: 'no-background', type: Boolean},
      noBottomMargin: {attribute: 'no-bottom-margin', type: Boolean},
      helperText: {attribute: 'helper-text', type: String},
      allowMultiHelper: {attribute: 'allow-multi-helper', type: Boolean},
      valueAsSelected: {attribute: 'value-as-selected', type: Boolean},
      fullWidth: {attribute: 'full-width', type: Boolean},
      prefixIcon: {attribute: 'prefix-icon', type: String},
      noArrow: {attribute: 'no-arrow', type: Boolean},
      minDropWidth: {attribute: 'min-drop-width', type: Number},
      halfHeightDrop: {attribute: 'drop-half-height', type: Boolean}
    };
  }

  get data() {
    return this.__data;
  }
  set data(value) {
    this.__data = value;
    this.__dataSet = true;
    this.__init();
  }

  get selected() {
    return this.__data ? this.__data[this.__selected] : null;
  }
  set selected(value) {
    this.__skipEvent = true;
    this.__selectedChanged(value);
  }

  get selectedIndex() {
    return this.__selectedIndex;
  }
  set selectedIndex(value) {
    this.__skipEvent = true;
    this.selected = this.__data && !isNaN(value) ? this.__data[value] : null;
  }

  validate() {
    const valid = Boolean(!this.required || this.selected);
    if (valid) this.__clearInvalid();
    else this.__setInvalid();
    return valid;
  }

  clear() {
    this.selected = null;
  }

  open() {
    this.__dropDownEl.open();
  }

  /* For Testing Only */
  set __selectedIndexWithEvent(value) {
    this.__selectedChanged(!isNaN(value) ? this.__data[value] : null);
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__keys = '';
    this.__data = this.data || [];
    this.__selected = -1;
  }

  firstUpdated() {
    this.__isReady = true;
    this._afterRender(() => {
      this.__init();
    });
  }

  __init() {
    if (!this.__isReady || !this.__dataSet || !this.__data || !this.__data.length) return;
    const sel = this.__data.find(d => d.selected === true);
    if (sel) {
      this.__skipEvent = true;
      this.__selectedChanged(sel);
    }
    this.__dropDownEl.data = this.__data;
    this.__adjustMenuWidth();
  }

  __changeHandler(e) {
    e.stopPropagation();
    this.__selectedChanged(e.detail);
  }

  __selectedChanged(selected) {
    this.__clearInvalid();
    this.__selected = selected ? this.__data.indexOf(selected) : -1;
    this.__inputEl.value = this.__selected >= 0 ? (this.valueAsSelected ? selected.value : selected.label) : '';
    this.__adjustMenuWidth();
    if (this.__skipEvent) {
      this.__skipEvent = false;
      return;
    }
    this._dispatchEvent('change', this.selected);
  }

  __adjustMenuWidth() {
    if (this.fixedWidth) return;
    const ipt = this.__inputEl._getElement('input');
    const dd = this.__dropDownEl;
    const compStyle = window.getComputedStyle(ipt, null);
    let extraWidth = parseInt(compStyle.getPropertyValue('--input-inner-margin'));
    if (isNaN(extraWidth)) extraWidth = 56;
    if (this.prefixIcon) extraWidth += 40;
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    const prop = ['font-style', 'font-variant', 'font-weight', 'font-size', 'font-family'];
    ctx.font = prop.reduce((t, i) => t + compStyle.getPropertyValue(i) + ' ', '');
    let val = ipt.value;
    if (!val) val = this.getAttribute('label');
    let ddWidth = val ? ctx.measureText(val).width : 'unset';
    ddWidth = Math.ceil(ddWidth + extraWidth);
    this.style.minWidth = this.style.maxWidth = ddWidth + 'px';
    if (window.Testophobia) this.style.minWidth = this.style.maxWidth = Math.ceil(ddWidth / 10) * 10 + 'px';
  }

  __menuOpened() {
    this.__inputEl.__textfield.foundation_.activateFocus();
    if (this.__selected >= 0) this.__dropDownEl.focusAtIndex(this.__selected);
  }

  __menuClosed() {
    this.validate();
    this.__inputEl.__textfield.foundation_.deactivateFocus();
    this.__inputEl.setFocus();
  }

  __setInvalid() {
    this.__inputEl.setAttribute('invalid', '');
  }

  __clearInvalid() {
    this.__inputEl.removeAttribute('invalid');
  }

  __dropdownKeyPress(e) {
    if (e.key.length > 1) return;
    const key = e.key.charAt(0);
    let code = key.charCodeAt(0);
    if (code < 65 || (code > 90 && code < 97) || code > 122) return;
    clearTimeout(this.__keysTimeout);
    this.__keys += e.key.toLowerCase();
    this.__keysTimeout = setTimeout(() => (this.__keys = ''), 2000);
    this.__applyKeystrokes();
  }

  __applyKeystrokes() {
    const match = this.__data.find(d => (d.value && d.value.toLowerCase() === this.__keys) || (d.label && d.label.toLowerCase().startsWith(this.__keys)));
    if (match) {
      this.__selectedChanged(match);
    } else {
      this.__keys = '';
    }
  }

  get __dropDownEl() {
    return this._getElement('insight-dropdown-button');
  }

  get __inputEl() {
    return this._getElement('insight-input');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dropdown-button
        is-input
        ?disabled=${this.disabled}
        ?full-width=${this.fullWidth}
        min-width=${ifDefined(this.minDropWidth)}
        ?half-height=${this.halfHeightDrop}
        @change=${this.__changeHandler}
        @keydown="${this.__dropdownKeyPress};"
        @open=${this.__menuOpened}
        @close=${this.__menuClosed}
      >
        <insight-input
          slot="dropdown-button"
          label=${ifDefined(this.noLabel ? undefined : this.label)}
          ?required=${this.required}
          ?disabled=${this.disabled}
          is-button
          ?always-float-label=${this.alwaysFloatLabel}
          ?no-bottom-margin=${this.noBottomMargin}
          ?outlined=${this.outlined}
          ?dense=${this.dense}
          ?no-underline=${this.noUnderline}
          ?no-background=${this.noBackground}
          error-message=${ifDefined(this.errorMessage)}
          helper-text=${ifDefined(this.helperText)}
          allow-multi-helper=${ifDefined(this.allowMultiHelper)}
        >
          ${this.prefixIcon
            ? html`
                <insight-icon slot="prefix" icon-name=${this.prefixIcon}></insight-icon>
              `
            : ''}
          ${this.noArrow
            ? ''
            : html`
                <insight-icon slot="suffix" icon-name="arrow_drop_down"></insight-icon>
              `}
        </insight-input>
      </insight-dropdown-button>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          --input-active-color: var(--dropdown-active-color);
          --input-color: var(--dropdown-color);
          --input-suffix-width: 24px;
          --input-overflow: ellipsis;
        }
        :host insight-icon[slot="suffix"] {
          width: 40px;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-dropdown-menu', InsightDropdownMenu);
