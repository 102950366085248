import {InsightComponent, html} from '../../insight-component.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '../../button/insight-button.js';
import '../../button/insight-icon-button.js';
import '../../form/insight-dropdown-menu.js';
import '../../form/insight-form.js';
import '../../form/insight-input.js';
import '../../ui/insight-cc-icon.js';

class InsightPaymentAddCvc extends InsightComponent {
  static get properties() {
    return {
      options: {type: Object}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  getFocusEl() {
    return this._getElement('#ipt-cvc');
  }

  /**************************************************  Private Methods  *************************************************/

  firstUpdated() {
    this._afterRender(() => {
      this._focusElement('#ipt-cvc');
    });
  }

  __getCardLabel(options) {
    let val = options.cardsOnFile[0].type + ' •••••' + options.cardsOnFile[0].last4;
    val = val.charAt(0).toUpperCase() + val.slice(1);
    return val;
  }

  __updateCvc(e) {
    if (this.__formEl.validate()) {
      this.options.cardsOnFile[0].cvc = this.__cvc;
      this._dispatchEvent('complete-purchase');
    }
  }

  get __formEl() {
    return this._getElement('insight-form');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical cvc-container">
        <div class="flex-layout-horizontal flex-layout-center">
          <insight-icon-button id="btnBack" icon-name="arrow_back" class="cvc-back" @click=${() => this._dispatchEvent('change-view', 0)}></insight-icon-button>
          <div class="typo-subhead cvc-title">
            <b>${this._i18n('payment', 'enterCvc', 'Enter the CVC for')} ${this.__getCardLabel(this.options)}</b>
          </div>
        </div>
        <div class="typo-body2">
          ${this._i18n('payment', 'cvcDesc', "Enter your card's CVC code (3-4 digit code, usually found on the back of the card).")}
        </div>
        <insight-form id="cvc-form" class="flex-layout-flex">
          <div class="flex-layout-horizontal">
            <insight-cc-icon></insight-cc-icon>
            <insight-input
              autocomplete="cc-csc"
              dense
              class="flex-layout-flex"
              id="ipt-cvc"
              pattern="[0-9]{3,4}"
              maxlength="4"
              label=${this._i18n('payment', 'cvc', 'CVC')}
              required
              error-message=${this._i18n('payment', 'error.cvcReq', 'Invalid CVC')}
              @change=${e => (this.__cvc = e.detail)}
            ></insight-input>
          </div>
        </insight-form>
        <div class="flex-layout-horizontal flex-layout-end-justified cvc-footer">
          <insight-button id="btnUpdate" contained @click=${this.__updateCvc}>
            ${this._i18n('payment', 'confirm', 'Confirm')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .cvc-container {
          height: 100%;
        }
        :host .cvc-back {
          margin-left: -16px;
          margin-top: -12px;
        }
        :host .cvc-title {
          margin-bottom: 12px;
        }
        :host insight-cc-icon {
          margin-top: 4px;
          position: relative;
        }
        :host insight-cc-icon:after {
          content: '';
          border: 2px solid red;
          position: absolute;
          right: 3px;
          top: 13px;
          height: 7px;
          width: 10px;
          border-radius: 50%;
        }
        :host #ipt-cvc {
          margin-left: 16px;
          min-width: 120px;
          max-width: 120px;
        }
        :host #cvc-form {
          margin-top: 20px;
        }
        :host .cvc-footer {
          padding: 8px 0;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-add-cvc', InsightPaymentAddCvc);
