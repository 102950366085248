import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';

class MemberPhoto extends InsightElement {
  static get properties() {
    return {
      acctId: {type: String, attribute: 'account-id'},
      imageUrl: {type: String, attribute: 'image-url'},
      noPopup: {type: Boolean, attribute: 'no-popup'}
    };
  }

  updated(changedProperties) {
    if (changedProperties.has('imageUrl')) this.__updateImage();
  }

  __updateImage() {
    this._afterRender(() => {
      const img = this._getElement('img');
      if (this.imageUrl && this.imageUrl !== 'undefined') img.src = this.imageUrl;
    });
  }

  __toggleSummary() {
    if (this.noPopup || !this.__checkAccountId(this.acctId)) return;
    const br = this.getBoundingClientRect();
    this._dispatchEvent('toggle-acct-summary', {id: this.acctId, x: br.left, y: br.top});
  }

  __checkAccountId(acctId) {
    return !!acctId && acctId !== 'null' && acctId !== 'undefined';
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <img
        src="${window._photoPlaceholder}"
        onerror="this.src='${window._photoPlaceholder}';"
        @click=${this.__toggleSummary}
        ?data-can-click=${this.__checkAccountId(this.acctId)}
      />
      <div class="status-ball"></div>
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          position: relative;
        }
        :host {
          width: var(--photo-width, 64px);
          height: var(--photo-height, 48px);
        }
        :host img {
          box-sizing: border-box;
          width: var(--photo-width, 64px);
          height: var(--photo-height, 48px);
          border-radius: 4px;
          border-color: var(--photo-border-color, var(--mdc-theme-divider-color));
          border-width: var(--photo-border-width, 1px);
          border-style: solid;
        }
        :host img[data-can-click] {
          cursor: pointer;
        }
        :host .status-ball {
          position: absolute;
          right: -4px;
          top: -4px;
          border: 2px solid var(--mdc-theme-dark-bg-8, var(--mdc-theme-background));
          border-radius: 50%;
          width: 16px;
          height: 16px;
          display: none;
        }
        :host([account-status="YELLOW"]) .status-ball {
          display: initial;
          background: var(--mdc-theme-warning);
        }
        :host([account-status="RED"]) .status-ball,
        :host([account-status="COLLECTIONS"]) .status-ball,
        :host([account-status="CANCELLED"]) .status-ball {
          display: initial;
          background: var(--mdc-theme-error);
        }
      </style>
    `;
  }
}
window.customElements.define('member-photo', MemberPhoto);
