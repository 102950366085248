const i18nMoney = {
  format: (val, currency, i18n) => {
    val = parseFloat(val);
    const fmt = n => {
      const isNeg = n < 0;
      const str = Math.abs(n).toLocaleString(window.navigator.language, {style: 'currency', currency: currency || 'USD'});
      return isNeg ? '(' + str + ')' : str;
    };
    if (val && val.toLocaleString) return fmt(val);
    return i18n ? i18n('common:free') : fmt(0);
  }
};

export {i18nMoney};
