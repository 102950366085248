import {InsightComponent, html} from '../../insight-component.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nMoney} from '../../../i18n/i18n-money.js';
import '../../button/insight-button.js';
import '../../ui/insight-cc-icon.js';
import '../../ui/insight-icon.js';

class InsightPaymentReview extends InsightComponent {
  static get properties() {
    return {
      orderDetails: {type: Object},
      options: {type: Object}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  getFocusEl() {
    return this._getElement('#btnPay');
  }

  /**************************************************  Private Methods  *************************************************/

  __getOrderItems(details) {
    const items = details.displayItems.slice(0, 2).map(i => {
      return {
        label: i.label,
        value: i18nMoney.format(i.amount.value, i.amount.currency, this._i18nDirect)
      };
    });
    if (details.displayItems.length > 2) items.push({label: '12 more items', value: ''});
    return items;
  }

  __getCardValue(options, type) {
    let val;
    if (type === 'label') {
      if (this.options.cardsOnFile.length) {
        val = this.options.cardsOnFile[0].type + ' •••••' + this.options.cardsOnFile[0].last4;
        val = val.charAt(0).toUpperCase() + val.slice(1);
      } else {
        if (window.innerWidth < 620) val = this._i18n('payment', 'clickAddPymtShort', 'Add a payment method');
        else val = this._i18n('payment', 'clickAddPymtLong', 'Click to add a payment method');
      }
    } else if (type === 'name') {
      if (this.options.cardsOnFile.length) val = this.options.cardsOnFile[0].nameOnCard;
    } else {
      if (this.options.cardsOnFile.length) val = this.options.cardsOnFile[0].type;
    }
    return val;
  }

  __getContactValue(options, type) {
    let val;
    if (this.options.contactsOnFile.length) {
      val = this.options.contactsOnFile[0][type];
    } else if (type === 'fullName') {
      if (window.innerWidth < 620) val = this._i18n('payment', 'clickAddCntcShort', 'Add contact info');
      else val = this._i18n('payment', 'clickAddCntcLong', 'Click to add contact information');
    }
    return val;
  }

  __checkInvalid() {
    return (
      this.__tryValidate &&
      (!this.options ||
        ((!this.options.cardsOnFile || !this.options.cardsOnFile.length) && !this.options.ach) ||
        ((!this.options.contactsOnFile || !this.options.contactsOnFile.length) &&
          (this.options.requestPayerEmail || this.options.requestPayerName || this.options.requestPayerPhone)))
    );
  }

  __purchase() {
    this.__tryValidate = true;
    this.requestUpdate();
    if (!this.__checkInvalid()) this._dispatchEvent('purchase');
  }

  __translateAccountType(type) {
    switch (type) {
      case 'CHECKING':
        return this._i18n('payment', 'checking', 'Checking');
      case 'SAVINGS':
        return this._i18n('payment', 'savings', 'Savings');
      default:
        return this._i18n('payment', 'bizChecking', 'Business Checking');
    }
  }

  _render() {
    return html`
      <style>@-webkit-keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader-animation{to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.insight-loader{box-sizing:border-box;width:32px;height:32px;border-radius:100%;border:4px solid #ccc;border-top-color:var(--mdc-theme-primary);-webkit-animation:loader-animation 1s infinite linear;animation:loader-animation 1s infinite linear}</style>
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical review-container" ?data-invalid=${this.__checkInvalid(this.options)}>
        ${!this.orderDetails
          ? html`
              <div id="review-loader" class="flex-layout-horizontal flex-layout-center-center">
                <div class="insight-loader" style=${window.Testophobia ? 'border-top-color:#ccc;' : ''}></div>
              </div>
            `
          : html`
              <div class="typo-subhead review-title"><b>${this._i18n('payment', 'reviewPayment', 'Review Your Payment')}</b></div>
              <div class="flex-layout-horizontal review-section" @click=${() => this._dispatchEvent('change-view', 3)}>
                <div class="typo-subhead2 review-section-lbl">${this._i18n('payment', 'orderSummary', 'Order Summary')}</div>
                <div class="flex-layout-flex review-section-value">
                  ${this.__getOrderItems(this.orderDetails).map(i => {
                    return html`
                      <div class="flex-layout-horizontal flex-layout-justified typo-body2 review-value-line">
                        <div>${i.label}</div>
                        <div>${i.value}</div>
                      </div>
                    `;
                  })}
                  <div class="flex-layout-horizontal flex-layout-justified typo-subhead2 review-value-line review-value-total">
                    <div class="flex-layout-flex">${this.orderDetails.total.label}</div>
                    <div class="review-curr">${this.orderDetails.total.amount.currency}</div>
                    <div>${i18nMoney.format(this.orderDetails.total.amount.value, this.orderDetails.total.amount.currency, this._i18nDirect)}</div>
                  </div>
                </div>
                <div class="flex-layout-vertical flex-layout-center-justified flex-layout-end review-expand">
                  <insight-icon icon-name="arrow_right"></insight-icon>
                </div>
              </div>
              <div class="flex-layout-horizontal review-section" @click=${() =>
                this._dispatchEvent('change-view', this.options.ach || !this.options.networks || !this.options.networks.length ? 7 : 1)}>
                <div class="typo-subhead2 review-section-lbl">${this._i18n('payment', 'payment', 'Payment')}</div>
                  ${
                    !!this.options.ach
                      ? html`
                          <div class="flex-layout-flex review-section-value">
                            <div class="flex-layout-horizontal flex-layout-justified typo-body2 review-value-line">
                              <div class="review-card">
                                ${this.__translateAccountType(this.options.ach.accountType)}
                              </div>
                            </div>
                            <div class="flex-layout-horizontal flex-layout-justified typo-body2 review-value-line">
                              <div>${this.options.ach.bankName}</div>
                            </div>
                          </div>
                          <div class="flex-layout-horizontal flex-layout-center ach-type">
                            ACH
                          </div>
                        `
                      : html`
                          <div class="flex-layout-flex review-section-value">
                            <div class="flex-layout-horizontal flex-layout-justified typo-body2 review-value-line">
                              <div class="review-card" ?data-no-cards=${!this.options.cardsOnFile || !this.options.cardsOnFile.length}>
                                ${this.__getCardValue(this.options, 'label')}
                              </div>
                            </div>
                            <div class="flex-layout-horizontal flex-layout-justified typo-body2 review-value-line">
                              <div>${this.__getCardValue(this.options, 'name')}</div>
                            </div>
                          </div>
                          <div class="flex-layout-horizontal flex-layout-center">
                            <insight-cc-icon
                              cc-type=${this.__getCardValue(this.options, 'type')}
                              ?hidden=${!this.options.cardsOnFile || !this.options.cardsOnFile.length}
                            ></insight-cc-icon>
                          </div>
                        `
                  }
                  <div class="flex-layout-vertical flex-layout-center-justified flex-layout-end review-expand">
                    <insight-icon icon-name="arrow_right"></insight-icon>
                  </div>
                </div>
                ${
                  !!this.options.requestPayerName || !!this.options.requestPayerEmail || !!this.options.requestPayerPhone
                    ? html`
                        <div class="flex-layout-horizontal review-section" @click=${() => this._dispatchEvent('change-view', 2)}>
                          <div class="typo-subhead2 review-section-lbl">${this._i18n(null, 'contactInfo', 'Contact Info')}</div>
                          <div class="flex-layout-flex review-section-value">
                            ${!!this.options.requestPayerName
                              ? html`
                                  <div class="flex-layout-horizontal flex-layout-justified typo-body2 review-value-line">
                                    <div ?data-no-contact=${!this.options.contactsOnFile || !this.options.contactsOnFile.length}>
                                      ${this.__getContactValue(this.options, 'fullName')}
                                    </div>
                                  </div>
                                `
                              : ''}
                            ${!!this.options.requestPayerEmail
                              ? html`
                                  <div class="flex-layout-horizontal flex-layout-justified typo-body2 review-value-line">
                                    <div>${this.__getContactValue(this.options, 'email')}</div>
                                  </div>
                                `
                              : ''}
                            ${!!this.options.requestPayerPhone
                              ? html`
                                  <div class="flex-layout-horizontal flex-layout-justified typo-body2 review-value-line">
                                    <div>${this.__getContactValue(this.options, 'phone')}</div>
                                  </div>
                                `
                              : ''}
                          </div>
                          <div class="flex-layout-vertical flex-layout-center-justified flex-layout-end review-expand">
                            <insight-icon icon-name="arrow_right"></insight-icon>
                          </div>
                        </div>
                      `
                    : ''
                }
              </div>
            `}
        <div class="flex-layout-flex"></div>
        <div class="flex-layout-horizontal flex-layout-end-justified review-footer">
          <insight-button outlined @click=${() => this._dispatchEvent('close-dialog')}>
            ${this.options && this.options.cancelButtonLabel ? this._i18nDirect(this.options.cancelButtonLabel) : this._i18n(null, 'cancel', 'Cancel')}
          </insight-button>
          <insight-button id="btnPay" contained ?disabled=${!this.orderDetails} @click=${this.__purchase}>
            ${this.options && this.options.submitButtonLabel ? this._i18nDirect(this.options.submitButtonLabel) : this._i18n(null, 'pay', 'Pay')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .review-container {
          height: 100%;
        }
        :host .review-title {
          margin-bottom: 12px;
        }
        :host .review-section {
          padding: 8px 0;
          border-bottom: 1px solid var(--mdc-theme-divider-color);
          cursor: pointer;
          position: relative;
        }
        :host .review-section:hover:before {
          content: '';
          background: var(--mdc-theme-surface);
          opacity: 0.1;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        :host .review-section-lbl {
          width: 160px;
        }
        :host .review-value-line {
          line-height: 24px;
        }
        :host .review-value-line > div:first-child {
          max-width: 268px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        :host .review-section:nth-child(2) .review-value-line:not(.review-value-total):nth-child(3) {
          color: var(--mdc-theme-text-disabled);
        }
        :host *[data-no-cards],
        :host *[data-no-contact] {
          color: var(--mdc-theme-text-disabled);
        }
        :host .review-container[data-invalid] *[data-no-cards],
        :host .review-container[data-invalid] *[data-no-contact] {
          background-color: var(--mdc-theme-error);
          border-radius: 4px;
          padding: 0 8px;
          color: white;
          font-weight: 500;
          animation-duration: 0.7s;
          animation-fill-mode: both;
          animation-timing-function: ease-in-out;
          animation-name: animateError;
        }
        :host .review-expand {
          width: 36px;
        }
        :host .review-curr {
          width: 52px;
        }
        :host .review-footer {
          padding: 8px 0;
        }
        :host .review-footer insight-button {
          margin-left: 16px;
          --button-min-width: 112px;
        }
        :host #review-loader {
          height: 100%;
        }
        :host .ach-type {
          font-weight: 700;
          color: var(--mdc-theme-primary);
        }
        @media only screen and (max-width: 620px) {
          :host .review-section-lbl {
            min-width: 120px;
            max-width: 120px;
          }
          :host .review-value-line > div:first-child {
            max-width: 152px;
          }
          :host insight-cc-icon {
            font-size: 4px;
          }
        }
        @keyframes animateError {
          0% {
            transform: translateX(0);
          }
          6.5% {
            transform: translateX(-6px) rotateY(-9deg);
          }
          18.5% {
            transform: translateX(5px) rotateY(7deg);
          }
          31.5% {
            transform: translateX(-3px) rotateY(-5deg);
          }
          43.5% {
            transform: translateX(2px) rotateY(3deg);
          }
          50% {
            transform: translateX(0);
          }
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-review', InsightPaymentReview);
