import {InsightComponent, html} from '../insight-component.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '../button/insight-button.js';
import '../container/insight-dialog.js';

class InsightSybg extends InsightComponent {
  static get properties() {
    return {
      __sybgs: {type: Array}
    };
  }
  /****************************************************  Public Api  ****************************************************/

  show(sybgs) {
    this.__sybgs = sybgs;
    this.__dialogEl.open();
  }

  close() {
    this.__dialogEl.close();
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.__sybgs = [];
  }

  get __dialogEl() {
    return this._getElement('insight-dialog');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <insight-dialog id="sybg-dialog" modal can-dismiss @closed=${() => setTimeout(() => (this.__sybgs = []), 500)}>
        <div slot="modalHeader">
          <div>${this._i18n('sybg', 'title', "Since You've Been Gone")}</div>
          <div class="typo-caption"><small>${this._i18n('sybg', 'subtitle', 'updates from the Insight team')}</small></div>
        </div>
        <div slot="modalBody">
          ${this.__sybgs.map(sybg => {
            return html`
              <div id="sybg-item" role="alert" class="flex-layout-vertical" ?data-video=${sybg.description.includes('youtube.com')}>
                <div class="typo-body1"><b>${sybg.title}</b></div>
                <div class="typo-body2">
                  ${unsafeHTML(sybg.description)}${sybg.moreInfoUrl
                    ? html`
                        <insight-button contained dense class="more-link" @click=${() => window.open(sybg.moreInfoUrl, '_blank')}>Learn More</insight-button>
                      `
                    : ''}
                </div>
              </div>
            `;
          })}
        </div>
      </insight-dialog>
    `;
  }

  get __css() {
    return `
      <style>
        :host insight-dialog {
          --dialog-padding-body: 10px 24px 20px;
        }
        :host #sybg-item {
          max-width: 480px;
        }
        :host #sybg-item p {
          margin: 0;
        }
        :host #sybg-item h1,
        :host #sybg-item h2,
        :host #sybg-item h3,
        :host #sybg-item h4,
        :host #sybg-item h5,
        :host #sybg-item h6 {
          margin: 0;
          font-weight: normal;
        }
        :host #sybg-item[data-video] {
          width: 80vw;
          max-width: 800px;
        }
        :host #sybg-item:not(:first-child) {
          margin-top: 32px;
        }
        :host #sybg-item > div:first-child {
          background: var(--mdc-theme-primary-on-dark);
          color: white;
          padding: 8px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        :host #sybg-item > div:last-child {
          border: 2px solid var(--mdc-theme-primary);
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          padding: 16px;
          overflow-x: auto;
        }
        :host .more-link {
          display: block;
          margin-top: 16px;
          --button-color: var(--mdc-theme-secondary);
        }
      </style>
    `;
  }
}
window.customElements.define('insight-sybg', InsightSybg);
