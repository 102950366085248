import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '@insight/insight-common/components/button/insight-button.js';
import '@insight/insight-common/components/button/insight-dropdown-button.js';
import '@insight/insight-common/components/button/insight-icon-button.js';
import '@insight/insight-common/components/form/insight-dropdown-menu.js';
import {InsightMvcUrls} from '../../util/insight-mvc-urls.js';

class LocationMenu extends InsightElement {
  /****************************************************  Public Api  ****************************************************/

  static get properties() {
    return {
      locations: {type: Array},
      activityShown: {type: Boolean, attribute: 'activity-shown', reflect: true}
    };
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
  }

  firstUpdated() {
    this._afterRender(() => this.__configureLocationDropdown());
  }

  updated(changedProperties) {
    if (changedProperties.has('_mobileRes') || changedProperties.has('_tabletRes')) {
      this._afterRender(() => this.__configureLocationDropdown());
    }
  }

  __configureLocationDropdown() {
    let locs = this.locations.sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    locs = locs.map(l => ({label: l.name, value: l.id, selected: l.id === Insight.user.currentLocationGuid}));
    const currIdx = locs.findIndex(l => l.selected);
    let currLoc;
    if (currIdx >= 0) currLoc = locs.splice(currIdx, 1)[0];
    const addlLocs = [];
    if (this._hasAccess('EDIT_LOCATION')) addlLocs.push({label: this._i18n('common:settings'), value: 'settings', url: 'settings/location', icon: 'build'});
    if (this._hasAccess('EDIT_LOCATION'))
      addlLocs.push({label: this._i18n('appshell:locationmenu.apps'), value: 'apps', url: 'settings/applications', icon: 'laptop'});
    if (this._hasAccess('EDIT_USER')) addlLocs.push({label: this._i18n('appshell:locationmenu.users'), value: 'users', url: 'location/users', icon: 'person'});
    if (this._hasAccess('EDIT_USER_GROUPS'))
      addlLocs.push({label: this._i18n('appshell:locationmenu.groups'), value: 'groups', url: InsightMvcUrls.locationGroups, icon: 'people'});
    if (this._hasAccess('VIEW_SMS'))
      addlLocs.push({label: this._i18n('appshell:locationmenu.sms'), value: 'sms', url: InsightMvcUrls.locationSms, icon: 'forum'});
    if (this._hasAccess('RUN_REPORTS'))
      addlLocs.push({label: this._i18n('appshell:locationmenu.reports'), value: 'reports', url: InsightMvcUrls.locationReports, icon: 'bar_chart'});
    if (addlLocs.length && currLoc) currLoc.divider = 'after';
    if (addlLocs.length && locs.length) locs[0].divider = 'before';
    locs = addlLocs.concat(locs);
    if (currLoc) locs.unshift(currLoc);
    if (locs.length) this.__locationSelectEl.data = locs;
  }

  __locationChanged(e) {
    this.__locationSelectEl.selectedIndex = 0;
    if (e.detail.url) {
      this._navigateOnClick(e.detail._event_, e.detail.url);
    } else if (e.detail.selected) {
      //nothing
    } else if (e.detail.value) {
      localStorage.setItem('insight-location', e.detail.value);
      Insight.state.dispatch('changeLocation', {location: e.detail.value});
    }
  }

  __hasAnySettings() {
    return (
      this._hasAccess('EDIT_LOCATION') ||
      this._hasAccess('VIEW_APPS') ||
      this._hasAccess('EDIT_USER') ||
      this._hasAccess('EDIT_USER_GROUPS') ||
      this._hasAccess('VIEW_SMS') ||
      this._hasAccess('RUN_REPORTS')
    );
  }

  get __locationSelectEl() {
    return this._getElement('#location-selector');
  }

  get __singleLocTpl() {
    return html`
      <insight-button id="location-selector" outlined @click=${e => this.__locationChanged({detail: {selected: true, _event_: e}})}>
        <div class="flex-layout-horizontal flex-layout-center-center">
          <insight-icon icon-name="home"></insight-icon>
          <div>${this.locations[0].name}</div>
        </div>
      </insight-button>
    `;
  }

  get __desktopTpl() {
    return html`
      <insight-dropdown-menu
        id="location-selector"
        outlined
        dense
        no-background
        no-bottom-margin
        no-underline
        invert-colors
        no-label
        full-width
        prefix-icon="home"
        @change=${this.__locationChanged}
      ></insight-dropdown-menu>
    `;
  }

  get __mobileTpl() {
    return html`
      <insight-dropdown-button id="location-selector" min-width="192" @change=${this.__locationChanged}>
        <insight-icon-button icon-name="home" slot="dropdown-button"></insight-icon-button>
      </insight-dropdown-button>
    `;
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      ${this.locations.length === 1 && !this.__hasAnySettings()
        ? this.__singleLocTpl
        : this._mobileRes || this._tabletRes
        ? this.__mobileTpl
        : this.__desktopTpl}
    `;
  }

  get __css() {
    return `
      <style>
        :host {
          margin-left: 24px;
          margin-right: 12px;
        }
        :host([tablet-res]) {
          margin-right: 0;
        }
        :host([mobile-res]) {
          margin-left: -8px;
          margin-right: -8px;
        }
        :host #location-selector {
          display: block;
          --dropdown-active-color: var(--mdc-theme-dark-highlight, var(--mdc-theme-divider-color));
          --input-font-size: 16px;
          --input-font-weight: 400;
          --input-letter-spacing: 0.009375em;
          --input-line-height: 28px;
        }
        :host(:not([mobile-res]):not([tablet-res])) #location-selector {
          min-width: 192px !important;
        }
        :host insight-button#location-selector {
          --button-icon-color: var(--mdc-theme-text-icon);
          --button-color: var(--mdc-theme-text-primary);
          --button-border-color: var(--mdc-theme-divider-color);
        }
        :host insight-button#location-selector insight-icon {
          height: 24px;
          margin-top: -2px;
          margin-right: 8px;
          --icon-align: top;
        }
      </style>
    `;
  }
}
window.customElements.define('location-menu', LocationMenu);
