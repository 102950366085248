import {DefaultTemplateProcessor} from 'lit-html/lib/default-template-processor.js';
import {EventPart} from 'lit-html/lib/parts.js';

class LeftAndMiddleClickEventPart extends EventPart {
  constructor(element, eventContext) {
    super(element, 'click', eventContext);
  }
  commit() {
    const el = this.element;
    this.element = {
      addEventListener: (name, handler, opts) => {
        el.addEventListener(name, handler, opts);
        el.addEventListener('auxclick', handler, opts);
      },
      removeEventListener: (name, handler, opts) => {
        el.removeEventListener(name, handler, opts);
        el.removeEventListener('auxclick', handler, opts);
      }
    };
    super.commit();
    this.element = el;
  }
}

export class InsightTemplateProcessor extends DefaultTemplateProcessor {
  handleAttributeExpressions(element, name, strings, options) {
    if (name === '@left-and-middle-click') {
      return [new LeftAndMiddleClickEventPart(element, options.eventContext)];
    }
    return super.handleAttributeExpressions(element, name, strings, options);
  }
}
export const insightTemplateProcessor = new InsightTemplateProcessor();
