import {InsightElement, html} from '@insight/insight-common/components/insight-element.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {i18nMoney} from '@insight/insight-common/i18n/i18n-money.js';
import '@insight/insight-common/components/button/insight-button.js';

class RegisterConfirm extends InsightElement {
  static get properties() {
    return {
      registration: {type: Object},
      __regResult: {type: Object}
    };
  }

  firstUpdated() {
    this._afterRender(() => {
      this._dispatchEvent('step-load', this);
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('registration')) {
      this.__regResult = {
        price: this.registration.result.billed_amount || this.registration.result.billedAmount,
        confNum: this.registration.result.transaction_id || this.registration.result.transactionId,
        location: this.registration.result.locationGuid,
        redirect: this.registration.result.sign_in_url
      };
    }
  }

  __finish() {
    if (!this.registration.result.locationGuid) {
      window.location.href = this.__regResult.redirect;
    } else {
      window.location.href = '/';
      localStorage.setItem('insight-location', this.registration.result.locationGuid);
      Insight.state.dispatch('changeLocation', {location: this.registration.result.locationGuid});
    }
  }

  _render() {
    if (!this.__regResult) return;
    return html`
      ${unsafeHTML(this.__css)}
      <div>
        <div class="typo-headline">
          ${this._i18n(this.registration.payment === 'trial' ? 'location:register.trialActive' : 'location:register.acctActive')}
        </div>
        <div class="flex-layout-horizontal flex-layout-wrap confirm-container">
          <p>
            ${this.registration.payment === 'trial'
              ? html`
                  <div class="typo-subhead">
                    ${unsafeHTML(this._i18n('location:register.trialConfirm'))}
                  </div>
                  <div class="typo-subhead">
                    ${unsafeHTML(this._i18n('location:register.clickToLogin'))}
                  </div>
                `
              : html`
                  <div class="typo-subhead">
                    ${unsafeHTML(
                      this._i18n('location:register.acctConfirm', {
                        price: i18nMoney.format(this.__regResult.price),
                        confNum: this.__regResult.confNum
                      })
                    )}
                  </div>
                  <div class="typo-subhead">
                    ${unsafeHTML(
                      this._i18n('location:register.' + (!this.registration.result.locationGuid ? 'clickToLogin' : 'clickToSwitch'), {
                        locationName: this.registration.location.name
                      })
                    )}
                  </div>
                `}
          </p>
        </div>

        <div class="footer-btns">
          <insight-button contained @click=${this.__finish}>
            ${this._i18n('location:register.' + (!this.registration.result.locationGuid ? 'loginGI' : 'switchToNewLoc'))}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
    <style>
      :host .typo-headline {
        margin: 5px 0;
      }
      :host .confirm-container {
        max-width: 920px;
      }
      :host .typo-subhead:last-child {
        margin-top: 16px;
      }
      :host .footer-btns {
        margin: 10px 0;
      }
      :host .footer-btns insight-button:last-child {
        --button-color: var(--mdc-theme-success);
      }
    </style>
  `;
  }
}
window.customElements.define('register-confirm', RegisterConfirm);
