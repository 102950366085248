import {detect} from 'detect-browser';

const getBrowserInfo = () => {
  try {
    const info = Object.assign({}, detect(), {installed: Boolean(window.matchMedia('(display-mode: standalone)').matches)});
    info.toString = () => {
      const params = [];
      params.push(info && info.type ? info.type : 'unknown client');
      params.push(info && info.name ? info.name : 'unknown browser');
      params.push(info && info.version ? info.version : 'unknown version');
      params.push(info && info.os ? 'on ' + info.os : 'unknown OS');
      params.push(info && info.installed ? '(installed)' : '');
      return params.join(' ');
    };
    return info;
  } catch (e) {
    console.warn('Unable to determine browser info', e);
    return {toString: () => 'Unable to determine browser info - ' + e.message};
  }
};

export default getBrowserInfo;
