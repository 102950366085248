import {LitElement, html} from 'lit-element/lit-element.js';
import {TemplateResult} from 'lit-html/lib/template-result.js';
import {insightTemplateProcessor} from './insight-template-processor.js';
import i18next from 'i18next';
const insightHtml = (strings, ...values) => new TemplateResult(strings, values, 'html', insightTemplateProcessor);
export {insightHtml as html};

export class InsightElement extends LitElement {
  static get properties() {
    return {
      _mobileRes: {type: Boolean, attribute: 'mobile-res', reflect: true},
      _tabletRes: {type: Boolean, attribute: 'tablet-res', reflect: true}
    };
  }

  constructor() {
    super();
    this.__viewReady = false;
    this.__subscriptions = {};
    this.__setupResolutionListener();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._destructor();
  }

  connectedCallback() {
    super.connectedCallback();
    this._afterRender(() => this.dispatchEvent(new CustomEvent('element-ready'), {detail: this}));
  }

  _destructor() {
    this._unsubscribeAll();
    if (this.__boundResolutionListener) Insight.resolution.removeEventListener('change', this.__boundResolutionListener);
    if (this.__resizeHandler) {
      window.removeEventListener('orientationchange', this.__resizeHandler, false);
      window.removeEventListener('resize', this.__resizeHandler, false);
    }
  }

  _getElement(selector, multi) {
    const root = this.shadowRoot || this;
    if (multi) return root.querySelectorAll(selector);
    else return root.querySelector(selector);
  }

  _afterRender(cb) {
    this.updateComplete.then(() => {
      requestAnimationFrame(cb);
    });
  }

  _dispatchEvent(evtName, detail) {
    this.dispatchEvent(new CustomEvent(evtName, {detail: detail, bubbles: true, composed: true}));
  }

  _subscribe(command, callback) {
    this.__subscriptions[command] = callback;
    Insight.state.subscribe(command, callback);
  }

  _unsubscribe(command) {
    if (!this.__subscriptions[command]) return;
    Insight.state.unsubscribe(command, this.__subscriptions[command]);
    delete this.__subscriptions[command];
  }

  _unsubscribeAll() {
    if (this.__subscriptions) Object.keys(this.__subscriptions).forEach(s => Insight.state.unsubscribe(s, this.__subscriptions[s]));
  }

  _i18n(key, obj) {
    return i18next.t(key, obj);
  }

  _isAuthenticated() {
    return !!Insight.auth && !!Insight.auth.accessToken;
  }

  _hasAccess(permKey) {
    return !permKey || (Insight.user && Insight.user.permissions.includes(permKey));
  }

  _isMock() {
    return window.Testophobia || Insight.configs.mock;
  }

  _debounce(ms, fn) {
    let timer;
    return function () {
      clearTimeout(timer);
      const args = Array.prototype.slice.call(arguments);
      args.unshift(this);
      timer = setTimeout(fn.bind.apply(fn, args), ms);
    };
  }

  _getEventPath(evt) {
    let path = evt.composedPath && evt.composedPath();
    if (!path || !path.length) path = evt.path;
    if (path != null) {
      path = path.indexOf(window) < 0 ? path.concat([window]) : path;
      return path;
    }
    if (evt.target === window) return [window];
    const getParents = (node, memo) => {
      memo = memo || [];
      if (!node.parentNode) return memo;
      else return getParents(node.parentNode, memo.concat([node.parentNode]));
    };
    return [evt.target].concat(getParents(evt.target)).concat([window]);
  }

  _listenForWindowResize(cb) {
    if (!(window.addEventListener && window.document.createEvent)) return;
    let getCurrentOrientation = () => {
      return Math.abs(+window.orientation || 0) % 180;
    };
    let initialOrientation = getCurrentOrientation();
    let currentOrientation = null;
    let resizeDebounceTimeout = null;
    this.__resizeHandler = () => {
      currentOrientation = getCurrentOrientation();
      if (currentOrientation !== initialOrientation) {
        cb();
        return (initialOrientation = currentOrientation);
      } else {
        clearTimeout(resizeDebounceTimeout);
        return (resizeDebounceTimeout = setTimeout(cb, 50));
      }
    };
    window.addEventListener('orientationchange', this.__resizeHandler, false);
    return window.addEventListener('resize', this.__resizeHandler, false);
  }

  _focusElement(selectorOrEl, force) {
    if (!this._focusAlreadyApplied || force) {
      this._afterRender(() => (typeof selectorOrEl === 'string' ? this._getElement(selectorOrEl).setFocus() : selectorOrEl.setFocus()));
      this._focusAlreadyApplied = true;
    }
  }

  _navigateOnClick(evt, path) {
    this._dispatchEvent('navigate-app', {path: path, event: evt});
  }

  async _asyncForEach(arr, cb) {
    for (let i = 0; i < arr.length; i++) {
      if ((await cb(arr[i], i, arr)) === true) break;
    }
  }

  __setupResolutionListener() {
    if (!Insight.resolution) {
      Insight.resolution = new Proxy(new window.EventTarget(), {
        get: (obj, key) => {
          const result = Reflect.get(obj, key);
          if (typeof result === 'function') return result.bind(obj);
          return result;
        },
        set: (obj, prop, val) => {
          Reflect.set(obj, prop, val);
          obj.dispatchEvent(new CustomEvent('change', {detail: {prop, val}}));
          return true;
        }
      });
    }
    this._tabletRes = Insight.resolution.tablet;
    this._mobileRes = Insight.resolution.mobile;
    this.__boundResolutionListener = ({detail}) => {
      if (detail.prop === 'tablet') {
        if (detail.val && !this._tabletRes) this._tabletRes = true;
        else if (!detail.val && this._tabletRes) this._tabletRes = false;
      }
      if (detail.prop === 'mobile') {
        if (detail.val && !this._mobileRes) this._mobileRes = true;
        else if (!detail.val && this._mobileRes) this._mobileRes = false;
      }
    };
    Insight.resolution.addEventListener('change', this.__boundResolutionListener);
  }

  render() {
    if (!this.__viewReady) {
      this._afterRender(() => {
        this.__viewReady = true;
        this.style.opacity = 1;
        this._dispatchEvent('view-ready');
      });
    }
    let tpl = this._render();
    if (this.__excludeGlobalCSS) return tpl;
    else
      return html`
        <style>.flex-layout{display:flex}.flex-layout-inline{display:inline-flex}.flex-layout-horizontal{display:flex;flex-direction:row}.flex-layout-horizontal-reverse{flex-direction:row-reverse}.flex-layout-vertical{display:flex;flex-direction:column}.flex-layout-vertical-reverse{flex-direction:column-reverse}.flex-layout-wrap{flex-wrap:wrap}.flex-layout-wrap-reverse{flex-wrap:wrap-reverse}.flex-layout-flex-auto{flex:1 1 auto}.flex-layout-flex-none{flex:none}.flex-layout-flex{flex:1}.flex-layout-flex-2{flex:2}.flex-layout-flex-3{flex:3}.flex-layout-flex-4{flex:4}.flex-layout-flex-5{flex:5}.flex-layout-flex-6{flex:6}.flex-layout-flex-7{flex:7}.flex-layout-flex-8{flex:8}.flex-layout-flex-9{flex:9}.flex-layout-flex-10{flex:10}.flex-layout-flex-11{flex:11}.flex-layout-flex-12{flex:12}.flex-layout-start{align-items:flex-start}.flex-layout-center{align-items:center}.flex-layout-end{align-items:flex-end}.flex-layout-baseline{align-items:baseline}.flex-layout-start-justified{justify-content:flex-start}.flex-layout-center-justified{justify-content:center}.flex-layout-end-justified{justify-content:flex-end}.flex-layout-around-justified{justify-content:space-around}.flex-layout-justified{justify-content:space-between}.flex-layout-center-center{align-items:center;justify-content:center}.flex-layout-self-start{align-self:flex-start}.flex-layout-self-center{align-self:center}.flex-layout-self-end{align-self:flex-end}.flex-layout-self-stretch{align-self:stretch}.flex-layout-fit{position:absolute;top:0;right:0;bottom:0;left:0}</style>
        <style>.typo-body1,.typo-body2,.typo-button,.typo-caption,.typo-display1,.typo-display2,.typo-display3,.typo-display4,.typo-headline,.typo-overline,.typo-subhead,.typo-subhead2,.typo-title{font-family:'Roboto','Noto',sans-serif;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased}.typo-button,.typo-display1,.typo-display2,.typo-display3,.typo-display4,.typo-headline,.typo-overline,.typo-subhead,.typo-subhead2,.typo-title{text-rendering:optimizeLegibility}.typo-button,.typo-caption,.typo-display3,.typo-display4,.typo-overline,.typo-title{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.typo-display3,.typo-display4{font-size:96px;font-weight:300;letter-spacing:-.015625em;line-height:96px}.typo-display3{font-size:60px;letter-spacing:-.0083333333em;line-height:60px}.typo-display1,.typo-display2{font-size:48px;font-weight:400;letter-spacing:normal;line-height:50px}.typo-display1{font-size:34px;letter-spacing:.0073529412em;line-height:40px}.typo-headline,.typo-title{font-size:24px;font-weight:400;letter-spacing:normal;line-height:32px}.typo-title{font-size:20px;font-weight:500;letter-spacing:.0125em}.typo-subhead{font-size:16px;font-weight:400;letter-spacing:.009375em;line-height:28px}.typo-subhead2{font-size:14px;font-weight:500;letter-spacing:.0071428571em;line-height:22px}.typo-body1{font-size:16px;font-weight:400;letter-spacing:.03125em;line-height:24px}.typo-body2,.typo-caption{font-size:14px;font-weight:400;letter-spacing:.0178571429em;line-height:20px}.typo-caption{font-size:12px;letter-spacing:.0333333333em}.typo-button,.typo-overline{font-size:14px;font-weight:500;letter-spacing:.0892857143em;line-height:36px;text-transform:uppercase;text-decoration:none}.typo-overline{font-size:10px;letter-spacing:.1666666667em;line-height:32px}</style>
        <style>::-webkit-scrollbar{height:18px;width:18px}::-webkit-scrollbar-button,::-webkit-scrollbar-corner{cursor:pointer;width:0;height:0;display:none}::-webkit-scrollbar-thumb{background-color:#ccc}::-webkit-scrollbar-thumb,::-webkit-scrollbar-thumb:hover{border-radius:9px;border:solid 6px transparent;background-clip:content-box;min-height:32px}::-webkit-scrollbar-track{background:0 0}*{scrollbar-color:#ccc transparent}</style>
        ${tpl}
      `;
  }

  /*abstract*/ _render() {}
}
