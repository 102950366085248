import {InsightComponent, html} from '../../insight-component.js';
import {ifDefined} from 'lit-html/directives/if-defined.js';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import '../../button/insight-button.js';
import '../../button/insight-icon-button.js';
import '../../form/insight-dropdown-menu.js';
import '../../form/insight-form.js';
import '../../form/insight-input.js';

class InsightPaymentAddContact extends InsightComponent {
  static get properties() {
    return {
      contactDetails: {type: Object},
      options: {type: Object},
      __isCanada: {type: Boolean}
    };
  }

  /****************************************************  Public Api  ****************************************************/

  getFocusEl() {
    return this._getElement('#ipt-name');
  }

  /**************************************************  Private Methods  *************************************************/

  constructor() {
    super();
    this.contactDetails = {};
  }

  firstUpdated() {
    this._afterRender(() => {
      this._focusElement('#ipt-name');
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('options')) {
      this.contactDetails =
        !!this.options && this.options.editItem
          ? {
              editIndex: this.options.contactsOnFile.indexOf(this.options.editItem),
              name: this.options.editItem.fullName,
              email: this.options.editItem.email,
              phone: this.options.editItem.phone
            }
          : {};
    }
  }

  __updateContact(e) {
    if (this.__formEl.validate()) {
      const contact = {
        fullName: this.contactDetails.name,
        email: this.contactDetails.email,
        phone: this.contactDetails.phone
      };
      if (this.contactDetails.editIndex >= 0) {
        this.options.contactsOnFile.splice(this.contactDetails.editIndex, 1, contact);
        this._dispatchEvent('change-view', 2);
      } else {
        this.options.contactsOnFile.unshift(contact);
        this._dispatchEvent('change-view', 0);
      }
    }
  }

  __goBack() {
    if (!this.options || !this.options.contactsOnFile || !this.options.contactsOnFile.length) this._dispatchEvent('change-view', 0);
    else this._dispatchEvent('change-view', 2);
  }

  get __formEl() {
    return this._getElement('insight-form');
  }

  _render() {
    return html`
      ${unsafeHTML(this.__css)}
      <div class="flex-layout-vertical addcontact-container">
        <div class="flex-layout-horizontal flex-layout-center">
          <insight-icon-button id="btnBack" icon-name="arrow_back" class="addcontact-back" @click=${this.__goBack}></insight-icon-button>
          <div class="typo-subhead addcontact-title">
            <b>
              ${this._i18n(
                'payment',
                this.contactDetails.editIndex >= 0 ? 'editContact' : 'addContact',
                this.contactDetails.editIndex >= 0 ? 'Edit Contact Info' : 'Add Contact Info'
              )}
            </b>
          </div>
        </div>
        <insight-form id="addcontact-form" class="flex-layout-flex">
          ${!!this.options.requestPayerName
            ? html`
                <insight-input
                  id="ipt-name"
                  autocomplete="name"
                  dense
                  class="flex-layout-flex"
                  label=${this._i18n('payment', 'name', 'Name')}
                  required
                  error-message=${this._i18n(null, 'error.nameReq', 'Name is Required')}
                  initial-value=${ifDefined(this.contactDetails.name)}
                  @change=${e => (this.contactDetails.name = e.detail)}
                ></insight-input>
              `
            : ''}
          ${!!this.options.requestPayerEmail
            ? html`
                <insight-input
                  id="ipt-email"
                  autocomplete="email"
                  dense
                  class="flex-layout-flex"
                  type="email"
                  label=${this._i18n('payment', 'email', 'Email Address')}
                  required
                  error-message=${this._i18n(null, 'error.emailReq', 'Email Address is Required')}
                  initial-value=${ifDefined(this.contactDetails.email)}
                  @change=${e => (this.contactDetails.email = e.detail)}
                ></insight-input>
              `
            : ''}
          ${!!this.options.requestPayerPhone
            ? html`
                <insight-input
                  id="ipt-phone"
                  autocomplete="tel"
                  dense
                  class="flex-layout-flex"
                  type="tel"
                  label=${this._i18n(null, 'phoneNumber', 'Phone Number')}
                  required
                  error-message=${this._i18n(null, 'error.phoneNumReq', 'Phone Number is Required')}
                  initial-value=${ifDefined(this.contactDetails.phone)}
                  @change=${e => (this.contactDetails.phone = e.detail)}
                ></insight-input>
              `
            : ''}
        </insight-form>
        <div class="flex-layout-horizontal flex-layout-end-justified addcontact-footer">
          <insight-button id="btnUpdate" contained @click=${this.__updateContact}>
            ${this._i18n('payment', 'done', 'Done')}
          </insight-button>
        </div>
      </div>
    `;
  }

  get __css() {
    return `
      <style>
        :host .addcontact-container {
          height: 100%;
        }
        :host .addcontact-back {
          margin-left: -16px;
          margin-top: -12px;
        }
        :host .addcontact-title {
          margin-bottom: 12px;
        }
        :host #addcontact-form {
          margin-top: 8px;
        }
        :host .addcontact-footer {
          padding: 8px 0;
        }
      </style>
    `;
  }
}
window.customElements.define('insight-payment-add-contact', InsightPaymentAddContact);
